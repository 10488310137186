import { call, put } from "redux-saga/effects";
import { setAccounts, setTotalBalance } from "../../ducks/accounts";
import { requestGetAccounts } from "../request/accounts";

export function* handleGetAccounts(_action) {
  try {
    const response = yield call(requestGetAccounts);
    const { data } = response;
    yield put(setAccounts(data.data.accounts));
    yield put(setTotalBalance(data.data.total_aggregate_balance));
  } catch (error) {
    console.error("Error fetching accounts:", error);
  }
}
