import React, { useState } from "react";
import * as RiIcons from "react-icons/ri";
import { useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { Toast } from "react-bootstrap";

const InviteFriends = () => {
  const user = useSelector((state) => state.user);

  const [copied, setCopied] = useState(false);
  
  return (
    <div className="edit-profile">
      <div className="mt-5">
        <div className="mr-lg-5 mr-md-2">
          <h6 className="settings-small-header referral-header">
            Share your Referral Code
          </h6>
          <div className="referral-msg">
            <p>
              Invite a friend.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between referral-card p-4">
          <div>
            <h6>Referral Code</h6>
            <p className="text-primary mt-3">{user && user.referral_code}</p>
          </div>
          <div className="align-self-center copy-icon-container">
            <Toast
              onClose={() => setCopied(false)}
              show={copied}
              className=""
              delay={1000}
              autohide
            >
              <Toast.Body className="copy-toast-text">Copied</Toast.Body>
            </Toast>
            <CopyToClipboard
              btn="true"
              onCopy={() => setCopied(true)}
              text={user && user.referral_code}
            >
              <div style={{ cursor: "pointer" }}>
                <RiIcons.RiFileCopyLine />
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
