import speaker from "../assets/speaker.png"
import arrow from "../assets/right-arrow.png"

export const BroadCast = ({openModal}) => {
    return (
        <div 
        onClick={openModal}
        style={{
            display: "flex", alignItems: "center", justifyContent: "space-between",
            backgroundColor: "#54bbbc", borderRadius: "2rem", cursor:"pointer", height:"2rem"
        }}>
            <div style={{paddingInline: "1rem", minWidth: "4rem"}}>
                <img
                    src={speaker}
                    alt={"speaker icon"}
                    style={{width: "1.5rem"}}
                />
            </div>
            <div style={{color: "white", display: "flex", overflow: "hidden", whiteSpace: "nowrap", gap: "2rem", paddingTop:"1rem"}}>
                <p className="scroll">
                    Shanono Bank prohibits any cryptocurrency and all virtual currency trading.
                    Any account in violation of this notice will be closed!
                </p>
                <p className="scroll">
                    Shanono Bank prohibits any cryptocurrency and all virtual currency trading.
                    Any account in violation of this notice will be closed!
                </p>
                <p className="scroll">
                    Shanono Bank prohibits any cryptocurrency and all virtual currency trading.
                    Any account in violation of this notice will be closed!
                </p>
                <p className="scroll">
                    Shanono Bank prohibits any cryptocurrency and all virtual currency trading.
                    Any account in violation of this notice will be closed!
                </p>
            </div>
            <div style={{paddingInline: "1rem", minWidth: "4rem"}}>
                <img
                    src={arrow}
                    alt={"arrow icon"}
                    style={{width: "1.5rem"}}
                />
            </div>
        </div>
    )
}

export const NoCrypto = ({closeModal}) => {
    return (
        <section style={{ backgroundColor: "white", maxWidth: "28rem", padding: "2rem", borderRadius: "0.5rem", fontSize: "1.2rem", textAlign: "center" }}>
            <p>
                Shanono Bank prohibits any cryptocurrency and all virtual currency trading.
                Any account in violation of this notice will be closed
            </p>

            <div onClick={closeModal} style={{ padding: "0.8rem", backgroundColor: "#348081", width: "100%", borderRadius: "2rem", color: "white", marginTop: "1rem", cursor: "pointer" }}> Got it </div>
        </section>
    )
}