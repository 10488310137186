import { call, put } from "redux-saga/effects";
import { setAccountTypes } from "../../ducks/accountTypes";
import { requestGetAccountTypes } from "../request/accountTypes";

export function* handleGetAccountTypes(action) {
  try {
    // make the get request
    const response = yield call(requestGetAccountTypes);
    const { data } = response;
    // dispatch to redux
    yield put(setAccountTypes(data.data["account-types"].data));
  } catch (error) {
    console.log(error);
  }
}
