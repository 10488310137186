import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/InputField";
import InputDropDown from "../../../components/InputDropDown";
import "./EditProfile.scss";
import InputFile from "../../../components/InputFile";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert";
import { useSelector } from "react-redux";
import Unathorized from "../Unathorized";
import DatePicker from "react-datepicker";
import moment from "moment";
import { errorHandler } from "../../../utils/errorHandler";

const EditProfile = () => {
  const user = useSelector((state) => state.user);

  const [date, setDate] = useState(null);
  const [states, setStates] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    // fetch all states and their lgas
    axios
      .get(`${process.env.REACT_APP_API}/user/edit`)
      .then((res) => setStates(res.data.data.states))
      .catch((err) => console.log(err));

    //cleanup
    return () => {
      source.cancel();
    };
  }, []);

  //set states once the api loads them
  const statesArr = [];
  if (states) {
    for (const property in states) {
      statesArr.push(property);
    }
  }

  const validate = Yup.object({
    gender: Yup.string().required("Gender is required"),
    occupation: Yup.string().required("Occupation is required"),
    address_line_one: Yup.string().required("Address is required"),
    address_line_two: Yup.string(),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("LGA is required"),
    identity_card: Yup.mixed().required("Identity card is required"),
    utility_bill: Yup.mixed().required("Utility bill is required"),
  });

  const handleSubmit = (values) => {
    setLoading(true);

    if (!date) {
      setLoading(false);
      const title = "Error";
      const html = "Please enter your date of birth";
      const icon = "error";
      return fireAlert(title, html, icon);
    }

    let data = { ...values };

    data.date_of_birth = moment(date).format("YYYY-MM-DD");

    const formData = new FormData();

    //this _method is what the backend wants
    formData.append("_method", "PATCH");

    // loop and append to formData
    for (const property in data) {
      formData.append(property, data[property]);
    }
    axios
      .post(`${process.env.REACT_APP_API}/user`, formData)
      .then((res) => {
        setLoading(false);
        const title = "Successful";
        const html = "Your profile has been successfully updated.";
        const icon = "success";
        fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  if (user.verification && user.verification.status === "verified") {
    return <Unathorized message="You already updated your profile" />;
  }

  return (
    <div className="p-2 p-lg-5 edit-profile profile-modifier">
      <h3>Profile</h3>
      <p>Update your profile information</p>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            gender: user.gender ? user.gender : "",
            occupation: user.occupation ? user.occupation : "",
            address_line_one: user.address_line_one
              ? user.address_line_one
              : "",
            address_line_two: user.address_line_two
              ? user.address_line_two
              : "",
            state: user.state ? user.state : "",
            city: user.city ? user.city : "",
            identity_card: "",
            utility_bill: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <div
                className="d-flex flex-wrap edit-profile-input-group"
                style={{ gap: "1rem" }}
              >
                <InputDropDown
                  label="GENDER"
                  noValue="Select"
                  options={["Male", "Female"]}
                  values={["male", "female"]}
                  name="gender"
                />
                <InputField label="OCCUPATION" name="occupation" type="text" />
              </div>
              <div
                className="d-flex flex-wrap edit-profile-input-group"
                style={{ gap: "1rem" }}
              >
                <InputField
                  label="ADDRESS LINE 1"
                  name="address_line_one"
                  type="text"
                />
                <InputField
                  label="ADDRESS LINE 2"
                  name="address_line_two"
                  type="text"
                />
              </div>
              <div
                className="d-flex flex-wrap edit-profile-input-group profile-state"
                style={{ gap: "1rem" }}
              >
                <InputDropDown
                  label="STATE"
                  noValue="Select"
                  options={statesArr}
                  values={statesArr}
                  name="state"
                />
                <InputDropDown
                  label="LGA"
                  noValue="Select"
                  options={
                    formik.values.state && states
                      ? states[formik.values.state]
                      : []
                  }
                  values={
                    formik.values.state && states
                      ? states[formik.values.state]
                      : []
                  }
                  name="city"
                />
              </div>
              <div
                className="d-flex flex-wrap edit-profile-input-group"
                style={{ gap: "1rem" }}
              >
                <div className="align-self-center">
                  <p style={{ fontSize: "16px" }}>DATE OF BIRTH</p>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={date}
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => setDate(date)}
                    className="form-control reg-input mt-n2"
                  />
                </div>
                <InputFile
                  label="IDENTITY CARD(2MB MAX SIZE)"
                  name="identity_card"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "identity_card",
                      event.currentTarget.files[0]
                    );
                  }}
                />
              </div>
              <InputFile
                label="UTILITY BILL(2MB MAX SIZE)"
                name="utility_bill"
                onChange={(event) => {
                  formik.setFieldValue(
                    "utility_bill",
                    event.currentTarget.files[0]
                  );
                }}
              />
              <button
                type="submit"
                className="btn btn-primary mt-2 edit-profile-btn"
                disabled={loading}
              >
                {loading ? "Please wait..." : " Update Information"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditProfile;
