import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BackArrow from "../../assets/back-arrow.svg";
import DayAndDate from "../../components/DayAndDate";
import axios from "axios";
import PageLoader from "../../components/PageLoader";
import BeneficiaryCard from "./BeneficiaryCard";
import "./Beneficiaries.scss";

const Beneficiaries = ({ history }) => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API}/beneficiaries`)
      .then((res) => {
        setBeneficiaries([...res.data.data.beneficiaries]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, []);

  if (loading) {
    return <PageLoader />;
  }


  return (
    <>
      <Helmet>
        <title>Saved Beneficiaries - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Saved Beneficiaries</h1>
          <DayAndDate />
          <span
            className="d-flex align-items-center cursor-pointer"
            onClick={() => history.push("/send-money")}
          >
            <img src={BackArrow} alt="Back Arrow" />
            <span className="pl-1">Back</span>
          </span>
          <section className="mt-3 beneficiaries">
            <BeneficiaryCard
              beneficiaries={beneficiaries}
              setBeneficiaries={setBeneficiaries}
              error={error}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default Beneficiaries;
