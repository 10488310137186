import createSagaMiddleware from "@redux-saga/core";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import accountsReducer from "./ducks/accounts";
import accountTypesReducer from "./ducks/accountTypes";
import banksReducer from "./ducks/banks";
import beneficiariesReducer from "./ducks/beneficiaries";
import investmentsReducer from "./ducks/investments";
import providersReducer from "./ducks/providers";
import schedulesReducer from "./ducks/schedules";
import userReducer from "./ducks/user";
import { watcherSaga } from "./sagas/rootSaga";
// import userLocationReducer from "./ducks/userLocation";

const appReducer = combineReducers({
  user: userReducer,
  accounts: accountsReducer,
  // location: userLocationReducer,
  accountTypes: accountTypesReducer,
  banks: banksReducer,
  beneficiaries: beneficiariesReducer,
  investments: investmentsReducer,
  schedules: schedulesReducer,
  providers: providersReducer,
});

// to clear all data in redux on logout,
//that's the whole point of having 2 reducers root and app reducers
const LOGOUT = "LOGOUT";
export const logoutUser = () => ({ type: LOGOUT });

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    typeof window === "object" &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined" &&
      process.env.NODE_ENV !== "production"
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

sagaMiddleware.run(watcherSaga);

export default store;
