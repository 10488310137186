import React from "react";
import Unauthorized from "../../assets/unauthorized.svg";

const Unathorized = ({ message }) => {
  return (
    <div className="edit-profile">
      <div className="w-75 m-auto">
        <img src={Unauthorized} alt="" className="img-fluid w-100"/>
      </div>
      <p className="text-center mt-2 text-primary">{message}</p>
    </div>
  );
};

export default Unathorized;
