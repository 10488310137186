import React, { useState } from "react";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import Unathorized from "../../settings/Unathorized";
import CancelPaymentModal from "./CancelPaymentModal";
import UpdatePaymentModal from "./UpdatePaymentModal";

const RecurringCards = ({ payments, deleted, setDeleted }) => {
  //don't confuse this with payments, this is the one payment to be cancelled (SINGULAR)
  const [payment, setPayment] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleUpdateClick = (item) => {
    setPayment(item);
    setShowUpdateModal(true);
  };

  const handleDeleteClick = (item) => {
    setPayment(item);
    setShowCancelModal(true);
  };

  if (payments.length < 1) {
    return <Unathorized message="You have no recurring payments." />;
  }

  return (
    <>
      <UpdatePaymentModal
        payment={payment}
        showUpdateModal={showUpdateModal}
        deleted={deleted}
        setPayment={setPayment}
        setShowUpdateModal={setShowUpdateModal}
        setDeleted={setDeleted}
      />
      <CancelPaymentModal
        payment={payment}
        showCancelModal={showCancelModal}
        deleted={deleted}
        setPayment={setPayment}
        setShowCancelModal={setShowCancelModal}
        setDeleted={setDeleted}
      />
      <div className="mt-4 d-flex justify-content-evenly align-items-center flex-wrap">
        {payments.map((payment, index) => (
          <div
            key={index}
            className="m-2 bg-primary text-white p-3 d-flex flex-column justify-content-between recurring-card"
          >
            <div className="d-flex justify-content-between">
              <p>Amount: ₦ {thousandSeparator(payment.amount)}</p>
              <p
                className="recurring-card_stop"
                onClick={() => handleDeleteClick(payment)}
              >
                Delete
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="recurring-card_number">
                **** **** **** {payment.last_four_digits}
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => handleUpdateClick(payment)}
              >
                Edit
              </p>
            </div>
            <div className="d-flex justify-content-between mb-n3">
              <p className="text-capitalize">
                {payment.schedule.replace("_", "-")}
              </p>
              <p>
                Expires: {payment.expiry_month}/
                {payment.expiry_year.substring(2)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RecurringCards;
