import moment from "moment";
import React from "react";

const DayAndDate = () => {
    
const day = moment().format('dddd');
const date = moment().format("Do MMMM, YYYY");

  return (
    <p className="day-and-date">
      <span className="text-primary">{day}, </span>
      {date}
    </p>
  );
};

export default DayAndDate;
