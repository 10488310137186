import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import * as BiIcons from "react-icons/bi";
import { getCurrencySymbol } from "../../../utils/normalize";

const TotalBalance = () => {
  const [balances, setBalances] = useState({});
  const [hasForeignAccount, setHasForeignAccount] = useState(false);
  const [unLockedBalance, setUnLockedBalance] = useState(0);
  const accounts = useSelector((state) => state.accounts.accounts);
  const trueString = "true";
  const falseString = "false";
  const [hide, setHide] = useState(localStorage.getItem("hideTotalBalance"));

  useEffect(() => {
  if (accounts) {
    const wallet = {};
    let totalUnLockedBalance = 0;
    accounts.forEach((acct) => {
      const currency = acct.currency ?? "ngn";
      const balance = +acct.available_balance ?? 0;
      const unlockedBalance = +acct.unlocked_balance ?? 0;

      // Update total available balance
      totalUnLockedBalance += unlockedBalance;

      // Exclude unlocked_balance from the total balance
      const totalBalance = balance;

      if (currency in wallet) {
        wallet[currency] += totalBalance;
      } else {
        wallet[currency] = totalBalance;
      }

      if (currency === "usd") setHasForeignAccount(true);
    });

    setBalances(wallet);
    setUnLockedBalance(totalUnLockedBalance);
  }
}, [accounts]);


  const formatBalance = (currency, value) => {
    return `${getCurrencySymbol({ currency })} ${thousandSeparator(value.toFixed(2))}`;
  };

  return (
    <div className="text-center p-2 m-2 d-flex total-balance-container">
      <h2 className="m-auto total-balance">
        Total Balance:{" "}
        {hide !== trueString ? (
          <>
            <span>{formatBalance("ngn", balances?.ngn ?? 0)}</span>
            {hasForeignAccount ? (
              <span className="my-3 d-block">
                {`(${formatBalance("usd", balances?.usd ?? 0)})`}
              </span>
            ) : null}
            <span className="my-3 d-block">
              Available Balance: {formatBalance("ngn", unLockedBalance ?? 0)}
            </span>
          </>
        ) : (
          <span>****</span>
        )}
      </h2>
      <div>
        <button
          className="btn btn-light btn-sm"
          onClick={() => {
            if (localStorage.hideTotalBalance) {
              setHide(falseString);
              localStorage.removeItem("hideTotalBalance");
            } else {
              setHide(trueString);
              localStorage.setItem("hideTotalBalance", trueString);
            }
          }}
        >
          {hide !== trueString ? <BiIcons.BiHide /> : <BiIcons.BiShow />}
        </button>
      </div>
    </div>
  );
};

export default TotalBalance;