import React from "react";
import { withRouter } from "react-router-dom";
import BackArrow from "../../../assets/back-arrow.svg";

const AddPosLeft = ({ history }) => {
  return (
    <div className="new-card-left">
      <span
        className="d-flex align-items-center cursor-pointer"
        onClick={() => history.push("/pos")}
      >
        <img src={BackArrow} alt="Back Arrow" />
        <span className="pl-1">Back</span>
      </span>
      <div className="new-card-left-content">
        <h2 className="text-primary new-card-title">
          Request a POS <br /> from <br />{" "}
          <span className="title-red">Shanono</span>
        </h2>
        {/* <p className="new-card-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
          tellus felis, tincidunt at neque in, ornare interdum enim.{" "}
        </p> */}
      </div>
    </div>
  );
};

export default withRouter(AddPosLeft);
