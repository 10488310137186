export const hours = [
  {
    time: "12 AM",
    value: 0,
  },
  {
    time: "1 AM",
    value: 1,
  },
  {
    time: "2 AM",
    value: 2,
  },
  {
    time: "3 AM",
    value: 3,
  },
  {
    time: "4 AM",
    value: 4,
  },
  {
    time: "5 AM",
    value: 5,
  },
  {
    time: "6 AM",
    value: 6,
  },
  {
    time: "7 AM",
    value: 7,
  },
  {
    time: "8 AM",
    value: 8,
  },
  {
    time: "9 AM",
    value: 9,
  },
  {
    time: "10 AM",
    value: 10,
  },
  {
    time: "11 AM",
    value: 11,
  },
  {
    time: "12 PM",
    value: 12,
  },
  {
    time: "1 PM",
    value: 13,
  },
  {
    time: "2 PM",
    value: 14,
  },
  {
    time: "3 PM",
    value: 15,
  },
  {
    time: "4 PM",
    value: 16,
  },
  {
    time: "5 PM",
    value: 17,
  },
  {
    time: "6 PM",
    value: 18,
  },
  {
    time: "7 PM",
    value: 19,
  },
  {
    time: "8 PM",
    value: 20,
  },
  {
    time: "9 PM",
    value: 21,
  },
  {
    time: "10 PM",
    value: 22,
  },
  {
    time: "11 PM",
    value: 23,
  },
];
