import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import DayAndDate from "../../components/DayAndDate";
import "./Airtime.scss";
import { Alert, Form } from "react-bootstrap";
import LoadingOverLay from "../../components/LoadingOverLay";
import axios from "axios";
import { useSelector } from "react-redux";
import { thousandSeparator } from "../../utils/thousandSeparator";
import { validateTvAndCablePayment } from "./paymentValidation";
import { useHistory } from "react-router-dom";
import Cleave from "cleave.js/react";
import { fireAlert } from "../../utils/Alert";
import { errorHandler } from "../../utils/errorHandler";
import { BsChevronLeft } from "react-icons/bs";

function InternetBills() {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [sourceAccount, setSourceAccount] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [network, setNetwork] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [billers, setBillers] = React.useState([]);
  const [loadBillers, setLoadBillers] = React.useState("");

  const [billersItem, setBillersItem] = React.useState([]);
  const [loadBillersItem, setLoadBillersItem] = React.useState("");
  const [name, setName] = React.useState("");
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAmount, setShowAmount] = React.useState(false);
  const [reNew, setReNew] = useState("");
  const [errors, setErrors] = React.useState([]);
  const initialState = {
    sourceAccount: "",
    billerName: "",
    billerItem: "",
    customerId: "",
    customerMobile: "",
    amount: "",
    pin: "",
    biller: "",
  };
  const [formData, setFormData] = React.useState(initialState);

  const accounts = useSelector((state) => state.accounts.accounts);

  const handleChange = (prop) => (e) => {
    setFormData({
      ...formData,
      [prop]: e.target.value,
    });
  };

  const handlePayment = () => {
    formData.name = name;
    console.log(formData.amount, "amount");
    let fixedAmount = formData.billerItem
      ? JSON.parse(formData.billerItem)?.amount
      : "";

    if (validateTvAndCablePayment(formData).length > 0) {
      setErrors(validateTvAndCablePayment(formData));
      return setShowAlert(true);
    }

    const billerDetails =
      formData.billerItem.length > 0 ? JSON.parse(formData.billerItem) : "";
    let number = formData.customerMobile.substr(1);
    number = `+234${number}`;

    console.log(billerDetails, "details");
    setLoading(true);
    const payload = {
      source_account_number: formData.sourceAccount,
      amount: showAmount
        ? formData.amount
        : reNew
        ? reNew?.amount
        : fixedAmount,
      customer_mobile: number,
      payment_code: reNew ? reNew?.payment_code : billerDetails?.payment_code,
      pin: formData.pin,
      category_id: billerDetails?.category_id,
      biller_name: billerDetails?.payment_item_name,
      payment_item_name: billerDetails?.payment_item_name,
      customer_id: formData.customerId,
    };

    console.log(payload, "payload");
    let title, html, icon;
    axios
      .post(`${process.env.REACT_APP_API}/bills/payment`, payload)
      .then((res) => {
        setFormData(initialState);
        if (res.data.data.bill.status == "success") {
          setShowAlert(false);
          title = "Internet Purchase Successful";
          html = `Your internet purchase
              to ${billerDetails?.categoryid} was successful`;
          icon = "success";
          fireAlert(title, html, icon);
        } else if (res.data.data.bill.status == "pending") {
          setShowAlert(false);
          title = "Internet Purchase Pending";
          html = `Your internet purchase
              to ${billerDetails?.categoryid} is pending`;
          icon = "info";
          fireAlert(title, html, icon);
        } else {
          title = "Internet Purchase UnSuccessful";
          html = `Your internet purchase
              to ${billerDetails?.categoryid} was unsuccessful`;
          icon = "info";
          fireAlert(title, html, icon);
        }
      })
      .catch((err) => {
        title = "Internet Purchase Failed";
        html = "An error occured";
        icon = "error";
        fireAlert(title, html, icon);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTvBiller = () => {
    setLoadBillers("loading...");
    axios
      .get(`${process.env.REACT_APP_API}/bills/categories/5`)
      .then((res) => {
        if (res.data.status == "success") {
          setErrors([]);
          setLoadBillers("");
          setBillers(res.data.data.billers);
          return setShowAlert(false);
        } else {
          setErrors(["Unable to fetch tv "]);
          return setShowAlert(true);
        }
      })
      .catch((err) => {
        setErrors(["An error occured"]);
        return setShowAlert(true);
      });
  };

  const getBillerItem = () => {
    setLoadBillersItem("Loading...");
    axios
      .get(
        `${process.env.REACT_APP_API}/bills/payment-item/${formData.billerName}`
      )
      .then((res) => {
        setLoadBillersItem("");
        if (res.data.status == "success") {
          setErrors([]);
          setBillersItem(res.data?.data?.payment_item);
          return setShowAlert(false);
        } else {
          setErrors(["Unable to fetch tv "]);
          return setShowAlert(true);
        }
      })
      .catch((err) => {
        setLoadBillersItem("");
        setErrors(["An error occured"]);
        return setShowAlert(true);
      });
  };

  const validateCustomer = () => {
    if (
      formData.customerId &&
      formData.billerName &&
      formData.billerItem.length > 0
    ) {
      setName("Validating Customer ID...");
      const payload = {
        customer_id: formData.customerId,
        payment_code: JSON.parse(formData.billerItem)?.payment_code,
      };
      axios
        .post(`${process.env.REACT_APP_API}/bills/validate-customer`, payload)
        .then((res) => {
          if (res.data.status == "success") {
            setName(res.data?.data?.customer?.full_name);
            console.log(res.data?.data?.customer, "datata");
            if (res.data?.data?.customer?.amount > 0) {
              console.log("renew");
              formData.amount = res.data?.data?.customer?.amount;
              setReNew(res.data?.data?.customer);
            }
            setErrors([]);
            setShowAlert(false);
          } else {
            setName("");
            setErrors(["Unable to validate customer id"]);
            return setShowAlert(true);
          }
        })
        .catch((err) => {
          console.log(err.response, err.message, "validate-customer");
          setName("");
          setErrors(["An error occured"]);
          return setShowAlert(true);
        });
    }
  };
  React.useEffect(() => {
    fetchTvBiller();
  }, []);

  React.useEffect(() => {
    if (formData.billerName) {
      getBillerItem();
    }
  }, [formData.billerName]);

  React.useEffect(() => {
    const billerDetails =
      formData.billerItem.length > 0 ? JSON.parse(formData.billerItem) : "";

    if (billerDetails.is_amount_fixed == false) {
      setShowAmount(true);
    } else {
      setShowAmount(false);
    }
  }, [formData.billerItem]);

  return (
    <div className="vh-100">
      <LoadingOverLay loading={loading} />
      <Helmet>
        <title>Bills payment - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Bills payment</h1>
          <DayAndDate />
          <button
            type="button"
            className="btn btn-link text-dark"
            onClick={() => history.goBack()}
          >
            <BsChevronLeft />
            Back
          </button>
          <div className="airtime-container px-4">
            <h3 className="text-primary sendmoney-header py-2">
              Internet Subscription
            </h3>
            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
              show={showAlert}
            >
              {errors.map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </Alert>
            <div className="">
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                The account to be debited
                <Form.Control
                  as="select"
                  name="accountNumber"
                  value={formData.sourceAccount}
                  onChange={handleChange("sourceAccount")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option
                        key={index}
                        value={account.account_number}
                        style={{}}
                      >
                        {account.account_name} - {account.type.name} - &#8358;
                        {thousandSeparator(account.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Internent Provider
                <Form.Control
                  as="select"
                  name="billerName"
                  value={formData.billerName}
                  onChange={handleChange("billerName")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Provider --</option>
                  {billers &&
                    billers.map((biller, index) => (
                      <option key={index} value={biller.biller_id} style={{}}>
                        {biller.biller_name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Internet Plan
                <Form.Control
                  as="select"
                  name="billerItem"
                  value={formData.billerItem}
                  onChange={handleChange("billerItem")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">
                    {loadBillersItem ? loadBillersItem : "-- Choose Plan --"}
                  </option>
                  {billersItem &&
                    billersItem.map((biller, index) => (
                      <option
                        key={index}
                        // value={billersItem[index]}
                        value={JSON.stringify(biller)}
                        style={{}}
                      >
                        {`${biller.payment_item_name} - ₦${biller?.display_amount}`}
                      </option>
                    ))}
                </Form.Control>
                {formData.billerItem && (
                  <p className="text-success mt-n3">
                    Service charge: ₦
                    {JSON.parse(formData.billerItem).display_item_fee}
                  </p>
                )}
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className="">{name || " Customer ID"}</Form.Label>

                <Form.Control
                  as="input"
                  name="customerId"
                  autoComplete={"new-password"}
                  onBlur={() => validateCustomer()}
                  value={formData.customerId}
                  onChange={handleChange("customerId")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group>

              {showAmount && (
                <div>
                  <p
                    className="sendmoney-select_acc-label"
                    style={{ marginBottom: -3 }}
                  >
                    Amount
                  </p>

                  <Cleave
                    options={{
                      numeral: true,
                      numeralThousandsGroupStyle: "thousand",
                      prefix: "₦ ",
                      rawValueTrimPrefix: true,
                    }}
                    style={{
                      background: "#e2f3f3",
                      border: "1px solid #79b0b0",
                    }}
                    className="select-input"
                    id="amount-input"
                    name="amount"
                    value={formData.amount}
                    onChange={(e) =>
                      setFormData({ ...formData, amount: e.target.rawValue })
                    }
                  />
                </div>
              )}

              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Phone Number
                <Form.Control
                  as="input"
                  name="customerMobile"
                  value={formData.customerMobile}
                  onChange={handleChange("customerMobile")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Pin
                <Form.Control
                  type={"password"}
                  autoComplete="new-password"
                  maxLength={4}
                  as="input"
                  name="pin"
                  value={formData.pin}
                  onChange={handleChange("pin")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
              onClick={handlePayment}
              // disabled={name == "Loading..." || name == "" ? true : false}
            >
              Proceed
            </button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default InternetBills;
