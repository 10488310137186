import React, { useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../components/InputField";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const RegisterForm1 = ({ setStep, account, setAccount }) => {
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ type: "", message: [] });

  const handleSubmit = (values) => {
    if (!values.email && !values.telephone)
      return setAlert({
        type: "danger",
        message: ["Enter your email or phone number"],
      });
    setLoading(true);
    setAlert({ type: "", message: [] });
    setAccount({ ...account, ...values });
    axios
      .post(`${process.env.REACT_APP_API}/tokens/registration`, values)
      .then((res) => {
        setLoading(false);
        setAlert({ type: "success", message: [res.data.data.message] });
        setTimeout(() => setStep(2), 1000);
      })
      .catch((err) => {
        setLoading(false);
        //add errors to error array
        const apiErrors = err.response.data.error;
        const errors = [];
        if (err.response.status === 422) {
          for (const property in apiErrors) {
            errors.push(...apiErrors[property]);
          }
        } else {
          for (const property in apiErrors) {
            errors.push(apiErrors[property]);
          }
        }
        setAlert({
          type: "danger",
          message: errors,
        });
      });
  };

  const validate = Yup.object({
    email: Yup.string().email(),
    telephone: Yup.string(),
  });

  return (
    <>
      <div className="reg-msg mt-2 mb-3">
        <h2>We will send verification codes to verify it's you</h2>
        <small>Enter your email or phone number</small>
      </div>
      <div className="text-left form-container">
        {alert.message.length > 0 && (
          <div className={`alert alert-${alert.type}`} role="alert">
            {/* new Set removes duplicates */}
            {alert.message.map((msg, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {msg}
              </p>
            ))}
          </div>
        )}
        <Formik
          initialValues={{
            email: "",
            telephone: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputField
                label="EMAIL ADDRESS"
                name="email"
                type="email"
                placeholder="EMAIL ADDRESS"
                style={{ width: "100%" }}
              />
              <p className="reg-label pt-3">PHONE NUMBER</p>
              <PhoneInput
                value=""
                inputProps={{
                  name: "telephone",
                  required: true,
                }}
                inputStyle={{
                  background: "#f3f5fc",
                  height: "43px",
                }}
                inputClass="w-100"
                containerClass="mb-3"
                onChange={(telephone) =>
                  formik.setFieldValue("telephone", `${"+" + telephone}`)
                }
                country={"ng"}
                placeholder="phone number"
                defaultErrorMessage="required"
                enableSearch={true}
              />
              <button
                type="submit"
                className="btn btn-primary mt-2 login-btn"
                style={{ width: "100%" }}
                disabled={isLoading}
              >
                {isLoading ? "Please wait..." : "Send Verification Codes"}
              </button>
            </Form>
          )}
        </Formik>
        <p className="text-center py-4 reg-bottom-text">
          Already have an account?
          <span className="text-primary ml-1">
            <Link to="/login">Login</Link>
          </span>
        </p>
      </div>
    </>
  );
};

export default RegisterForm1;
