import React from "react";
import { Helmet } from "react-helmet-async";
import DayAndDate from "../../components/DayAndDate";
import "./Airtime.scss";
import { Alert, Form } from "react-bootstrap";
import LoadingOverLay from "../../components/LoadingOverLay";
import axios from "axios";
import { useSelector } from "react-redux";
import { thousandSeparator } from "../../utils/thousandSeparator";
import { validateAirtimepurchase } from "./paymentValidation";
import { BsChevronLeft } from "react-icons/bs";

function MobileData() {
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [network, setNetwork] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [biller, setBiller] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  const [billersItem, setBillersItem] = React.useState([]);
  const [loadBillersItem, setLoadBillersItem] = React.useState("");
  const [name, setName] = React.useState("");
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);

  const [formData, setFormData] = React.useState({
    sourceAccount: "",
    billerName: "",
    billerItem: "",
    customerId: "",
    customerMobile: "",
    amount: "",
    pin: "",
    biller: "",
  });

  const accounts = useSelector((state) => state.accounts.accounts);

  const handleChange = (prop) => (e) => {
    setFormData({
      ...formData,
      [prop]: e.target.value,
    });
  };

  const handlePayment = () => {
    if (validateAirtimepurchase(formData)) {
      setErrors(validateAirtimepurchase(formData));
      return setShowAlert(true);
    }
  };

  const fetchDataBiller = () => {
    axios
      .get(`${process.env.REACT_APP_API}/bills/categories/4`)
      .then((res) => {})
      .catch((err) => "");
  };
  const getBillerItem = () => {
    axios
      .get(`${process.env.REACT_APP_API}/bills/payment-item/402`)
      .then((res) => {})
      .catch((err) => "");
  };
  React.useEffect(() => {
    fetchDataBiller();
    getBillerItem();
  }, []);
  return (
    <div className="vh-100">
      <LoadingOverLay loading={loading} />
      <Helmet>
        <title>Bills payment - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Bills payment</h1>
          <DayAndDate />

          <div className="airtime-container px-4">
            <h3 className="text-primary sendmoney-header py-2">Buy Data</h3>

            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
              show={showAlert}
            >
              {errors.map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </Alert>
            <div className="">
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                The account to be debited
                <Form.Control
                  as="select"
                  name="source_account"
                  value={formData.sourceAccount}
                  onChange={handleChange("sourceAccount")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option key={index} value={account.account_number}>
                        {account.account_name} - {account.type.name} - &#8358;
                        {thousandSeparator(account.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Network
                <Form.Control
                  as="select"
                  name="network"
                  value={network}
                  onChange={(text) => setNetwork(text)}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option key={index} value={account.account_number}>
                        {account.account_name} - {account.type.name} - &#8358;
                        {thousandSeparator(account.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              {/* <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Data Plan
                <Form.Control
                  as="select"
                  name="network"
                  value={network}
                  onChange={(text) => setNetwork(text)}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option
                        key={index}
                        value={account.account_number}
                        style={{ background: "red" }}
                      >
                        {account.account_name} - {account.type.name} - &#8358;
                        {thousandSeparator(account.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Phone number
                <Form.Control
                  as="input"
                  name=""
                  value={phoneNumber}
                  onChange={(text) => setPhoneNumber(text)}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Pin
                <Form.Control
                  as="input"
                  name="pin"
                  value={pin}
                  onChange={(text) => setPin(text)}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group> */}
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
              onClick={handlePayment}
              // disabled={true}
            >
              Proceed
            </button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default MobileData;
