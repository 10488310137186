import Form from "react-bootstrap/Form";

function Switch({ label, onChange, checked }) {
  return (
    <Form.Check
      id="switch-1"
      type="switch"
      label={label}
      checked={checked}
      onChange={onChange}
    />
  );
}

export default Switch;
