import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const AccountName = ({
  transaction,
  setTransaction,
  setLoading,
  newAccount,
}) => {
  const [sourceAccountNumber, setSourceAccountNumber] = useState("");
  const accounts = useSelector((state) => state.accounts.accounts);

  const {
    beneficiary_account_number,
    beneficiary_bank_name,
    beneficiary_bank_code,
    beneficiary_account_name,
    account_id,
  } = transaction;

  useEffect(() => {
    console.log("useEffect for accounts and account_id called");
    if (Array.isArray(accounts) && accounts.length > 0 && account_id) {
      const account = accounts.find((account) => account.id === account_id);
      if (account) {
        setSourceAccountNumber(account.account_number);
        console.log("Source account number set:", account.account_number);
      } else {
        console.log("Account not found for account_id:", account_id);
      }
    } else {
      setSourceAccountNumber("");
      console.log("Accounts array is empty or account_id is not defined", { accounts, account_id });
    }
  }, [accounts, account_id]);

  const fetchAccName = useCallback(() => {
    console.log("fetchAccName called");
    if (!sourceAccountNumber || !beneficiary_account_number || !beneficiary_bank_code) {
      console.log("Missing required information for fetching account name", {
        sourceAccountNumber,
        beneficiary_account_number,
        beneficiary_bank_code,
      });
      return;
    }

    setLoading(true);
    setTransaction((prevTransaction) => ({
      ...prevTransaction,
      beneficiary_account_name: "Loading...",
    }));

    axios
      .post(`/v1/accounts/validate`, {
        source_account_number: sourceAccountNumber,
        account_number: beneficiary_account_number,
        bank_code: beneficiary_bank_code,
      })
      .then((res) => {
        console.log("Fetch account name response:", res.data);
        setTransaction((prevTransaction) => ({
          ...prevTransaction,
          beneficiary_account_name: res.data.data.account_name,
        }));
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching account name:", {
          error: err,
          sourceAccountNumber,
          beneficiary_account_number,
          beneficiary_bank_code,
        });
        setTransaction((prevTransaction) => ({
          ...prevTransaction,
          beneficiary_account_name: "",
        }));
        setLoading(false);
      });
  }, [sourceAccountNumber, beneficiary_account_number, beneficiary_bank_code, setTransaction, setLoading]);

  useEffect(() => {
    console.log("useEffect for fetching account name called", {
      beneficiary_account_number,
      beneficiary_bank_name,
      sourceAccountNumber,
      newAccount,
    });
    if (
      beneficiary_account_number &&
      beneficiary_bank_name &&
      sourceAccountNumber &&
      beneficiary_account_number.length >= 10 &&
      newAccount
    ) {
      fetchAccName();
    }
  }, [
    beneficiary_account_number,
    beneficiary_bank_name,
    sourceAccountNumber,
    newAccount,
    fetchAccName,
  ]);

  return (
    <div>
      <Form.Group controlId="formBasicText2">
        <Form.Label className="sendmoney-select_acc-label">
          Account Name
        </Form.Label>
        <Form.Control
          name="beneficiary_account_name"
          type="text"
          placeholder=""
          className="select-input"
          value={beneficiary_account_name}
          readOnly
        />
      </Form.Group>
    </div>
  );
};

export default AccountName;
