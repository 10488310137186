import React from "react";
import InputField from "../../../components/InputField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { fireAlert } from "../../../utils/Alert";
import axios from "axios";
import { useState } from "react";
import { errorHandler } from "../../../utils/errorHandler";

const ChangePin = () => {
  const [loading, setLoading] = useState(false);

  const validate = Yup.object({
    old_pin: Yup.string().required("Please enter your current pin"),
    pin: Yup.string()
      .max(4, "Pin must be 4 characters")
      .required("New pin is required"),
    pin_confirmation: Yup.string()
      .oneOf([Yup.ref("pin"), null], "Pins must match")
      .required("Please confirm pin"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    axios
      .patch(`${process.env.REACT_APP_API}/security/pin`, values)
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "Your transaction pin has been changed successfully";
        const icon = "success";
        fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html =errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  return (
    <div className="edit-profile">
      <h2 className="settings-small-header">Change Pin</h2>
      <div>
        <Formik
          initialValues={{
            old_pin: "",
            pin: "",
            pin_confirmation: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputField
                label="Old Pin"
                name="old_pin"
                type="password"
                placeholder=""
                style={{ width: "100%" }}
              />
              <InputField
                label="New Pin"
                name="pin"
                type="password"
                placeholder=""
                style={{ width: "100%" }}
              />
              <InputField
                label="Confirm Pin"
                name="pin_confirmation"
                type="password"
                placeholder=""
                style={{ width: "100%" }}
              />

              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Update Pin"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePin;
