import * as Yup from "yup";

// Formik Validation Schema for Get New Card Form
export const validatePosRequest = Yup.object().shape({
  pos_type: Yup.string().required("Please select a pos type"),
  account_id: Yup.string().required("Please select account"),
  delivery_option: Yup.string().required("Please select delivery option"),
  existing_address: Yup.string().when("delivery_option", {
    is: "dispatch",
    then: Yup.string().required("Please select existing address option."),
  }),
  address_line_one: Yup.string().when("existing_address", {
    is: "false",
    then: Yup.string().required("Please enter address."),
  }),
  address_line_two: Yup.string(),
  country: Yup.string().when("existing_address", {
    is: "false",
    then: Yup.string().required("Please select country."),
  }),
  state: Yup.string(),
  // .when("existing_address", {
  //   is: "false",
  //   then: Yup.string().required("Please select state."),
  // }),
  city: Yup.string().when("existing_address", {
    is: "false",
    then: Yup.string().required("Please select city."),
  }),
  pin: Yup.string().max(4).required("Please enter pin"),
  paper_display: Yup.string().required("Please choose an option"),
  stand: Yup.string().required("Please choose an option"),
  pos_payment_method: Yup.string().required("Please select a payment method"),
  installment_amount: Yup.string().when("pos_payment_method", {
    is: "installment",
    then: Yup.string().required("Please select installment amount."),
  }),
  down_payment_amount: Yup.string().when("pos_payment_method", {
    is: "installment",
    then: Yup.string().required("Please select down payment amount."),
  }),
});
