import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import DayAndDate from "../../../components/DayAndDate";
import BackArrow from "../../../assets/back-arrow.svg";
import "./RecurringPayments.scss";
import PageLoader from "../../../components/PageLoader";
import RecurringCards from "./RecurringCards";
import { useDispatch } from "react-redux";
import { getSchedules } from "../../../redux/ducks/schedules";

const RecurringPayments = ({ history }) => {
  const dispatch = useDispatch();

  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  // fires reload on delete
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    // get card payments
    axios
      .get(`${process.env.REACT_APP_API}/card-payments`)
      .then((res) => {
        setLoading(false);
        setPayments(res.data.data.card_payments.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, [deleted]);

  useEffect(() => {
    //get schedules
    dispatch(getSchedules());
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Recurring Payments - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Recurring Payments</h1>
          <DayAndDate />
          <span
            className="d-flex align-items-center cursor-pointer"
            onClick={() => history.push("/fund-account")}
          >
            <img src={BackArrow} alt="Back Arrow" />
            <span className="pl-1">Back</span>
          </span>
          <RecurringCards
            payments={payments}
            deleted={deleted}
            setDeleted={setDeleted}
          />
        </main>
      </div>
    </>
  );
};

export default RecurringPayments;
