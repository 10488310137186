import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import DayAndDate from "../../components/DayAndDate";
import AirtimePurchase from "./airtime_purchase/Airtime_purchase";
import DataPurchase from "./data_purchase/DataPurchase";
import TvandCable from "./tvandCable/TvandCable";
import "./Billspayment.scss";
import ElectricityPayment from "./electricityPayment/ElectricityPayemnt";
import utilityBill from "../../assets/utilityBill.png";
import cableBill from "../../assets/cableBill.png";
import airtime from "../../assets/airtime.png";
import phoneBills from "../../assets/phoneBills.png";
import subscription from "../../assets/subscription.png";
import data from "../../assets/data.png";
import { Link, withRouter } from "react-router-dom";

const menu = [
  {
    label: "Utility Bills",
    text: "Pay your utility bills here",
    icon: utilityBill,
    screen: "/utility-bills",
  },
  {
    label: "Cable TV Bills",
    text: "Pay for your cable TV subscriptions here",
    icon: cableBill,
    screen: "/tv-subscription",
  },
  {
    label: "Airtime Recharge",
    text: "Get your airtime with zero stress",
    icon: airtime,
    // screen: "/mobile-data",
    screen: "/airtime-payment",
  },
  {
    label: "Data",
    text: "Get your Data with zero stress",
    icon: data,
    screen: "/postpaid-bills",
  },
  {
    label: "Subscriptions",
    text: "Pay for your other subscriptions (like ISP) here",
    icon: subscription,
    screen: "/internet-bills",
  },
];

const BillCard = ({ item }) => {
  return (
    <div className="billsContainer text-center">
      <Link to={item.screen} style={{ textDecoration: "none" }}>
        <div className="text-center">
          <img src={item.icon} />
        </div>

        <div>
          <p className="title mt-3">{item.label}</p>
          <p className="title-sub">{item.text}</p>
        </div>
      </Link>
    </div>
  );
};
const Billspayment = ({ location, history }) => {
  const { pathname } = location;

  return (
    <div className="vh-100">
      <Helmet>
        <title>Bills payment - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Bills payment</h1>
          <DayAndDate />

          <div className="mt-4 d-sm-block d-md-flex flex-wrap  lg-flex-nowrap">
            {menu.map((item, index) => (
              <BillCard item={item} key={index} />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Billspayment;
