export const getProviderName = (account) => {
  let suffix = "Bank";
  if (account?.currency?.toLowerCase()) {
    suffix = account?.currency?.toUpperCase();
  }

  return `${account?.provider} ${suffix}`;
};

/**
 * Get currency symbol from account information
 * @returns currency symbol
 */
export const getCurrencySymbol = (account) => {
  switch (account?.currency?.toLowerCase()) {
    case "usd":
      return "$";

    case "eur":
      return "€";

    default:
      return "₦";
  }
};
