import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import ScrollToTop from "./components/ScrollToTop";
import Cards from "./pages/cards/Cards";
import Dashboard from "./pages/dashboard/Dashboard";
import ForgotPass from "./pages/forgot_password/ForgotPass";
import FundAccount from "./pages/fund_account/FundAccount";
import Investment from "./pages/investment/Investment";
// import Loan from "./pages/loan/Loan";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
// import RegOTP from "./pages/reg_otp/RegOTP";
import SendMoney from "./pages/send_money/SendMoney";
import Settings from "./pages/settings/Settings";
import Billspayment from "./pages/bills_payment/Billspayment";
import MobileData from "./pages/bills_payment/MobileData";
import Transactions from "./pages/transactions/Transactions";
import UserMenu from "./components/user_menu/UserMenu";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "./redux/ducks/user";
import axios from "axios";
import Cookies from "js-cookie";
import { getAccounts } from "./redux/ducks/accounts";
import TransactionDetail from "./pages/transactions/transaction_detail/TransactionDetail";
import Accounts from "./pages/accounts/Accounts";
import PrivateRoute from "./components/PrivateRoute";
import Page404 from "./pages/404/Page404";
import TermsAndConditions from "./pages/terms_and_conditions/TermsAndConditions";
import { sessionExpired } from "./utils/sessionExpired";
import RecurringPayments from "./pages/fund_account/recurring_payments/RecurringPayments";
import SuccessPage from "./pages/success_page/SuccessPage";
import FreshChat from "./components/FreshChat";
import IdleWrapper from "./components/idle_wrapper/IdleWrapper";
import Beneficiaries from "./pages/manage_beneficiaries/Beneficiaries";
import { getAccountTypes } from "./redux/ducks/accountTypes";
import AutoTransfer from "./pages/auto_transfer/AutoTransfer";
import ManageAutoTransfer from "./pages/auto_transfer/manage_auto_transfer/ManageAutoTransfer";
import Pos from "./pages/pos/Pos";
import AuthRoute from "./components/AuthRoute";
import { getProviders } from "./redux/ducks/providers";
import Airpayment from "./pages/bills_payment/Airtime";
import InternetBills from "./pages/bills_payment/Internet";
import TvSubscription from "./pages/bills_payment/TvSubscription";
import UtilityBills from "./pages/bills_payment/Utility";
import PostPaidBills from "./pages/bills_payment/PostPaidBills";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const removeData = () => {
  Cookies.remove("isAuthenticated");
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  delete axios.defaults.headers.common["Authorization"];
};

// Add a 401 response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response ? error.response.status : null;
    const url = error.response ? error.response.config.url : null;

    if (status === 401 && url === `${process.env.REACT_APP_API}/refresh`) {
      sessionExpired();
      removeData();
    } else if (status === 401 && url !== `${process.env.REACT_APP_API}/refresh`) {
      axios
        .get(`${process.env.REACT_APP_API}/refresh`, {
          headers: { "X-Refresh-Token": Cookies.get("refreshToken") },
        })
        .then((res) => {
          Cookies.set("token", res.data.data.token);
          Cookies.set("refreshToken", res.data.data.refresh_token);
          window.location.reload();
        })
        .catch((err) => {
          removeData();
          toast.error("Session expired. Please log in again.");
          window.location.replace("/login");
        });
    } else if (error.toJSON().message === "Network Error") {
      toast.error("Network Error, please ensure you have internet connection");
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountTypes());

    // Load user details if authenticated
    if (Cookies.get("isAuthenticated") && Cookies.get("token")) {
      dispatch(getUser());
      dispatch(getAccounts());
      dispatch(getProviders());
    }
  }, [dispatch]);

  return (
    <>
      <FreshChat />
      <IdleWrapper />
      <ScrollToTop>
        <UserMenu />
        <Switch>
          <Route exact path="/fund-account/success" component={SuccessPage} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/cards" render={(props) => <Cards {...props} />} />
          <PrivateRoute path="/pos" render={(props) => <Pos {...props} />} />
          <PrivateRoute exact path="/send-money" component={SendMoney} />
          <PrivateRoute exact path="/auto-transfer" component={AutoTransfer} />
          <PrivateRoute exact path="/auto-transfer/manage-auto-transfer" component={ManageAutoTransfer} />
          <PrivateRoute exact path="/send-money/beneficiaries" component={Beneficiaries} />
          <PrivateRoute exact path="/fund-account" component={FundAccount} />
          <PrivateRoute exact path="/fund-account/recurring-payments" component={RecurringPayments} />
          <PrivateRoute path="/investment" render={(props) => <Investment {...props} />} />
          <PrivateRoute exact path="/transactions/:id" component={TransactionDetail} />
          {/* <PrivateRoute exact path="/loan" component={Loan} /> */}
          <PrivateRoute path="/settings" render={(props) => <Settings {...props} />} />
          <PrivateRoute path="/bills-payment" render={(props) => <Billspayment {...props} />} />
          <PrivateRoute exact path="/airtime-payment" render={(props) => <Airpayment {...props} />} />
          <PrivateRoute exact path="/mobile-data" render={(props) => <MobileData {...props} />} />
          <PrivateRoute exact path="/internet-bills" render={(props) => <InternetBills {...props} />} />
          <PrivateRoute exact path="/tv-subscription" render={(props) => <TvSubscription {...props} />} />
          <PrivateRoute exact path="/utility-bills" render={(props) => <UtilityBills {...props} />} />
          <PrivateRoute exact path="/postpaid-bills" render={(props) => <PostPaidBills {...props} />} />
          <PrivateRoute path="/accounts" render={(props) => <Accounts {...props} />} />
          <PrivateRoute path="/transactions" render={(props) => <Transactions {...props} />} />
          <AuthRoute exact path="/register" component={Register} />
          {/* <Route exact path="/register/enter-otp" component={RegOTP} /> */}
          <AuthRoute exact path="/login" component={Login} />
          <Route exact path="/terms-of-service" component={TermsAndConditions} />
          <AuthRoute exact path="/forget-password" component={ForgotPass} />
          <Route component={Page404} />
        </Switch>
      </ScrollToTop>
      <ToastContainer />
    </>
  );
}

export default App;
