export const GET_BANKS = "get banks";
const SET_BANKS = "set banks";
const REMOVE_BANKS = "remove banks";
const LOADING_BANKS = "loading banks";

export const loadingBanks = () => ({ type: LOADING_BANKS });

export const setBanks = (banks) => ({
  type: SET_BANKS,
  banks,
});

export const getBanks = (source_account_number) => ({
  type: GET_BANKS,
  source_account_number,
});

export const removeBanks = () => ({ type: REMOVE_BANKS });

const initailState = {
  banks: undefined,
  loading: false,
};

const banksReducer = (state = initailState, action) => {
  switch (action.type) {
    case LOADING_BANKS:
      return { banks: undefined, loading: true };
    case SET_BANKS:
      return { ...state, banks: [], loading: false };
    case REMOVE_BANKS:
      return { ...state, banks: undefined, loading: false };
    default:
      return state;
  }
};

export default banksReducer;
