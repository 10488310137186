import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { logoutUser } from "../../redux/configureStore";
import { IdleTimeOutModal } from "./IdleModal";
import IdleTimerComponent from "./IdleTimerComponent";

const IdleWrapper = ({ history }) => {
  const dispatch = useDispatch();

  //for idle modal
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const handleLogout = () => {
    setShowModal(false);
    delete axios.defaults.headers.common["Authorization"];
    Cookies.remove("token");
    Cookies.remove("isAuthenticated");
    dispatch(logoutUser());
    history.replace("/login");
  };

  return (
    <>
      <IdleTimeOutModal
        showModal={showModal}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
      <IdleTimerComponent
        handleLogout={handleLogout}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default withRouter(IdleWrapper);
