import { ErrorMessage, useField } from "formik";
import React from "react";

// the css for the non bootstrap classes can be found in register/Register.scss
const InputDropDown = ({ label, noValue, options,values, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="py-3">
      {/* render label if there is a label */}
      {label && (
        <label htmlFor={field.name} className="reg-label">
          {label}
        </label>
      )}
      <select
        className={`form-control shadow-name reg-input ${
          meta.touched && meta.error && "is-invalid"
        }`}
        id={field.name}
        {...field}
        {...props}
        autoComplete="off"
      >
        {noValue && <option value="">{noValue}</option>}
        {options.map((item, index) => (
          <option key={index} value={values?values[index]:item}>
            {item}
          </option>
        ))}
      </select>
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

export default InputDropDown;
