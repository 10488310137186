import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../../components/InputField.jsx";
import InputDropDown from "../../../components/InputDropDown";
import InputFile from "../../../components/InputFile.jsx";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert.js";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader.jsx";
import { errorHandler } from "../../../utils/errorHandler.js";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getProviders } from "../../../redux/ducks/providers.js";

const CreateAccount = () => {
  const dispatch = useDispatch();

  const accountTypes = useSelector((state) => state.accountTypes.accountTypes);
  const providers = useSelector((state) => state.providers.providers);
  const user = useSelector((state) => state.user);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getProviders());
  }, []);

  if (!accountTypes || !providers) return <PageLoader />;

  // get account ids
  const savings = accountTypes.filter((type) => type.name === "Savings")[0].id;
  const business = accountTypes.filter((type) => type.name === "Business")[0]
    .id;
  const currencies = ["NGN", "USD"];

  const accountFormStyles = {
    // background: "#e2f3f3",
    // border: "1px solid #79b0b0",
    textTransform: "capitalize",
    width: "100%",
  };
  // account type uses id
  const validateAccountCreationForm = Yup.object().shape({
    account_type: Yup.string().required("Please select account type"),
    provider: Yup.string().required("Please select a provider"),
    //account_name: Yup.string().required("Please enter an account name"),
    // bvn:
    //   user && !user.kyc
    //     ? Yup.string()
    //         .required("Please enter a valid BVN")
    //         .test(
    //           "Is positive?",
    //           "Please enter positive numbers only",
    //           (value) => value > 0
    //         )
    //         .test(
    //           "len",
    //           "The BVN is not correct",
    //           (val) => val && val.length === 11
    //         )
    //     : Yup.string(),
    // date_of_birth: Yup.string(),
    cac_document: Yup.mixed().when("account_type", {
      is: business,
      then: Yup.mixed().required("Required"),
    }),
  });

  const handleOnChangeProvider = (e, formik) => {
    const provider = e.target.value;

    formik.setFieldValue("provider", provider);
    // if cloudbank is chosen as a provider,
    // automatically choose USD for the account

    if (provider === "cloudbank") {
      formik.setFieldValue("currency", "usd");
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);

    // if (!user.kyc && !date) {
    //   setLoading(false);
    //   const title = "Error";
    //   const html = "Please enter your date of birth";
    //   const icon = "error";
    //   return fireAlert(title, html, icon);
    // }

    let data = { ...values };

    //data.date_of_birth = moment(date).format("YYYY-MM-DD");

    const formData = new FormData();
    let createAccountRoute = `${process.env.REACT_APP_API}/accounts`;
    // loop through values and append to formData
    for (const property in data) {
      if (data[property]) {
        formData.append(property, data[property]);
      }

      // use this endpoint for creating foreign accounts
      if (data[property] === "cloudbank") {
        createAccountRoute = `${process.env.REACT_APP_API}/accounts/cloud-account`;
      }
    }

    axios
      .post(createAccountRoute, formData)
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "New account created successfully";
        const icon = "success";
        fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  return (
    <div className="account-cards-container">
      <div className="row">
        <div className="col-md-4">
          <h2 className="text-primary new-account-title">
            Create another account with <br />
            <span className="title-red">Shanono</span>
          </h2>
        </div>
        <div className="col-md-8">
          <Formik
            initialValues={{
              account_type: "",
              currency: "ngn",
              // account_name: "",
              provider: "",
              // date_of_birth: "",
              // bvn: "",
              cac_document: "",
            }}
            validationSchema={validateAccountCreationForm}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <div>
                  <div className="p-3 p-lg-4 bg-white sendmoney-whitebg">
                    <InputDropDown
                      label="Account Type"
                      noValue="-- Choose Account Type --"
                      name="account_type"
                      options={["Business", "Savings"]}
                      values={[business, savings]}
                      style={accountFormStyles}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "account_type",
                          event.target.value
                        );
                        //clear cac_document to avoid errors when user switch from business to savings
                        formik.setFieldValue("cac_document", "");
                      }}
                    />

                    <InputDropDown
                      label="Provider"
                      noValue="-- Choose Provider --"
                      name="provider"
                      options={Object?.values(providers) ?? []}
                      values={Object?.values(providers) ?? []}
                      style={accountFormStyles}
                      onChange={(e) => handleOnChangeProvider(e, formik)}
                    />

                    <InputDropDown
                      label="Currency"
                      name="currency"
                      options={currencies}
                      values={currencies.map((c) => c.toLowerCase())}
                      style={accountFormStyles}
                    />

                    {/* <InputField
                      label="Account Name"
                      name="account_name"
                      type="text"
                      style={accountFormStyles}
                    /> */}

                    {/* Show this if business account was chosen */}
                    {formik.values.account_type === business && (
                      <InputFile
                        label="Upload valid CAC or Business Name Document"
                        name="cac_document"
                        style={{
                          // background: "#e2f3f3",
                          // border: "1px solid #79b0b0",
                          width: "100%",
                          height: "43px",
                        }}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "cac_document",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                    )}
                    {/* {user && !user.kyc && (
                      <>
                        <InputField
                          label="BVN"
                          name="bvn"
                          type="text"
                          placeholder="12345678901"
                          maxLength={11}
                          inputMode="numeric"
                          style={{ width: "100%" }}
                        />
                        <div className="align-self-center">
                          <p style={{ fontSize: "16px" }}>DATE OF BIRTH</p>
                          <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            selected={date}
                            placeholderText="DD/MM/YYYY"
                            onChange={(date) => setDate(date)}
                            className="form-control reg-input mt-n2 w-100"
                          />
                        </div>
                      </>
                    )} */}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Create Account"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
