import axios from "axios";
import React, { useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";

const AccountName = ({
  transaction,
  setTransaction,
  setLoading,
  newAccount,
}) => {
  const {
    beneficiary_account_number,
    beneficiary_bank_name,
    beneficiary_bank_code,
    beneficiary_account_name,
    source_account_number,
  } = transaction;

  const fetchAccName = useCallback(async (retryCount = 0) => {
    setLoading(true);
    setTransaction((prevTransaction) => ({
      ...prevTransaction,
      beneficiary_account_name: "Loading...",
    }));

    try {
      // console.log("Sending request to fetch account name with data:", {
      //   source_account_number,
      //   account_number: beneficiary_account_number,
      //   bank_code: beneficiary_bank_code,
      // });

      const response = await axios.post(
        `${process.env.REACT_APP_API}/accounts/validate`,
        {
          source_account_number,
          account_number: beneficiary_account_number,
          bank_code: beneficiary_bank_code,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response from fetching account name:", response.data.status);

      const accountName = response.data.data.account_name;
      if (accountName) {
        setTransaction((prevTransaction) => ({
          ...prevTransaction,
          beneficiary_account_name: accountName,
        }));
      } else {
        throw new Error("Account name not found");
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error message:", error.message);
      }

      if (retryCount < 2) {
        console.log(`Retrying fetch account name (${retryCount + 1}/2)`);
        fetchAccName(retryCount + 1);
      } else {
        setTransaction((prevTransaction) => ({
          ...prevTransaction,
          beneficiary_account_name: "",
        }));
      }
    } finally {
      setLoading(false);
    }
  }, [source_account_number, beneficiary_account_number, beneficiary_bank_code, setTransaction, setLoading]);

  useEffect(() => {
    if (
      beneficiary_account_number &&
      beneficiary_bank_name &&
      source_account_number &&
      beneficiary_account_number.length >= 10 &&
      newAccount
    ) {
      fetchAccName();
    }
  }, [beneficiary_account_number, beneficiary_bank_name, source_account_number, newAccount, fetchAccName]);

  return (
    <div>
      <Form.Group controlId="formBasicText2">
        <Form.Label className="sendmoney-select_acc-label">
          Account Name
        </Form.Label>
        <Form.Control
          name="beneficiary_account_name"
          type="text"
          placeholder=""
          className="select-input"
          value={beneficiary_account_name}
          readOnly
        />
      </Form.Group>
    </div>
  );
};

export default AccountName;
