import axios from "axios";
import React, { useState } from "react";
import InputField from "../../../components/InputField";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const VerifyEmailForm1 = ({ setStep, setEmail,savedEmail }) => {

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setErrors([]);
    setLoading(true);
    setEmail(values.email);
    axios
      .post(`${process.env.REACT_APP_API}/tokens/verification/email`, values)
      .then((res) => {
        setLoading(false);
        setStep(2);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const errors = err.response.data.error;
          for (const property in errors) {
            if (typeof errors[property] === "string") {
              apiErrors.unshift(errors[property]);
            } else {
              apiErrors.unshift(errors[property][0]);
            }
          }
          setErrors(apiErrors);
        } else if (err.response.status === 403) {
          setErrors([err.response.data.message]);
        } else {
          setErrors([err.response.data.error.message]);
        }
      });
  };

  const validate = Yup.object({
    email: Yup.string().email().required("Email is required"),
  });

  return (
    <div className="enter-phone">
      {errors.length > 0 && (
        <div className="alert alert-danger login-alert w-100" role="alert">
          {errors.map((item, index) => (
            <p key={index} style={{ fontSize: "14px" }}>
              {item}
            </p>
          ))}
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          email: savedEmail,
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <InputField
              label="ENTER YOUR EMAIL ADDRESS"
              name="email"
              type="email"
              placeholder="EMAIL ADDRESS"
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
                setEmail(e.target.value);
              }}
            />
            <button
              type="submit"
              className="btn btn-primary mt-2 login-btn"
              disabled={loading}
            >
              {loading ? "Please wait..." : "Send Verification Code"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyEmailForm1;
