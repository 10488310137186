import React from "react";
import { Dropdown, Image } from "react-bootstrap";
import { ChartDonut } from "@patternfly/react-charts";
import no_avatar from "../../../assets/no_avatar.png";
import "./RightColumn.scss";
import RightTransactions from "./RightTransactions";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/configureStore";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const RightColumn = ({ history, home }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const userImage = !user ? no_avatar : user.image ? user.image : no_avatar;

  const handleLogout = () => {
    delete axios.defaults.headers.common["Authorization"];
    Cookies.remove("token");
    Cookies.remove("isAuthenticated");
    Cookies.remove("refreshToken"); // Add this if you have a refresh token
    dispatch(logoutUser());
    console.log("You have been logged out successfully");
    toast.success("You have been logged out successfully.");
    history.replace("/login");
  };

  return (
    <div>
      <div className="mt-5 d-flex justify-content-between">
        <div>
          <p className="right-column_header">My Profile</p>
          <p className="mt-n2 percent-complete">
            <span className="text-primary">{home.profileCompletion}%</span>{" "}
            Completed your profile
          </p>
        </div>
        <div>
          <Dropdown className="mr-n2 right-column_dropdown">
            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
              <Image
                src={userImage}
                alt="you"
                className="img-fluid rounded-circle dropdown-circle"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="/settings">Settings</Dropdown.Item>
              <Dropdown.Item onClick={handleLogout}>
                <span className="text-danger">Logout</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="mt-n4 img-area">
        <div style={{ height: "200px", width: "200px", margin: "auto" }}>
          <ChartDonut
            ariaDesc="Percent profile completed"
            ariaTitle="Profile completion"
            constrainToVisibleArea={true}
            data={[
              { x: "Completed", y: home.profileCompletion },
              { x: "Pending", y: 100 - +home.profileCompletion },
            ]}
            subTitle=""
            title=""
            colorScale={["#008080", "#E3E3E3"]}
            height={150}
            width={150}
            padAngle={0}
            innerRadius={50}
          />
        </div>
        <div className="big-img-container">
          <Image
            src={userImage}
            alt="you"
            className="img-fluid rounded-circle big-img"
          />
        </div>
      </div>
      <RightTransactions />
    </div>
  );
};

export default withRouter(RightColumn);