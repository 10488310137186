import axios from "axios";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";

const CancelPaymentModal = ({
  showCancelModal,
  setShowCancelModal,
  payment,
  setPayment,
  deleted,
  setDeleted,
}) => {
  const [password, setPassword] = useState("");
  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setPayment(null);
    setShowCancelModal(false);
    setPassword("");
    setAlerts({ messages: [], type: "" });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!password) {
      setLoading(false);
      return setAlerts({
        messages: ["Password is required"],
        type: "danger",
      });
    }

    axios
      .delete(`${process.env.REACT_APP_API}/card-payments/${payment.id}`, {
        data: { password },
      })
      .then((res) => {
        setDeleted(!deleted);
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  return (
    <Modal show={showCancelModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alerts.messages.length > 0 && (
          <div
            className={`alert alert-${alerts.type} login-alert w-100`}
            role="alert"
          >
            {alerts.messages.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <p>Enter password to continue</p>
        </div>
        <Form.Group controlId="exampleForm.ControlSelect22">
          <Form.Label className="sendmoney-select_acc-label">
            Password
          </Form.Label>
          <Form.Control
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className="reg-input w-100"
            value={password}
          ></Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={handleClose}
          disabled={loading}
        >
          No
        </button>
        <button
          className="btn btn-danger"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? "Processing..." : "Yes"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelPaymentModal;
