import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import { withRouter } from "react-router-dom";

const AddCardBox = ({ history, location }) => {
  const [showCardModal, setShowCardModal] = useState(false);

  const handleShowCardModal = () => setShowCardModal(true);

  const handleCloseCardModal = () => {
    setShowCardModal(false);
  };

  return (
    <>
      {/* <div className="bg-white text-center d-flex justify-content-center align-items-center add-card">
        <div className="btn text-primary"> */}
      {/* Show Card Modal Handler to accept terms and proceed */}
      {/* <p
            onClick={handleShowCardModal}
            className="d-flex flex-column align-items-center"
          >
            <span className="mb-2">
              <FaIcons.FaPlus />
            </span>
            <span>Request for a new card</span>
          </p>
        </div>
      </div> */}
      <div>
        <button
          className={
            location.pathname === "/cards/create"
              ? "d-none"
              : "btn btn-primary d-block ml-auto mr-2 mb-1"
          }
          onClick={handleShowCardModal}
        >
          Request a new card
        </button>
      </div>
      {/* Add Card Modal */}

      <Modal
        show={showCardModal}
        onHide={handleCloseCardModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="cards-modal-content"
      >
        <div className="cards-modal-wrapper">
          <h2 className="cards-header new-card-header">Add Card</h2>
          <p className="mb-0">
            Card costs ₦1,500 for verve and ₦3,500 for master card.
          </p>
          <p className="mb-0">This is excluding doorstep delivery.</p>
          <p className="mb-0">No limits on Naira transactions </p>
          <p className="mb-0">0% charge on local POS transactions</p>
          <div className="d-flex align-items-center justify-content-end cards-footer">
            <span
              className="cards-form-cancel"
              href="#"
              onClick={handleCloseCardModal}
            >
              Cancel
            </span>

            <button
              type="submit"
              className="btn btn-primary cards-form-submit"
              onClick={() => {
                history.push("/cards/create");
                handleCloseCardModal();
              }}
            >
              Request
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withRouter(AddCardBox);
