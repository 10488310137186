import React from "react";
import * as TiIcons from "react-icons/ti";

const SuccessPage = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center mt-5"
      style={{ height: "70vh" }}
    >
      <div className="text-center">
        <h2 style={{ fontSize: "8rem" }}>
          <TiIcons.TiTick className="bg-primary text-white rounded-circle" />
        </h2>
        <p className="text-primary mt-4 px-2">
          Your transaction was successful, please close your browser and return
          to the app
        </p>
      </div>
    </div>
  );
};

export default SuccessPage;
