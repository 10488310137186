import React from "react";
import "./Total.scss";
import TotalDonutChart from "./total_donut_chart/TotalDonutChart";
import { thousandSeparator } from "../../../utils/thousandSeparator";

const Total = ({ home }) => {
  // amount are returned in kobo so I converted to naira
  const totalSavingsAmount = +home.savings / 100;
  const totalBusinessAmount = +home.business / 100;
  const totalInvestmentsAmount = +home.investments / 100;
  return (
    <div className="total-container">
      <div className="total-container_left">
        {/* <div className="big-circle"><span className="total-text">Total</span><p></p>&#8358;2909.98</div> */}
        <TotalDonutChart
          totalBusinessAmount={totalBusinessAmount}
          totalSavingsAmount={totalSavingsAmount}
          totalInvestmentsAmount={totalInvestmentsAmount}
        />
        <div className="bullet-container">
          <div className="bullet-points">
            <div className="total-small-circle"></div>
            <p className="bullet-text">Savings</p>
          </div>
          <div className="bullet-points">
            <div className="total-small-circle red-circle"></div>
            <p className="bullet-text">Business</p>
          </div>
          <div className="bullet-points">
            <div className="total-small-circle ash-circle"></div>
            <p className="bullet-text">Investment</p>
          </div>
        </div>
      </div>
      <div className="total-left">
        <div>
          <p className="right-text">Savings</p>
          <p className="green-text">
            &#8358; {thousandSeparator(totalSavingsAmount)}
          </p>
        </div>
        <div>
          <p className="right-text">Business</p>
          <p className="green-text">
            &#8358; {thousandSeparator(totalBusinessAmount)}
          </p>
        </div>
        <div>
          <p className="right-text">Investment</p>
          <p className="green-text">
            &#8358; {thousandSeparator(totalInvestmentsAmount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Total;
