import React from 'react'
import { ErrorMessage, useField } from "formik";

// the css for the non bootstrap classes can be found in register/Register.scss
const InputFile = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <div className="py-3">
        {/* render label if there is a label */}
        {label && (
          <label htmlFor={field.name} className="reg-label">
            {label}
          </label>
        )}
        {/* <input
          className={`form-control shadow-name reg-input ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
        /> */}
        <input
                  id={field.name}
                  name={field.name}
                  type="file"
                //   onChange={(event) => {
                //     formik.setFieldValue("utilityBill", event.currentTarget.files[0]);
                //   }}
                  className={`form-control shadow-name reg-input ${
                    meta.touched && meta.error && "is-invalid"
                  }`}
                  {...props}
                />
        <ErrorMessage component="div" name={field.name} className="error" />
      </div>
    );
}

export default InputFile
