import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import DayAndDate from "../../components/DayAndDate";
import PageLoader from "../../components/PageLoader";
import {
  getInvestmentTypes,
  getRolloverTypes,
} from "../../redux/ducks/investments";
import "./Investment.scss";
import InvestmentCards from "./investment_cards/InvestmentCards";
import InvestmentForm from "./investment_form/InvestmentForm";
import ViewInvestments from "./view_investments/ViewInvestments";

const Investment = ({ location, history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    //load investment types
    dispatch(getInvestmentTypes());
    //load rolloverTypes
    dispatch(getRolloverTypes());
  }, [dispatch]);

  const [step, setStep] = useState(1);
  const investments = useSelector((state) => state.investments);

  if (!investments.investmentTypes) {
    return <PageLoader />;
  }
  return (
    <>
      <Helmet>
        <title>Investment - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <div className="investment">
          <div className="investment-header">
            <div>
              <h1 className="investment-mainheader">Investment</h1>
              <p
                className={
                  location.pathname === "/investment/all"
                    ? "d-none"
                    : "investment-call-to-action"
                }
              >
                Choose from our available
                <br /> investment plans
              </p>
              <div className="investment-date">
                <DayAndDate />
              </div>
            </div>
            <div className="investment-tags">
              <div
                className={
                  location.pathname !== "/investment"
                    ? "investment-tags_item dim"
                    : "investment-tags_item"
                }
                onClick={() => history.push("/investment")}
              >
                <p>
                  <i className="investment-icon"></i>
                </p>
                <p className="mt-2 investment-text">investment Plans</p>
              </div>
              <div
                className={
                  location.pathname === "/investment/create" && step === 1
                    ? "investment-tags_item"
                    : "investment-tags_item dim"
                }
                onClick={() => {
                  history.push("/investment/create");
                  setStep(1);
                }}
              >
                <p>
                  <i className="investment-icon"></i>
                </p>
                <p className="mt-2 investment-text">Create Investment</p>
              </div>
              <div
                className={
                  location.pathname === "/investment/create" && step === 2
                    ? "investment-tags_item"
                    : "investment-tags_item dim"
                }
                style={{ cursor: "default" }}
              >
                <p>
                  <i className="investment-icon"></i>
                </p>
                <p className="mt-2 investment-text">Confirm Investment</p>
              </div>
            </div>
          </div>
          <div>
            <button
              className={
                location.pathname === "/investment/all"
                  ? "d-none"
                  : "btn btn-primary d-block ml-auto mr-2 mb-1"
              }
              onClick={() => history.push("/investment/all")}
            >
              Your Investments
            </button>
          </div>
          <Switch>
            <Route
              exact
              path="/investment"
              render={(props) => (
                <InvestmentCards
                  {...props}
                  investmentTypes={investments.investmentTypes}
                />
              )}
            />
            <Route
              exact
              path="/investment/create"
              render={(props) => (
                <InvestmentForm
                  {...props}
                  step={step}
                  setStep={setStep}
                  investmentTypes={investments.investmentTypes}
                  rolloverTypes={investments.rolloverTypes}
                />
              )}
            />
            <Route
              exact
              path="/investment/all"
              render={(props) => <ViewInvestments {...props} />}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Investment;
