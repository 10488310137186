import React, { useEffect, useState } from "react";
import axios from "axios";
import DeleteReqModal from "./DeleteReqModal";
import EditReqModal from "./EditReqModal";
import LoadingSpinner from "../../../components/LoadingSpinner";
import moment from "moment";
import Datatable from "react-bs-datatable";
import Unathorized from "../../settings/Unathorized";

const CardRequests = () => {
  const [cardRequests, setCardRequests] = useState(null);

  const [loading, setLoading] = useState(true);
  // for top up modal
  const [showModal, setShowModal] = useState(false);
  // for edit modal
  const [showEdit, setShowEdit] = useState(false);
  // card to be edited or deleted
  const [request, setRequest] = useState(null);
  // this is just to fire useEffect and trigger live update when user do topup
  const [fetch, setFetch] = useState(false);
  const [countries, setCountries] = useState(null);

  const requestCardRequests = axios.get(
    `${process.env.REACT_APP_API}/card-requests`
  );
  const requestCountries = axios.get(`${process.env.REACT_APP_API}/countries`);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .all([requestCardRequests, requestCountries])
      .then(
        axios.spread((res1, res2) => {
          setLoading(false);
          setCardRequests(res1.data.data.card_requests.data);
          setCountries(res2.data.data.countries);
        })
      )
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, [fetch]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!cardRequests) {
    return (
      <div className="text-primary text-center mt-5">
        <p>Something went wrong,please try again later</p>
      </div>
    );
  }

  if (cardRequests.length < 1) {
    return (
      <div className="w-75 m-auto mt-5">
        <Unathorized message="You have no card requests." />
      </div>
    );
  }

  //header for data table
  const header = [
    {
      title: "CARD TYPE",
      prop: "type",
      filterable: true,
      sortable: true,
    },
    {
      title: "LINKED ACCOUNT",
      prop: "linked_account",
      filterable: true,
      sortable: true,
    },
    {
      title: "DATE OF REQUEST",
      prop: "date",
      filterable: true,
      sortable: true,
    },
    { title: "STATUS", prop: "status", filterable: true, sortable: true },
    { title: "", prop: "edit" },
    { title: "", prop: "delete" },
  ];

  const body = [];

  cardRequests.forEach((request) => {
    body.push({
      type: request.card_type.name,
      linked_account: request.account.account_number,
      date: (
        <span className="no-break">
          {moment(request.created_at).format("MMMM Do, YYYY")}
        </span>
      ),
      status: <span className="text-uppercase">{request.status}</span>,
      edit: request.is_editable ? (
        <button
          className="btn btn-outline-primary topup mt-n2 no-break"
          onClick={() => {
            setShowEdit(true);
            setRequest(request);
          }}
        >
          Change Delivery
        </button>
      ) : (
        ""
      ),
      delete: request.is_cancellable ? (
        <button
          className="btn btn-danger topup mt-n2"
          onClick={() => {
            setShowModal(true);
            setRequest(request);
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          Cancel Request
        </button>
      ) : (
        ""
      ),
    });
  });

  return (
    <>
      <DeleteReqModal
        showModal={showModal}
        setShowModal={setShowModal}
        request={request}
        // setRequest={setRequest}
        setFetch={setFetch}
        fetch={fetch}
      />
      <EditReqModal
        showModal={showEdit}
        setShowModal={setShowEdit}
        request={request}
        setRequest={setRequest}
        setFetch={setFetch}
        fetch={fetch}
        countries={countries}
      />
      <div>
        <h2 className="text-primary text-center m-4">
          <u>Card Requests</u>
        </h2>
        <div className="table-responsive container-fluid">
          <Datatable
            tableHeaders={header}
            tableBody={body}
            rowsPerPage={5}
            rowsPerPageOption={[5, 10, 15, 20]}
            //  onRowClick={onColClick}
            classes={{
              table: "mt-3 table",
              // thead: "topup table-header",
              filterClearButton: "clear-btn",
              //  tbodyCol: "table-data w-100 topup",
            }}
            labels={{ filterPlaceholder: "Filter..." }}
          />
        </div>
      </div>
    </>
  );
};

export default CardRequests;
