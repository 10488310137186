import React from "react";
import Datatable from "react-bs-datatable";
import "./AllTransactions.scss";
import moment from "moment";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import { withRouter } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Unathorized from "../../settings/Unathorized";
import ReactPaginate from "react-paginate";
import { getCurrencySymbol } from "../../../utils/normalize";

const AllTransactions = ({
  allTransactions,
  history,
  isLoading,
  pageCount,
  setPageNumber,
  pageLoading,
  setPageSize,
}) => {
  if (pageLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (allTransactions.length < 1) {
    return (
      <div className="w-75 m-auto mt-5">
        <Unathorized message="You have no transaction for this account." />
      </div>
    );
  }

  //header for data table
  const header = [
    {
      title: (
        <span className="table-dot bg-dark d-block rounded-circle mt-2"></span>
      ),
      prop: "dot",
      filterable: true,
    },
    { title: "TYPE", prop: "type", filterable: true },
    {
      title: "TRANSACTION REFERENCE",
      prop: "transaction_reference",
      filterable: true,
      sortable: true,
    },
    { title: "NARRATION", prop: "narration", filterable: true },
    { title: "AMOUNT", prop: "amount", filterable: true },
    { title: "DATE", prop: "date", filterable: true },
  ];

  const body = [];
  //once account is fetch set table contents
  allTransactions &&
    allTransactions.forEach((item) => {
      body.push({
        dot: (
          <span
            className={`table-dot d-block rounded-circle mt-2 ${
              item.type === "credit" ? "bg-primary" : "bg-danger"
            }`}
          ></span>
        ),
        type: (
          <span
            className={item.type === "debit" ? "text-danger" : "text-primary"}
          >
            {item.type}
          </span>
        ),
        transaction_reference: item.transaction_reference,
        narration: item.narration,
        amount: (
          <span
            className={item.type === "debit" ? "text-danger" : "text-primary"}
          >
            {getCurrencySymbol(item)} {thousandSeparator(item.amount)}
          </span>
        ),
        date: (
          <span className="no-break">
            {moment(item.created_at).format("MMMM Do, YYYY")}
          </span>
        ),
      });
    });

  const handleRowClick = (selected) => {
    // get the selected transaction
    const transaction = allTransactions.filter(
      (item) => item.transaction_reference === selected.transaction_reference
    );
    // set to params and go to view page
    history.push(`/transactions/${transaction[0].id}`);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setPageNumber(selectedPage + 1);
  };

  const onRowsPerPageChange = (e) => {
    setPageSize(e.target.value);
  };

  return (
    <div className="transactions-container">
      <div
        className={
          allTransactions.length < 1
            ? "d-none"
            : "table-responsive container-fluid"
        }
      >
        <div className="table-area">
          <div style={{ float: "right" }}>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={onRowsPerPageChange}
            >
              <option value="10">10 Rows</option>
              <option value="20">20 Rows</option>
              <option value="30">30 Rows</option>
            </select>
          </div>
          <Datatable
            tableHeaders={header}
            tableBody={body}
            // rowsPerPage={10}
            // rowsPerPageOption={[10, 20, 30]}
            onRowClick={handleRowClick}
            classes={{
              table: `mt-3 table`,
              thead: "",
              filterClearButton: "clear-btn",
              tbodyCol: "table-data",
              tbodyRow: "table-row m-auto",
              paginationCol: "d-none",
            }}
            labels={{ filterPlaceholder: "Filter..." }}
          />
          {isLoading && (
            <div className="transactions-spinner">
              <Spinner
                animation="border"
                variant="primary"
                className="d-block m-auto"
              />
            </div>
          )}
        </div>
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default withRouter(AllTransactions);
