import React, { useCallback, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputFile from "../../../components/InputFile";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert";
import getCroppedImg from "./cropImage";
import Cropper from "react-easy-crop";
import { useSelector } from "react-redux";
import Unathorized from "../Unathorized";
import { errorHandler } from "../../../utils/errorHandler";

const ProfilePicture = () => {
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [original, setOriginal] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const validate = Yup.object({
    avatar: Yup.mixed().required("Please upload your photo"),
  });

  const getUploadImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        original,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      return croppedImage;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [croppedAreaPixels, rotation]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const imageFile = await getUploadImage();
    if (!imageFile) {
      const title = "Failed";
      const html = "Something went wrong, please try again later";
      const icon = "error";
      fireAlert(title, html, icon);
      setLoading(false);
    }
    const formData = new FormData();

    formData.append("avatar", imageFile);
    formData.append("_method", "PATCH");

    axios
      .post(`${process.env.REACT_APP_API}/user/photo`, formData)
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "Your picture has been updated successfully";
        const icon = "success";
        fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  if (user.image) {
    return <Unathorized message="You already have a profile picture" />;
  }

  return (
    <div className="edit-profile">
      <h2 className="settings-small-header">Update your Profile Picture</h2>
      <div>
        <Formik
          initialValues={{
            avatar: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputFile
                label="Choose Photo"
                name="avatar"
                style={{ width: "100%" }}
                onChange={(event) => {
                  formik.setFieldValue("avatar", event.currentTarget.files[0]);
                  setOriginal(
                    URL.createObjectURL(event.currentTarget.files[0])
                  );
                }}
              />
              <div className={original ? "cropper" : "d-none"}>
                <Cropper
                  image={original}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Update Photo"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProfilePicture;
