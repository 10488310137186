import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import InputDropDown from "../../../../components/InputDropDown";
import InputField from "../../../../components/InputField";
import "./InvestmentForm1.scss";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import PageLoader from "../../../../components/PageLoader";
import { thousandSeparator } from "../../../../utils/thousandSeparator";
import Cleave from "cleave.js/react";
import {
  getInterestBasedRoleOverTypes,
  getInterestFreeRolloverTypes,
} from "../../../../utils/getInterestFreeRollOverTypes";

const InvestmentForm1 = ({
  setStep,
  rolloverTypes,
  investmentTypes,
  setFormValues,
}) => {
  const [autoRenew, setAutoRenew] = useState(false);

  // because I am using custom input for amount
  const [amount, setAmount] = useState("");
  const [noAmount, setNoAmount] = useState(false);
  const accounts = useSelector((state) => state.accounts.accounts);

  let validate;
  if (!autoRenew) {
    validate = Yup.object({
      source_account: Yup.string().required("Please select account"),
      investment_type: Yup.string().required("Please select investment type"),
      title: Yup.string().required("Title is required"),
      beneficiary_account: Yup.string().required("Please choose account"),
      //amount: Yup.string().required("Amount is required"),
      pin: Yup.string().max(4).required("Please enter pin"),
      rollover_type: Yup.string(),
    });
  } else {
    validate = Yup.object({
      source_account: Yup.string().required("Please select account"),
      investment_type: Yup.string().required("Please select investment type"),
      title: Yup.string().required("Title is required"),
      beneficiary_account: Yup.string().required("Please choose account"),
      //amount: Yup.string().required("Amount is required"),
      pin: Yup.string().max(4).required("Please enter pin"),
      rollover_type: Yup.string().required("Select type of rollover"),
    });
  }

  //this for inputDropDown component options and values
  const accountsOptions = accounts
    ? [
        ...accounts.map(
          (account) =>
            `${account.account_name} - ${
              account.account_number
            } - ₦${thousandSeparator(account.available_balance)}`
        ),
      ]
    : [];
  const accountValues = accounts
    ? [...accounts.map((account) => account.id)]
    : [];

  if (!accounts || !rolloverTypes || !investmentTypes) {
    return <PageLoader />;
  }

  return (
    <div className="bg-white rounded p-3 mt-2">
      <div className="d-flex justify-content-center flex-wrap py-3 px-1 form1">
        <div className="invest-form-msg">
          <h2>Your Information is Safe with Us!</h2>
          <p>
            We respect your privacy and protect it with
            <br /> strong encryption, plus strict polices.
          </p>
        </div>
        <div>
          <Formik
            initialValues={{
              source_account: "",
              investment_type: "",
              title: "",
              beneficiary_account: "",
              // amount: "",
              pin: "",
              rollover_type: "",
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              setNoAmount(false);
              const {
                source_account,
                investment_type,
                title,
                beneficiary_account,
                pin,
              } = values;
              if (!amount) {
                return setNoAmount(true);
              }
              if (!autoRenew) {
                setFormValues({
                  source_account,
                  investment_type,
                  title,
                  beneficiary_account,
                  pin,
                  amount,
                });
              } else {
                setFormValues({ ...values, amount });
              }
              setStep(2);
            }}
          >
            {(formik) => (
              <Form>
                <div className="d-flex justify-content-center flex-wrap invest-form">
                  <div>
                    <InputDropDown
                      label=""
                      noValue="Select Source Account"
                      options={accountsOptions}
                      values={accountValues}
                      name="source_account"
                      style={{ width: "250px", height: "43px" }}
                    />
                    <InputDropDown
                      label=""
                      noValue="Select Investment Type"
                      options={[...investmentTypes.map((type) => type.name)]}
                      values={[...investmentTypes.map((type) => type.id)]}
                      name="investment_type"
                      style={{ width: "250px", height: "43px" }}
                    />
                    <InputField
                      label=""
                      name="title"
                      type="text"
                      placeholder="Enter Title"
                      style={{ width: "250px", height: "43px" }}
                    />
                    <div className="form-check form-check-inline mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="autoRenew"
                        onChange={() => setAutoRenew(!autoRenew)}
                      />
                      <label className="form-check-label" htmlFor="autoRenew">
                        Auto renew on investment?
                      </label>
                    </div>
                  </div>
                  <div>
                    <InputDropDown
                      label=""
                      noValue="Choose Beneficiary Account"
                      options={accountsOptions}
                      values={accountValues}
                      name="beneficiary_account"
                      style={{ width: "250px", height: "43px" }}
                    />
                    <div className="d-flex">
                      <p className="investment-naira">₦</p>
                      <Cleave
                        placeholder="Enter Amount"
                        options={{
                          numeral: true,
                          numeralThousandsGroupStyle: "thousand",
                        }}
                        className="select-input"
                        id="amount-input"
                        name="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.rawValue)}
                        style={{
                          width: "228px",
                          height: "43px",
                          marginTop: "1rem",
                          paddingLeft: "0.2rem",
                        }}
                      />
                    </div>
                    <Alert
                      variant="danger"
                      show={noAmount}
                      style={{
                        fontSize: "0.7rem",
                        height: "50px",
                      }}
                      onClose={() => setNoAmount(false)}
                      dismissible
                    >
                      <p>Please enter amount</p>
                    </Alert>
                    <InputField
                      label=""
                      name="pin"
                      type="password"
                      placeholder="Enter your Pin"
                      style={{ width: "250px", height: "43px" }}
                      maxLength="4"
                    />
                    <div className={autoRenew ? "" : "d-none"}>
                      {investmentTypes
                        ?.find(
                          (investment) =>
                            investment?.id === formik?.values?.investment_type
                        )
                        ?.name?.toLowerCase() === "msa fund" ? (
                        <InputDropDown
                          label=""
                          noValue="Select Rollover type"
                          options={[
                            ...rolloverTypes
                              .filter((type) =>
                                getInterestFreeRolloverTypes(
                                  type?.name,
                                  formik?.values?.investment_type,
                                  investmentTypes
                                )
                              )
                              .map((type) => type.name),
                          ]}
                          values={[
                            ...rolloverTypes
                              .filter((type) =>
                                getInterestFreeRolloverTypes(
                                  type?.name,
                                  formik?.values?.investment_type,
                                  investmentTypes
                                )
                              )
                              .map((type) => type.id),
                          ]}
                          name="rollover_type"
                          style={{ width: "250px", height: "43px" }}
                        />
                      ) : (
                        <InputDropDown
                          label=""
                          noValue="Select Rollover type"
                          options={[...rolloverTypes.map((type) => type.name)]}
                          values={[...rolloverTypes.map((type) => type.id)]}
                          name="rollover_type"
                          style={{ width: "250px", height: "43px" }}
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary mt-3 px-4 py-2"
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default InvestmentForm1;
