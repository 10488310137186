import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import PageLoader from "../../../components/PageLoader";
import { Helmet } from "react-helmet-async";
import { Table } from "react-bootstrap";
import moment from "moment";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {onShareJPEG, onSharePDF, ShareFileType} from "../../../utils/Share";
import {NoCrypto} from "../../../components/Notice";
import Modal from "react-modal";

const TransactionDetail = () => {
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState({});
  const [error, setError] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const componentRef = useRef();

  //get transaction id from link
  const { id } = useParams();

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API}/transactions/${id}`)
      .then((res) => {
        setTransaction(res.data.data.transaction);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(true);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, [id]);

  if (loading) {
    return <PageLoader />;
  }

  const printReceipt = () => window.print();

  const shareJpeg = () => {
    onShareJPEG(componentRef);
  }

  const sharePdf = () => {
    onSharePDF(componentRef);
  }

  let senderName,
    senderAccountNumber,
    senderBank,
    receiverName,
    receiverAccountNumber,
    receiverBank;
  if (transaction.type === "credit") {
    senderName = transaction.external_account_name
      ? transaction.external_account_name
      : transaction.source
      ? transaction.source.account_name
      : "";
    senderAccountNumber = transaction.source
      ? transaction.source.account_number
      : transaction.external_account_number
      ? transaction.external_account_number
      : "";
    senderBank =
      transaction.channel === "PAYSTACK"
        ? "CARD PAYMENT"
        : transaction.debit_bank
        ? transaction.debit_bank.bank_name
        : "";
    receiverName = transaction.beneficiary
      ? transaction.beneficiary.account_name
      : transaction.external_account_name
      ? transaction.external_account_name
      : "";
    receiverAccountNumber = transaction.beneficiary
      ? transaction.beneficiary.account_number
      : transaction.external_account_number
      ? transaction.external_account_number
      : "";
  } else {
    senderName = transaction.source ? transaction.source.account_name : "";
    senderAccountNumber = transaction.source
      ? transaction.source.account_number
      : "";
    receiverName = transaction.external_account_name
      ? transaction.external_account_name
      : transaction.beneficiary
      ? transaction.beneficiary.account_name
      : "";
    receiverAccountNumber = transaction.external_account_number
      ? transaction.external_account_number
      : transaction.beneficiary
      ? transaction.beneficiary.account_number
      : "";
    receiverBank = transaction.credit_bank
      ? transaction.credit_bank.bank_name
      : "";
  }
  // if (transaction.type === "credit") {
  //   senderName = transaction.source.account_name || transaction.account_name;
  //   senderAccountNumber =
  //     transaction.source.account_number || transaction.account_number;
  //   senderBank = transaction.bank.bank_name || "";
  //   receiverName =
  //     transaction.beneficiary.account_name || transaction.account_name;
  //   receiverAccountNumber =
  //     transaction.beneficiary.account_number || transaction.account_number;
  // } else {
  //   senderName = transaction.source.account_name;
  //   senderAccountNumber = transaction.source.account_number;
  //   receiverName =
  //     transaction.account_name || transaction.beneficiary.account_name;
  //   receiverAccountNumber =
  //     transaction.account_number || transaction.beneficiary.account_number;
  //   receiverBank = transaction.bank.bank_name;
  // }

  return (
    <>
      <Helmet>
        <title>{id} Transaction Receipt - Shanono</title>
      </Helmet>

      <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            },
            content: {
              maxWidth: "fit-content",
              maxHeight: "fit-content",
              margin: "auto",
              background: "white",
              borderRadius: "8px",
              padding: "0px",
            },
          }}
      >
        <ShareFileType
            closeModal={handleCloseModal}
            sharePdf={sharePdf}
            shareJpeg={shareJpeg}
        />
      </Modal>

      <div className="user-dashboard">
        <p className={error ? "text-primary text-center pt-5" : "d-none"}>
          Something went wrong, please try again after 3 mins.
        </p>

        <div style={{paddingInline: "1.5rem", paddingTop: "1.5rem", marginLeft: "auto", display: "flex", justifyContent: "flex-end"}}>
          <div style={{display: "flex", gap: "1rem"}}>
            <button
                className="btn btn-outline-primary hide-on-print"
                onClick={() => handleOpenModal()}
            >
              Share Receipt
            </button>

            <button
                className="btn btn-outline-primary hide-on-print"
                onClick={printReceipt}
            >
              Print Receipt
            </button>
          </div>
        </div>
        <section ref={componentRef} className="p-2 p-lg-4">
          <div>
            <h4 className="text-primary">Transaction Receipt</h4>
          </div>
          <hr className="bg-primary"/>
          <div className="d-flex justify-content-between mt-5">
            <div>
              <h5>Sender Details</h5>
              <p>{senderName || ""}</p>
              <p>{senderAccountNumber || ""}</p>
              <p>{senderBank || ""}</p>
            </div>
            <div>
              <h5>Receiver Details</h5>
              <p>{receiverName || ""}</p>
              <p>{receiverAccountNumber || ""}</p>
              <p>{receiverBank || ""}</p>
            </div>
          </div>
          <hr className="bg-primary"/>
          <p>Transaction Reference: {transaction.transaction_reference}</p>
          <hr className="bg-primary"/>
          <Table striped bordered hover responsive>
            <thead>
            <tr>
              <th>Amount in Words</th>
              <th>Date</th>
              <th>Type</th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="text-capitalize">
                {transaction.amount_in_words}
              </td>
              <td>
                  <span>
                    {moment(transaction.created_at).format("MMMM Do, YYYY")}
                    <br/>
                    {moment(transaction.created_at).format("hh:mm a")}
                  </span>
              </td>
              <td
                  className={
                    transaction.type === "debit"
                        ? "text-danger"
                        : "text-primary"
                  }
              >
                {transaction.type}
              </td>
              <td
                  className={
                    transaction.type === "debit"
                        ? "text-danger"
                        : "text-primary"
                  }
              >
                &#8358; {thousandSeparator(transaction.amount)}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <span className="text-primary">Narration:</span>{" "}
                {transaction.narration}
              </td>
            </tr>
            </tbody>
          </Table>
          <hr className="bg-primary"/>
          <div className={transaction.type === "debit" ? "mt-5" : "d-none"}>
            <h6 className="text-center text-primary">DISCLAIMER</h6>
            <hr className="bg-primary"/>
            <p>
              Your transfer has been successful and the beneficiary's account
              will be credited. However, this does not serve as confirmation of
              credit into the beneficiary's account. Due to the nature of the
              Internet, transactions may be subject to interruption,
              transmission blackout, delayed transmission and incorrect data
              transmission. The Bank is not liable for malfunctions in
              communications facilities not within its control that may affect
              the accuracy or timeliness of messages and transactions you
              send.All transactions are subject to verification and our normal
              fraud checks
            </p>
          </div>
          <div className="text-center mt-5 mb-n4">
            <p>
              <small>
                Copyright © {new Date().getFullYear()} Shanono. All rights
                reserved.
              </small>
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default TransactionDetail;
