import React from "react";
import { Helmet } from "react-helmet-async";
import DayAndDate from "../../components/DayAndDate";
import "./Airtime.scss";
import { Alert, Form } from "react-bootstrap";
import LoadingOverLay from "../../components/LoadingOverLay";
import axios from "axios";
import { useSelector } from "react-redux";
import { thousandSeparator } from "../../utils/thousandSeparator";
import { validateAirtimepurchase } from "./paymentValidation";
import Cleave from "cleave.js/react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { fireAlert } from "../../utils/Alert";
import { errorHandler } from "../../utils/errorHandler";
import Switch from "../../components/Switch";
import { BsChevronLeft } from "react-icons/bs";

const billersInfo = [
  {
    category_id: 3,
    biller_id: 908,
    biller_name: "9mobile Recharge (E-Top Up)",
    paydirect_product_id: 0,
    paydirect_institution_id: 0,
    narration: "Direct Top-Up",
    logo_url: "9mobile_new.jgp",
  },
  {
    category_id: 3,
    biller_id: 617,
    biller_name: "Airtel Top-up (Postpaid)",
    paydirect_product_id: 0,
    paydirect_institution_id: 0,
    narration: "Postpaid Top-up",
    logo_url: "postpaid",
  },

  {
    category_id: 3,
    biller_id: 913,
    biller_name: "GLO QuickCharge (Top-up)",
    paydirect_product_id: 0,
    paydirect_institution_id: 0,
    narration: "Direct Top-Up",
    logo_url: "glo.gif",
  },
  {
    category_id: 3,
    biller_id: 109,
    biller_name: "MTN Direct Top-up (Prepaid)",
    paydirect_product_id: 0,
    paydirect_institution_id: 0,
    narration: "Direct Top-up",
    logo_url: "MTN.jpg",
  },
];
function Airtime() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [network, setNetwork] = React.useState("");
  const [biller, setBiller] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [checked, setChecked] = React.useState(false);

  const { telephone } = useSelector((state) => state.user);

  const initialState = {
    sourceAccount: "",
    network: "",
    phoneNumber: "",
    amount: "",
    pin: "",
    biller: "",
  };

  const [formData, setFormData] = React.useState(initialState);

  const accounts = useSelector((state) => state.accounts.accounts);

  const handleChange = (prop) => (e) => {
    setFormData({
      ...formData,
      [prop]: e.target.value,
    });
  };

  const useDefaultPhoneNumber = () => {
    if (!checked) {
      setChecked(true);
      setFormData({ ...formData, phoneNumber: telephone });
    } else {
      setChecked(false);
      setFormData({
        ...formData,
        phoneNumber: "",
      });
    }
  };
  const handlePayment = () => {
    if (validateAirtimepurchase(formData).length > 0) {
      setErrors(validateAirtimepurchase(formData));
      return setShowAlert(true);
    }
    let number;
    if (formData.phoneNumber.length > 11) {
      number = formData.phoneNumber;
    } else {
      number = formData.phoneNumber.substr(1);
      number = `+234${number}`;
    }

    setLoading(true);
    const payload = {
      source_account_number: formData.sourceAccount,
      amount: formData.amount,
      customer_mobile: number,
      payment_code: biller.payment_code,
      pin: formData.pin,
      category_id: biller.category_id,
      biller_name: biller.payment_item_name,
      payment_item_name: biller.payment_item_name,
    };

    let title, html, icon;
    axios
      .post(`${process.env.REACT_APP_API}/bills/payment`, payload)
      .then((res) => {
        setFormData({ ...initialState });
        if (res.data.data.bill.status == "success") {
          setShowAlert(false);
          setChecked(false);
          title = "Airtime Purchase Successful";
          html = `Your airtime purchase of ₦ ${thousandSeparator(
            formData.amount
          )} to ${formData.phoneNumber} was successful`;
          icon = "success";
          fireAlert(title, html, icon);
        } else if (res.data.data.bill.status == "pending") {
          setShowAlert(false);
          title = "Airtime Purchase is Pending";
          html = `Your airtime purchase of ₦ ${thousandSeparator(
            formData.amount
          )} to ${formData.phoneNumber} is pending`;
          icon = "info";
          fireAlert(title, html, icon);
        } else {
          title = "Airtime Purchase UnSuccessful";
          html = `Your airtime purchase of ₦ ${thousandSeparator(
            formData.amount
          )} to ${formData.phoneNumber} was unsuccessful`;
          icon = "info";
          fireAlert(title, html, icon);
        }
      })
      .catch((err) => {
        title = "Airtime Purchase Failed";
        html = "An Error Occured";
        icon = "error";
        fireAlert(title, html, icon);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getBillerItem = () => {
    setNetwork("Loading...");
    axios
      .get(
        `${process.env.REACT_APP_API}/bills/payment-item/${formData.network}`
      )
      .then((res) => {
        setNetwork("");
        if (res.data?.status == "success") {
          setBiller(res.data?.data?.payment_item[0]);
        } else {
          setErrors([...errors, "Unable to fetch mobile network"]);
          return setShowAlert(true);
        }
      })
      .catch((err) => {
        setNetwork("");
        setErrors(["An error occured"]);
        return setShowAlert(true);
      });
  };

  React.useEffect(() => {
    if (formData.network) {
      getBillerItem();
    }
  }, [formData.network]);
  return (
    <div className="vh-100">
      <Helmet>
        <title>Bills payment - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Bills payment</h1>
          <DayAndDate />
          <button
            type="button"
            className="btn btn-link text-dark"
            onClick={() => history.goBack()}
          >
            <BsChevronLeft />
            Back
          </button>
          <div className="airtime-container px-4">
            <LoadingOverLay loading={loading} />
            <h3 className="text-primary sendmoney-header py-2">Buy Airtime</h3>

            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
              show={showAlert}
            >
              {errors.map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </Alert>
            <div className="">
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                The account to be debited
                <Form.Control
                  as="select"
                  name="source_account"
                  value={formData.sourceAccount}
                  onChange={handleChange("sourceAccount")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option
                        key={index}
                        value={account.account_number}
                        style={{}}
                      >
                        {account.account_name} - {account.type.name} - &#8358;
                        {thousandSeparator(account.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Network
                <Form.Control
                  as="select"
                  name="network"
                  value={formData.network}
                  onChange={handleChange("network")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">
                    {network ? network : "-- Choose Network --"}
                  </option>
                  {billersInfo &&
                    billersInfo.map((biller, index) => (
                      <option key={index} value={biller?.biller_id} style={{}}>
                        {biller?.biller_name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Phone number
                <Form.Control
                  as="input"
                  name=""
                  value={formData.phoneNumber}
                  onChange={handleChange("phoneNumber")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
                {telephone && (
                  <Switch
                    label="Use default phone number"
                    checked={checked}
                    onChange={useDefaultPhoneNumber}
                  />
                )}
              </Form.Group>
              <div>
                <p
                  className="sendmoney-select_acc-label"
                  style={{ marginBottom: -3 }}
                >
                  Amount
                </p>

                <Cleave
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    prefix: "₦ ",
                    rawValueTrimPrefix: true,
                  }}
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                  className="select-input"
                  id="amount-input"
                  name="amount"
                  value={formData.amount}
                  onChange={(e) =>
                    setFormData({ ...formData, amount: e.target.rawValue })
                  }
                />
                {formData.billerItem && (
                  <p className="text-success mt-n3">
                    Service charge: ₦{JSON.parse(formData.billerItem).item_fee}
                  </p>
                )}
              </div>

              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Pin
                <Form.Control
                  as="input"
                  name="pin"
                  type={"password"}
                  maxLength={4}
                  value={formData.pin}
                  onChange={handleChange("pin")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
              onClick={handlePayment}
              disabled={loading ? true : false}
            >
              Proceed
            </button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Airtime;
