import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { fireAlert } from "../../../utils/Alert";
import { errorHandler } from "../../../utils/errorHandler";

const TransactionStatement = ({ accountId }) => {
  const [dates, setDates] = useState({ start_date: null, end_date: null });
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!dates.start_date || !dates.end_date) {
      setLoading(false);
      const title = "Error";
      const html = "Start and end dates are required";
      const icon = "error";
      return fireAlert(title, html, icon);
    }

    //Get right date format
    let values = {};
    values.start_date = moment(dates.start_date).format("YYYY-MM-DD");
    values.end_date = moment(dates.end_date).format("YYYY-MM-DD");

    axios
      .post(
        `${process.env.REACT_APP_API}/accounts/${accountId}/statement`,
        values
      )
      .then((res) => {
        setLoading(false);
        setDates({ start_date: null, end_date: null });
        const title = "Successful";
        const html = res.data.data.message;
        const icon = "success";
        return fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  return (
    <div className="mb-5">
      <div>
        <p className="text-center text-primary">
          Request a stamped account statement
        </p>
      </div>
      <div className="d-flex justify-content-center flex-wrap">
        <div className="align-self-center mr-md-2">
          <p style={{ fontSize: "16px" }}>START DATE</p>
          <DatePicker
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd/MM/yyyy"
            selected={dates.start_date}
            maxDate={new Date()}
            placeholderText="DD/MM/YYYY"
            onChange={(date) => setDates({ ...dates, start_date: date })}
            className="form-control reg-input mt-n2"
          />
        </div>
        <div className="align-self-center">
          <p style={{ fontSize: "16px" }}>END DATE</p>
          <DatePicker
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
            minDate={dates.start_date}
            dateFormat="dd/MM/yyyy"
            selected={dates.end_date}
            placeholderText="DD/MM/YYYY"
            onChange={(date) => setDates({ ...dates, end_date: date })}
            className="form-control reg-input mt-n2"
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <button
          className="btn btn-primary"
          disabled={loading}
          onClick={handleClick}
        >
          {loading ? "Please wait..." : "Request Statement"}
        </button>
      </div>
    </div>
  );
};

export default TransactionStatement;
