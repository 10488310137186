import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Form, Modal, Spinner } from "react-bootstrap";
import InputField from "../../../components/InputField.jsx";
import InputDropDown from "../../../components/InputDropDown";
import LoadingImage from "../../../assets/card-loading.svg";
import { validatePosRequest } from "./validatePosRequest";
import AddPosLeft from "./AddPosLeft.jsx";
import axios from "axios";
import LoadingSpinner from "../../../components/LoadingSpinner.jsx";
import { thousandSeparator } from "../../../utils/thousandSeparator.js";
import { fireAlert } from "../../../utils/Alert";
// import { deliveryFee } from "../../../utils/deliveryFee.js";
import { errorHandler } from "../../../utils/errorHandler.js";
import { getCurrencySymbol } from "../../../utils/normalize.js";

const cardFormStyles = {
  // background: "#e2f3f3",
  // border: "1px solid #79b0b0",
  width: "100%",
};

const AddPos = ({ history }) => {
  const [showPosInTransitModal, setShowPosInTransitModal] = useState(false);
  const [posData, setPosData] = useState(null);
  console.log("🚀 ~ file: AddPos.jsx:26 ~ AddPos ~ posData:", posData);
  const [states, setStates] = useState(null);
  const [loadingStates, setLoadingStates] = useState(false);
  const [stateError, setStateError] = useState("");
  const [loadingPosData, setLoadingPosData] = useState(true);
  const [loading, setLoading] = useState(false);

  const getStates = (countryId) => {
    setLoadingStates(true);
    setStates(null);
    axios
      .get(`${process.env.REACT_APP_API}/countries/${countryId}/states`)
      .then((res) => {
        setLoadingStates(false);
        setStates(res.data.data.states);
      })
      .catch((err) => {
        setLoadingStates(false);
        console.log(err);
      });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API}/pos-requests/create`)
      .then((res) => {
        setLoadingPosData(false);
        setPosData(res.data.data);
      })
      .catch((err) => {
        setLoadingPosData(false);
        console.log(err);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, []);
  const handleClosePosInTransitModal = () => {
    setShowPosInTransitModal(false);
    history.push("/pos");
  };

  // Show Card in Transit Modal and go to pos page with pos already added
  const handleSubmit = (values) => {
    setLoading(true);
    //Ensure a user pick a state if required
    if (
      !states &&
      values.existing_address !== "" &&
      values.existing_address !== "true"
    ) {
      setLoading(false);
      return setStateError("Please choose a state");
    }

    if (
      states &&
      states.length > 0 &&
      values.state === "" &&
      values.existing_address !== "true"
    ) {
      setLoading(false);
      return setStateError("Please choose a state");
    }

    const {
      account_id,
      pos_type,
      delivery_option,
      existing_address,
      pin,
      stand,
      paper_display,
      pos_payment_method,
      installment_amount,
      down_payment_amount,
      repayment_duration,
    } = values;

    let newRequest = {};

    if (delivery_option === "pickup") {
      newRequest = {
        account_id,
        pos_type,
        delivery_option,
        pos_payment_method,
        pin,
      };
    } else if (existing_address === "true") {
      newRequest = {
        account_id,
        pos_type,
        delivery_option,
        existing_address: true,
        pos_payment_method,
        pin,
      };
    } else {
      newRequest = { ...values, existing_address: false };
    }

    if (stand === "true") {
      newRequest.stand = true;
    } else {
      newRequest.stand = false;
    }

    if (paper_display === "true") {
      newRequest.paper_display = true;
    } else {
      newRequest.paper_display = false;
    }

    if (pos_payment_method === "installment") {
      newRequest.installment_amount = installment_amount;
      newRequest.down_payment_amount = down_payment_amount;
      newRequest.repayment_duration = repayment_duration;
    }

    // add referrer code if refered
    if (values?.referrer_code)
      Object.assign(newRequest, { referrer_code: values?.referrer_code });

    if (values?.pos_payment_method === "c-caution-deposit")
      Object.assign(newRequest, { caution_fee: values?.caution_fee });

    axios
      .post(`${process.env.REACT_APP_API}/pos-requests`, newRequest)
      .then((res) => {
        setLoading(false);
        setShowPosInTransitModal(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
    // handleCardFormSubmit();
  };

  if (loadingPosData) {
    return <LoadingSpinner />;
  }

  //if after loading and there's no data
  if (!posData) {
    return (
      <p className="text-primary text-center mt-5">
        Something went wrong, please try again
      </p>
    );
  }

  return (
    <>
      <div className="new-card-wrapper">
        <AddPosLeft />
        <div className="new-card-right">
          <Formik
            enableReinitialize
            initialValues={{
              account_id: "",
              pos_type: "",
              delivery_option: "",
              existing_address: "",
              address_line_one: "",
              address_line_two: "",
              city: "",
              state: "",
              country: "",
              paper_display: "",
              stand: "",
              pin: "",
              referrer_code: "",
              repayment_duration: "",
              pos_payment_method: "",
              installment_amount: "",
              down_payment_amount: "",
              caution_fee: "",
            }}
            validationSchema={validatePosRequest}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <FormikForm>
                <div>
                  <div className="p-3 p-lg-4 bg-white sendmoney-whitebg">
                    <InputDropDown
                      label="POS type"
                      name="pos_type"
                      noValue="-- Choose POS Type --"
                      options={[
                        ...posData.pos_types.map(
                          (pos) =>
                            pos.name +
                            " - " +
                            getCurrencySymbol({ currency: "ngn" }) +
                            thousandSeparator(pos.price)
                        ),
                      ]}
                      values={[...posData.pos_types.map((pos) => pos.id)]}
                      style={cardFormStyles}
                    />

                    <InputDropDown
                      label="Which account would this be linked to?"
                      noValue="-- Choose Account --"
                      name="account_id"
                      options={[
                        ...posData.accounts.map(
                          (account) =>
                            account.account_name +
                            " - " +
                            account.account_number +
                            " - " +
                            getCurrencySymbol(account) +
                            thousandSeparator(account.available_balance)
                        ),
                      ]}
                      values={[
                        ...posData.accounts.map((account) => account.id),
                      ]}
                      style={cardFormStyles}
                    />

                    <InputDropDown
                      label="Payment Method"
                      noValue="-- Choose Payment Method --"
                      name="pos_payment_method"
                      options={["Installment", "One-off", "C_Caution_Deposit"]}
                      values={["installment", "one-off", "c-caution-deposit"]}
                      style={cardFormStyles}
                    />
                    {formik.values.pos_payment_method === "installment" && (
                      <>
                        <div>
                          <InputDropDown
                            label="Installment Amount"
                            noValue="-- Select Option --"
                            name="installment_amount"
                            options={Object.keys(
                              posData.installment_amount_options
                            ).map((amount) => `₦${thousandSeparator(amount)}`)}
                            values={Object.keys(
                              posData.installment_amount_options
                            )}
                            style={cardFormStyles}
                          />
                        </div>
                        <div>
                          <InputDropDown
                            label="Repayment duration"
                            noValue="-- Select Option --"
                            name="repayment_duration"
                            options={Object.keys(
                              posData?.repayment_duration
                            ).map(
                              (duration) =>
                                posData?.repayment_duration[duration]
                            )}
                            values={Object.keys(posData.repayment_duration)}
                            style={cardFormStyles}
                          />
                        </div>
                        <div>
                          <InputDropDown
                            label="Down Payment Amount"
                            noValue="-- Select Option --"
                            name="down_payment_amount"
                            options={Object.keys(
                              posData.down_payment_amount
                            ).map(
                              (amount) =>
                                `${getCurrencySymbol({
                                  currency: "ngn",
                                })} ${thousandSeparator(amount)}`
                            )}
                            values={Object.keys(posData.down_payment_amount)}
                            style={cardFormStyles}
                          />
                        </div>
                      </>
                    )}
                    {formik.values.pos_payment_method ===
                      "c-caution-deposit" && (
                      <div>
                        <InputDropDown
                          label="Caution Deposit"
                          noValue="-- Select Option --"
                          name="caution_fee"
                          options={Object.keys(posData.caution_fee).map(
                            (amount) => posData.caution_fee[amount]
                          )}
                          values={Object.keys(posData.caution_fee)}
                          style={cardFormStyles}
                        />
                      </div>
                    )}
                    <InputDropDown
                      label="Delivery Method"
                      noValue="-- Choose Delivery Method --"
                      name="delivery_option"
                      options={[
                        // "Pick-up at our office address",
                        "Delivery to your address",
                      ]}
                      values={[/*"pickup",*/ "dispatch"]}
                      style={cardFormStyles}
                    />

                    {/* Only show when pickup option is chosen */}

                    {formik.values.delivery_option === "pickup" && (
                      <>
                        <div>
                          <Form.Group controlId="pickup-address">
                            <Form.Label className="sendmoney-select_acc-label">
                              Pick Up Address
                            </Form.Label>
                            <Form.Control
                              disabled
                              as="textarea"
                              rows={3}
                              value="No.3 John Great Court, Chevron Drive, Lekki Expressway, Lekki, Lagos"
                              className="sendmoney-textarea"
                              style={cardFormStyles}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}

                    {/* Make API call and post request if pick up option is chosen */}

                    {/* if Door Delivery(Dispatch) is chosen, show "use existing address option" */}

                    {formik.values.delivery_option === "dispatch" && (
                      <InputDropDown
                        label="Use Existing address"
                        noValue="-- Select Option --"
                        name="existing_address"
                        options={["Yes", "No"]}
                        values={[true, false]}
                        style={cardFormStyles}
                      />
                    )}

                    {/* if useExistingAddress is true, make API call and post request" */}

                    {/* if useExistingAddress is false, show Address Line Fields, Country, State and City options" */}

                    {formik.values.existing_address === "false" &&
                      formik.values.delivery_option === "dispatch" && (
                        <>
                          <InputField
                            label="Address Line 1"
                            name="address_line_one"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputField
                            label="Address Line 2"
                            name="address_line_two"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputDropDown
                            label="Country"
                            noValue="-- Select Country --"
                            name="country"
                            options={["Nigeria"]}
                            values={["163"]}
                            style={cardFormStyles}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "country",
                                event.currentTarget.value
                              );
                              formik.setFieldValue("state", "");
                              getStates(event.currentTarget.value);
                            }}
                          />
                          <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label className="sendmoney-select_acc-label">
                              State
                            </Form.Label>
                            <p className={loadingStates ? "" : "d-none"}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </p>
                            <Form.Control
                              as="select"
                              name="state"
                              className={
                                loadingStates ? "d-none" : "select-input"
                              }
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "state",
                                  event.currentTarget.value
                                );
                              }}
                            >
                              <option value="">-- Select State --</option>
                              {states &&
                                states.map((state, index) => (
                                  <option key={index} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                          <InputField
                            label="City"
                            name="city"
                            type="text"
                            style={cardFormStyles}
                          />
                        </>
                      )}
                    <InputField
                      label="Transaction pin"
                      name="pin"
                      type="password"
                      style={cardFormStyles}
                      maxLength="4"
                    />
                    <InputField
                      label="Referrer Code (Optional)"
                      name="referrer_code"
                      type="text"
                      style={cardFormStyles}
                    />
                    <div
                      className={
                        formik.values.existing_address === "false"
                          ? ""
                          : "d-none"
                      }
                    >
                      {/* <p className="d-flex justify-content-between text-primary">
                        <span>Delivery Fee:</span>
                        <span>
                          {deliveryFee(formik.values, countries, states)
                            ? "₦" +
                              thousandSeparator(
                                deliveryFee(formik.values, countries, states)
                              )
                            : "Applicable"}
                        </span>
                      </p> */}
                    </div>
                    <div
                      className={stateError ? "alert alert-danger" : "d-none"}
                      role="alert"
                    >
                      {stateError}
                    </div>
                    <h5 className="text-primary">Extras</h5>
                    <hr className="m-n1" />
                    <InputDropDown
                      label="Stand - ₦5000"
                      noValue="-- Select --"
                      name="stand"
                      options={["Yes", "No"]}
                      values={[true, false]}
                      style={cardFormStyles}
                    />
                    <InputDropDown
                      label="Paper Display - ₦500"
                      noValue="-- Select --"
                      name="paper_display"
                      options={["Yes", "No"]}
                      values={[true, false]}
                      style={cardFormStyles}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
                    disabled={loading}
                  >
                    {loading ? "Processing..." : " Request POS"}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>

      {/* Card in Transit Modal */}

      <Modal
        show={showPosInTransitModal}
        onHide={handleClosePosInTransitModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="cards-modal-content card-in-transit"
      >
        <p
          className="ml-auto text-danger btn"
          style={{ fontSize: "24px" }}
          onClick={handleClosePosInTransitModal}
        >
          <strong>&#128473;</strong>
        </p>
        <div className="d-flex align-items-center justify-content-center text-center flex-column">
          <div className="mb-2">
            <img src={LoadingImage} alt="Card Loading" />
          </div>
          <h5 className="card-in-transit-header">Thank you</h5>
          <p className="card-in-transit-text notice">
            Your POS request will be processed
          </p>
          <p className="card-in-transit-text mail">
            A mail will be sent to you. Thank you
          </p>
        </div>
      </Modal>
    </>
  );
};

export default AddPos;
