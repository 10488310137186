import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import * as FaIcons from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader";
import { getAccounts } from "../../../redux/ducks/accounts";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import UpdateCACModal from "./UpdateCACModal";
import { getProviderName } from "../../../utils/normalize";

const AllAccounts = () => {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.accounts.accounts);

  const [copied, setCopied] = useState(false);
  // control the card that fires copied toast
  const [copiedAcc, setCopiedAcc] = useState(null);

  // update cac for business accounts
  const [showModal, setShowModal] = useState(false);
  const [account, setAccount] = useState(null);

  /**
   * Get currency from an account
   * @param {*} account
   * @returns
   */
  const getCurrency = (account) => {
    switch (account?.currency?.toLowerCase()) {
      case "usd":
        return "USD";

      case "eur":
        return "EUR";

      default:
        return "NGN";
    }
  };

  const updateCAC = (account) => {
    setShowModal(true);
    setAccount(account);
  };

  useEffect(() => {
    dispatch(getAccounts());
  }, []);

  if (!accounts) {
    return <PageLoader />;
  }

  return (
    <>
      <UpdateCACModal
        showModal={showModal}
        setShowModal={setShowModal}
        account={account}
        setAccount={setAccount}
      />
      <div className="account-cards-container mt-5">
        <div className="row">
          {accounts.map((account, index) => (
            <div
              key={index}
              className="col-sm-9 mx-auto mx-md-0 col-md-6 col-xl-4"
            >
              <div className="account-card">
                <div className="account-card-top">
                  <p>{account.type.name}</p>
                  <p className="account-card-top_bankname">
                    {getProviderName(account)}
                  </p>
                </div>
                <div className="account-card-body">
                  <div className="account-card-field">
                    <p className="account-card-field-name">Account Name</p>
                    <p className="account-card-field-value">
                      {account.account_name}
                    </p>
                  </div>
                  <div className="account-card-field">
                    <p className="account-card-field-name">Account Number</p>
                    <p className="account-card-field-value">
                      {account.account_number}
                    </p>
                  </div>
                  <div className="account-card-field">
                    <p className="account-card-field-name">Available Balance</p>
                    <p className="account-card-field-value">
                      {getCurrency(account)}&ensp;
                      {thousandSeparator(account.available_balance)}
                    </p>
                  </div>
                  <div className="account-card-field">
                    <p className="account-card-field-name">Currency</p>
                    <p className={"account-card-field-value"}>
                      {getCurrency(account)}
                    </p>
                  </div>
                  <div className="account-card-field">
                    <p className="account-card-field-name">Status</p>
                    <p
                      className={
                        account.status === "active"
                          ? "account-card-field-value"
                          : "account-card-field-value text-danger"
                      }
                    >
                      {account.status}
                    </p>
                  </div>
                </div>
                <div className="account-card-footer d-flex align-items-center justify-content-center">
                  {copiedAcc === account.id && (
                    <Toast
                      onClose={() => setCopied(false)}
                      show={copied}
                      className="copy-toast"
                      delay={1000}
                      autohide
                    >
                      <Toast.Body className="copy-toast-text">
                        Copied
                      </Toast.Body>
                    </Toast>
                  )}
                  <CopyToClipboard
                    btn="true"
                    onCopy={() => {
                      setCopied(true);
                      setCopiedAcc(account.id);
                    }}
                    text={
                      account &&
                      account.account_name +
                        " " +
                        getProviderName(account) +
                        " " +
                        account.account_number
                    }
                  >
                    <button
                      btn
                      className="btn btn-primary btn-copy account-card-btn-copy mr-1"
                    >
                      <FaIcons.FaCopy />{" "}
                      <span className="pl-2">Copy Account Details</span>
                    </button>
                  </CopyToClipboard>
                  {(account.type.slug === "business" ||
                    account.type.name === "Business") && (
                    <button
                      btn
                      className="btn btn-primary btn-copy account-card-btn-copy"
                      onClick={() => updateCAC(account)}
                    >
                      Update CAC
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AllAccounts;
