import * as Yup from "yup";

export const validateUsername = () =>
  Yup.string("Email or phone number is required")
    // .email("Enter a valid email")
    .trim()
    .required("Email or phone number is required")
    .test(
      "test-name",
      "Valid email or phone number is required",
      function (value) {
        const emailRegex =
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        //const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
        const phoneRegex =
          /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
        let isValidEmail = emailRegex.test(value);
        let isValidPhone = phoneRegex.test(value);
        if (!isValidEmail && !isValidPhone) {
          return false;
        }
        return true;
      }
    );
