import React from "react";
import * as FaIcons from "react-icons/fa";
import "./InvestmentCards.scss";

const InterestInvestmentAction = ({ history }) => (
  <div className="investment-card-features-container">
    <p className="investment-card-feature mt-n2">
      <span className="investment-card-check-icon">
        <FaIcons.FaCheck />
      </span>
      Automatically Renewable
    </p>
    <p className="investment-card-feature">
      <span className="investment-card-check-icon">
        <FaIcons.FaCheck />
      </span>
      Automatic Renewal Options
    </p>
    <p className="investment-card-feature">
      <span className="investment-card-check-icon">
        <FaIcons.FaCheck />
      </span>
      Cancel Renewal Anytime
    </p>
    <p className="investment-card-feature">
      <span className="investment-card-check-icon">
        <FaIcons.FaCheck />
      </span>
      Fast Automated Payout
    </p>
    <button
      className="btn btn-primary investment-card-button"
      onClick={() => history.push("/investment/create")}
    >
      Make Investment
    </button>
  </div>
);

const InterestFreeInvestmentAction = ({ history }) => (
  <div className="investment-card-features-container">
    <p className="investment-card-feature mt-n2">
      <span className="investment-card-check-icon">
        <FaIcons.FaCheck />
      </span>
      Non interest bearing
    </p>
    {/* <p className="investment-card-feature">
      	<span className="investment-card-check-icon">
        	<FaIcons.FaCheck />
      	</span>
      	used for POS transactions residency card production for state and Direct
      	Sales Agency network job employment
    	</p> */}
    <p className="investment-card-feature">
      <span className="investment-card-check-icon">
        <FaIcons.FaCheck />
      </span>
      Cancel Renewal Anytime
    </p>
    <p className="investment-card-feature">
      <span className="investment-card-check-icon">
        <FaIcons.FaCheck />
      </span>
      Fast Automated Payout
    </p>
    <button
      className="btn btn-primary investment-card-button"
      onClick={() => history.push("/investment/create")}
    >
      Make Investment
    </button>
  </div>
);

const InvestmentCards = ({ history, investmentTypes }) => {
  const getInterestFree = (name) => name?.toLowerCase() === "msa fund";
  return (
    <div className="investment-card-container">
      {investmentTypes.map((data) => (
        <div className="investment-card" key={data.id}>
          <p className="text-capitalize investment-card-title">{data.name}</p>

          {getInterestFree(data?.name) ? null : (
            <p className="mt-n2 investment-card-interest">
              {data.interest_rate}
              <span className="percent">% P/A</span>
            </p>
          )}

          <p
            className="investment-card-days"
            style={{
              marginBottom: getInterestFree(data.name) ? "100px" : "0px",
            }}
          >
            {data.duration}{" "}
            <span className="text-capitalize">
              {data.duration == 1 ? "Month" : "Months"}
            </span>
          </p>
          <>
            {getInterestFree(data.name) ? (
              <InterestFreeInvestmentAction history={history} />
            ) : (
              <InterestInvestmentAction history={history} />
            )}
          </>
        </div>
      ))}
    </div>
  );
};

export default InvestmentCards;
