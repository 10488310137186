export const GET_SCHEDULES = "get schedules";
const SET_SCHEDULES = "set schedules";
const REMOVE_SCHEDULES = "remove schedules";

export const setSchedules = (schedules) => ({
  type: SET_SCHEDULES,
  schedules,
});

export const getSchedules = () => ({ type: GET_SCHEDULES });

export const removeSchedules = () => ({ type: REMOVE_SCHEDULES });

const initailState = {
  schedules: undefined,
};

const schedulesReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_SCHEDULES:
      return { ...state, schedules: { ...action.schedules } };
    case REMOVE_SCHEDULES:
      return { ...state, schedules: { ...action.schedules } };
    default:
      return state;
  }
};

export default schedulesReducer;
