export const GET_BENEFICIARIES = "get beneficiaries";
const SET_BENEFICIARIES = "set beneficiaries";
const REMOVE_BENEFICIARIES = "remove beneficiaries";

export const getBeneficiaries = () => ({ type: GET_BENEFICIARIES });

export const setBeneficiaries = (beneficiaries) => ({
  type: SET_BENEFICIARIES,
  beneficiaries,
});

export const removeBeneficiaries = () => ({ type: REMOVE_BENEFICIARIES });

const initailState = {
  beneficiaries: undefined,
};

const beneficiariesReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_BENEFICIARIES:
      return { ...state, beneficiaries:[...action.beneficiaries] };
    case REMOVE_BENEFICIARIES:
      return { ...state, beneficiaries: undefined };
    default:
      return state;
  }
};

export default beneficiariesReducer;
