import { call, put } from "redux-saga/effects";
import { setInvestmentTypess, setRolloverTypes } from "../../ducks/investments";
import {
  requestGetInvestmentTypes,
  requestGetRolloverTypes,
} from "../request/investments";

export function* handleGetInvestmentTypes(action) {
  try {
    // make the get request
    const response = yield call(requestGetInvestmentTypes);
    const { data } = response;
    // dispatch to redux
    yield put(setInvestmentTypess(data.data.investmentTypes));
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetRolloverTypes(action) {
  try {
    // make the get request
    const response = yield call(requestGetRolloverTypes);
    const { data } = response;
    // dispatch to redux
    yield put(setRolloverTypes(data.data.rolloverTypes));
  } catch (error) {
    console.log(error);
  }
}
