import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccountName from "./AccountName";
import ChooseBank from "./ChooseBank";

const AccDetail = ({
  transaction,
  newAccount,
  handleChange,
  setTransaction,
  beneficiaries,
}) => {
  const chooseBeneficiary = (e) => {
    const id = e.target.value;
    const beneficiary = beneficiaries.filter((person) => id === person.id);
    if (beneficiary[0].bank_code === "000000") {
      setTransaction({
        ...transaction,
        beneficiary_bank_name: beneficiary[0].bank_name,
        beneficiary_bank_code: beneficiary[0].bank_code,
        beneficiary_account_number: beneficiary[0].account_number,
        beneficiary_account_name: beneficiary[0].account_name,
        channel: "internal",
      });
    } else {
      setTransaction({
        ...transaction,
        beneficiary_bank_name: beneficiary[0].bank_name,
        beneficiary_bank_code: beneficiary[0].bank_code,
        beneficiary_account_number: beneficiary[0].account_number,
        beneficiary_account_name: beneficiary[0].account_name,
        channel: "external",
      });
    }
  };

  // this is triggered when loading account_name
  const [loading, setLoading] = useState(false);

  return (
    <>
      <section className={newAccount ? "" : "d-none"}>
        <div>
          <Form.Group controlId="exampleForm.ControlSelect2">
            <Form.Label className="sendmoney-select_acc-label">
              Transfer Type
            </Form.Label>
            <Form.Control
              as="select"
              name="channel"
              onChange={handleChange}
              className="select-input"
              value={transaction.channel}
            >
              <option value="">-- Please select an option --</option>
              <option value="internal">Shanono to Shanono</option>
              <option value="external">Shanono to other banks</option>
            </Form.Control>
          </Form.Group>
        </div>
        <ChooseBank
          transaction={transaction}
          setTransaction={setTransaction}
          loading={loading}
        />
        <div>
          <Form.Group controlId="exampleForm.ControlSelect2">
            <Form.Label className="sendmoney-select_acc-label">
              Account Number
            </Form.Label>
            <Form.Control
              as="input"
              type="tel"
              name="beneficiary_account_number"
              onChange={handleChange}
              className="select-input"
              value={transaction.beneficiary_account_number}
              pattern="\d{3,4}"
              required
              disabled={loading}
            />
          </Form.Group>
          {/* <Form.Group controlId="formBasicText">
            <Form.Label className="sendmoney-select_acc-label">
              Account Number
            </Form.Label>
            <Form.Control
              type="number"
              className="select-input"
              name="beneficiary_account_number"
              value={transaction.beneficiary_account_number}
              onChange={handleChange}
              disabled={loading}
              maxLength="10"
            />
          </Form.Group> */}
        </div>
        <AccountName
          transaction={transaction}
          setTransaction={setTransaction}
          loading={loading}
          setLoading={setLoading}
          newAccount={newAccount}
        />
      </section>
      <section className={newAccount ? "d-none" : ""}>
        {beneficiaries && beneficiaries.length > 0 ? (
          <div>
            <Form.Group controlId="exampleForm.ControlSelect2">
              <Form.Label className="sendmoney-select_acc-label w-100">
                <span className="d-flex justify-content-between flex-wrap">
                  <span>Beneficiary</span>
                  <span className="text-primary" style={{ cursor: "pointer" }}>
                    <small>
                      <Link to="/send-money/beneficiaries">
                        Manage Saved Beneficiaries
                      </Link>
                    </small>
                  </span>
                </span>
              </Form.Label>
              {/* set onChange to populate account with the chosen account details */}
              <Form.Control
                as="select"
                onChange={chooseBeneficiary}
                className="select-input"
              >
                <option value="">Select...</option>
                {beneficiaries.map((beneficiary, index) => (
                  <option key={index} value={beneficiary.id}>
                    {beneficiary.account_name +
                      " " +
                      beneficiary.account_number +
                      " " +
                      beneficiary.bank_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        ) : (
          <p className="text-primary text-center">
            You have no saved beneficiary
          </p>
        )}
      </section>
    </>
  );
};

export default AccDetail;
