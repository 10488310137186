export const GET_ACCOUNTS = "get accounts";
export const SET_ACCOUNTS = "set accounts";
export const REMOVE_ACCOUNTS = "remove accounts";
export const SET_TOTAL_BALANCE = "set total balance";

export const setAccounts = (accounts) => ({
  type: SET_ACCOUNTS,
  accounts,
});

export const setTotalBalance = (totalBalance) => ({
  type: SET_TOTAL_BALANCE,
  totalBalance,
});

export const getAccounts = () => ({
  type: GET_ACCOUNTS,
});

export const removeAccounts = () => ({
  type: REMOVE_ACCOUNTS,
});


const initialState = {
  totalBalance: "0",
  accounts: [],
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return { ...state, accounts: action.accounts };
    case SET_TOTAL_BALANCE:
      return { ...state, totalBalance: action.totalBalance };
    case REMOVE_ACCOUNTS:
      return { ...state, accounts: [] };
    default:
      return state;
  }
};

export default accountsReducer;
