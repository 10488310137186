export const GET_PROVIDERS = "get providers";
const SET_PROVIDERS = "set providers";
const REMOVE_PROVIDERS = "remove providers";

export const setProviders = (providers) => ({
  type: SET_PROVIDERS,
  providers,
});

export const getProviders = () => ({ type: GET_PROVIDERS });

export const removeAccounts = () => ({ type: REMOVE_PROVIDERS });

const initailState = {
  providers: undefined,
};

const providersReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_PROVIDERS:
      return { ...state, providers: {...action.providers} };
    case REMOVE_PROVIDERS:
      return { ...state, providers: undefined };
    default:
      return state;
  }
};

export default providersReducer;
