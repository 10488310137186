import React, { useState } from "react";
import Cleave from "cleave.js/react";
import * as HiIcons from "react-icons/hi";
import * as MdIcons from "react-icons/md";
import { Alert } from "react-bootstrap";
import { validateFundAccount } from "../../fundAccValidation";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { thousandSeparator } from "../../../../utils/thousandSeparator";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Link } from "react-router-dom";
import { getCurrencySymbol } from "../../../../utils/normalize";

// FundAccount is a clone of sendmoney, non bootstrap classes came from sendmoney folder
const FundAccount1 = ({ payment, setPayment }) => {
  const accounts = useSelector((state) => state.accounts.accounts);
  const schedules = useSelector((state) => state.schedules.schedules);

  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setPayment({
      ...payment,
      [prop]: event.target.value,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setShowAlert(false);
    setLoading(true);
    setErrors([]);

    //validate form inputs
    if (validateFundAccount(payment).length > 0) {
      setLoading(false);
      setErrors(validateFundAccount(payment));
      return setShowAlert(true);
    }

    let data = { ...payment };
    // generate reference code
    data.transaction_reference = uuidv4().replace(/-/g, "");

    data.type === "instant" && delete data.schedule;

    axios
      .post(`${process.env.REACT_APP_API}/card-payments`, data)
      .then((res) => {
        window.location.href = res.data.data.card_payment.url;
        setTimeout(() => setLoading(false), 2000);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        let apiErrors = [];
        if (err.response.status === 422) {
          const errors = err.response.data.error;
          for (const property in errors) {
            if (typeof errors[property] === "string") {
              apiErrors.unshift(errors[property]);
            } else {
              apiErrors.unshift(errors[property][0]);
            }
          }
        } else {
          apiErrors.push(err.response.data.error.message);
        }
        setErrors(apiErrors);
        setShowAlert(true);
      });
  };

  return (
    <>
      <p className="recurring-cards_mobile">
        Add money to your Shanono account with a debit card.
        <br />
        Click <Link to="/fund-account/recurring-payments">here</Link> to manage
        recurring payments.
      </p>
      <div className="sendmoney1">
        <div>
          <div className="p-3 p-lg-4 bg-white sendmoney-whitebg">
            <h3 className="text-primary mb-4 new-transact">Fund Account</h3>
            <Alert variant="danger" show={showAlert}>
              {errors.map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </Alert>
            <div>
              <Form.Group controlId="exampleForm.ControlSelect22">
                <Form.Label className="sendmoney-select_acc-label">
                  Account to be funded
                </Form.Label>
                <Form.Control
                  as="select"
                  name="schedule"
                  onChange={handleChange("beneficiary_account_number")}
                  className="select-input"
                  value={payment.beneficiary_account_number}
                >
                  <option value="">-- Please select an account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option key={index} value={account.account_number}>
                        {account.account_name} - {account.type.name} -{" "}
                        {getCurrencySymbol(account)}
                        {thousandSeparator(account.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>

            <div>
              <p className="sendmoney-select_acc-label">Payment Type</p>
              <div className="d-flex">
                {/* beneficiary-unselected removes the highlight */}
                <p
                  onClick={() => setPayment({ ...payment, type: "instant" })}
                  className={
                    payment.type === "recurring"
                      ? "mr-2 text-primary sendmoney1-select_acc beneficiary-type beneficiary-unselected"
                      : "mr-2 text-primary sendmoney1-select_acc beneficiary-type"
                  }
                >
                  <span className="beneficiary-icon">
                    <HiIcons.HiOutlineArrowNarrowRight />
                  </span>
                  One Time Funding
                </p>
                <p
                  onClick={() => setPayment({ ...payment, type: "recurring" })}
                  className={
                    payment.type === "recurring"
                      ? "text-primary sendmoney1-select_acc beneficiary-type"
                      : "text-primary sendmoney1-select_acc beneficiary-type beneficiary-unselected"
                  }
                >
                  <span className="beneficiary-icon">
                    <MdIcons.MdLoop />
                  </span>
                  Recurring Funding
                </p>
              </div>
            </div>

            <div className={payment.type === "recurring" ? "" : "d-none"}>
              <Form.Group controlId="exampleForm.ControlSelect2">
                <Form.Label className="sendmoney-select_acc-label">
                  Schedule
                </Form.Label>
                <Form.Control
                  as="select"
                  name="schedule"
                  onChange={handleChange("schedule")}
                  className="select-input"
                  value={payment.schedule}
                >
                  <option value="">-- Please select a schedule --</option>
                  {schedules &&
                    Object.keys(schedules).map((key, index) => (
                      <option key={index} value={key}>
                        {schedules[key]}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div>
              <p className="sendmoney-select_acc-label">Amount</p>

              <Cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  prefix: `${getCurrencySymbol(null) + " "}`,
                  rawValueTrimPrefix: true,
                }}
                className="select-input"
                id="amount-input"
                name="amount"
                value={payment.amount}
                onChange={(e) =>
                  setPayment({ ...payment, amount: e.target.rawValue })
                }
              />
            </div>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="sendmoney-select_acc-label">
                Description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="sendmoney-textarea"
                name="description"
                onChange={handleChange("description")}
                value={payment.description}
              />
            </Form.Group>
          </div>
          <button
            type="submit"
            className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
            onClick={handleClick}
            disabled={loading}
          >
            {loading ? "Please wait..." : "Proceed"}
          </button>
        </div>
      </div>
    </>
  );
};

export default FundAccount1;
