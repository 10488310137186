import axios from "axios";
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import Switch from "../../../components/Switch";

const VerifyPhoneForm1 = ({ setStep, phone, setPhone, telephone }) => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const [check, setCheck] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setErrors([]);
    setLoading(true);
    if (!phone) {
      setLoading(false);
      return setErrors(["Plaese enter your phone number"]);
    }

    axios
      .post(`${process.env.REACT_APP_API}/tokens/verification/telephone`, {
        telephone: phone,
        whatsapp: check,
      })
      .then((res) => {
        setLoading(false);
        setStep(2);
      })
      .catch((err) => {
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const errors = err.response.data.error;
          for (const property in errors) {
            if (typeof errors[property] === "string") {
              apiErrors.unshift(errors[property]);
            } else {
              apiErrors.unshift(errors[property][0]);
            }
          }
          setErrors(apiErrors);
        } else if (err.response.status === 403) {
          setErrors([err.response.data.message]);
        } else {
          setErrors([err.response.data.error.message]);
        }
      });
  };

  return (
    <div className="enter-phone">
      {errors.length > 0 && (
        <div className="alert alert-danger login-alert w-100" role="alert">
          {errors.map((item, index) => (
            <p key={index} style={{ fontSize: "14px" }}>
              {item}
            </p>
          ))}
        </div>
      )}
      <p className="reg-label pt-3">ENTER YOUR PHONE NUMBER</p>
      <PhoneInput
        inputProps={{
          name: "telephone",
          required: true,
        }}
        inputStyle={{
          background: "#f3f5fc",
          height: "43px",
        }}
        inputClass="verify-phone-input"
        containerClass="mb-3"
        onChange={(telephone) => setPhone("+" + telephone)}
        country={"ng"}
        placeholder="phone number"
        defaultErrorMessage="required"
        enableSearch={true}
        value={phone}
        // disabled={telephone ? true : false}
      />

      {/* <Switch label="Get Token on Whatsapp" 
             
            onChange={() => setCheck(!check)}
            /> */}

      <button
        className="btn btn-primary mt-3"
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? "Please wait..." : "Send Verification Code"}
      </button>
    </div>
  );
};

export default VerifyPhoneForm1;
