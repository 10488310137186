import React, { useState } from "react";
import "./SendMoney1.scss";
import * as HiIcons from "react-icons/hi";
import * as TiIcons from "react-icons/ti";
import { Alert, Form } from "react-bootstrap";
import AccDetail from "../acc-detail/AccDetail";
import { validateTransfer } from "../../../../utils/sendMoneyValidation";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { thousandSeparator } from "../../../../utils/thousandSeparator";
import Cleave from "cleave.js/react";
import { fireAlert } from "../../../../utils/Alert";
import { errorHandler } from "../../../../utils/errorHandler";
import axios from "axios";
import LoadingOverLay from "../../../../components/LoadingOverLay";
import { getCurrencySymbol } from "../../../../utils/normalize";

const SendMoney1 = ({
  transaction,
  setTransaction,
  step,
  setStep,
  handleChange,
  beneficiaries,
  setBeneficiaries,
}) => {
  const accounts = useSelector((state) => state.accounts.accounts);
  const [newAccount, setNewAccount] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setShowAlert(false);
    const id = uuidv4().replace(/-/g, "");
    setTransaction({
      ...transaction,
      transaction_reference: id,
    });
    if (validateTransfer(transaction).length > 0) {
      return setShowAlert(true);
    }
    return setStep(2);
  };

  const getBeneficiaries = async () => {
    if (!transaction.source_account_number) {
      return fireAlert("Error", "Please choose a source account", "error");
    }
    setLoading(true);
    try {
      console.log("Fetching beneficiaries for account:", transaction.source_account_number);
      const res = await axios.get(
        `${process.env.REACT_APP_API}/beneficiaries?source=${transaction.source_account_number}`
      );
      console.log("Response from fetching beneficiaries:", res.data.status);
      const { beneficiaries } = res.data.data;
      setBeneficiaries(beneficiaries);
      setNewAccount(false);
      setTransaction({
        ...transaction,
        channel: "",
        beneficiary_bank_name: "",
        beneficiary_bank_code: "",
        beneficiary_account_number: "",
        beneficiary_account_name: "",
      });
    } catch (err) {
      console.error("Error fetching beneficiaries:", err);
      let html = errorHandler(err.response || err.message);
      fireAlert("Error", html, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingOverLay loading={loading} />
      <div className={step === 1 ? "sendmoney1" : "d-none"}>
        <div>
          <div className="p-3 p-lg-4 bg-white sendmoney-whitebg">
            <h3 className="text-primary mb-4 new-transact">New Transaction</h3>
            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
              show={showAlert}
            >
              {validateTransfer(transaction).map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </Alert>
            <div>
              <Form.Group controlId="select-acc">
                <Form.Label className="sendmoney-select_acc-label">Source Account</Form.Label>
                <Form.Control
                  as="select"
                  name="source_account_number"
                  value={transaction.source_account_number}
                  onChange={handleChange}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option key={index} value={account.account_number}>
                        {account.account_name} - {account.type.name} -{" "}
                        {getCurrencySymbol(account)}
                        {thousandSeparator(account.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div>
              <p className="sendmoney-select_acc-label">Amount</p>
              <Cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  rawValueTrimPrefix: true,
                }}
                className="select-input"
                id="amount-input"
                name="amount"
                value={transaction.amount}
                onChange={handleChange}
                inputMode="numeric"
              />
            </div>
            <div>
              <p className="sendmoney-select_acc-label">Beneficiary Type</p>
              <div className="d-flex">
                <p
                  onClick={() => {
                    setNewAccount(true);
                    setTransaction({
                      ...transaction,
                      channel: "",
                      beneficiary_bank_name: "",
                      beneficiary_bank_code: "",
                      beneficiary_account_number: "",
                      beneficiary_account_name: "",
                    });
                  }}
                  className={
                    newAccount
                      ? "mr-2 text-primary sendmoney1-select_acc beneficiary-type"
                      : "mr-2 text-primary sendmoney1-select_acc beneficiary-type beneficiary-unselected"
                  }
                >
                  <span className="beneficiary-icon">
                    <HiIcons.HiUserAdd />
                  </span>
                  New Beneficiary
                </p>
                <p
                  onClick={getBeneficiaries}
                  className={
                    newAccount
                      ? "text-primary sendmoney1-select_acc beneficiary-type beneficiary-unselected"
                      : "text-primary sendmoney1-select_acc beneficiary-type"
                  }
                >
                  <span className="beneficiary-icon">
                    <TiIcons.TiGroup />
                  </span>
                  Saved Beneficiary
                </p>
              </div>
            </div>

            <AccDetail
              newAccount={newAccount}
              handleChange={handleChange}
              transaction={transaction}
              setTransaction={setTransaction}
              beneficiaries={beneficiaries}
            />

            <div>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="sendmoney-select_acc-label">
                  Transaction Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="sendmoney-textarea"
                  name="description"
                  onChange={handleChange}
                  value={transaction.description}
                />
              </Form.Group>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
            onClick={handleClick}
            disabled={
              transaction.beneficiary_account_name === "Loading..."
                ? true
                : false
            }
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default SendMoney1;
