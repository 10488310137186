import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "./SendMoney2.scss";
import { thousandSeparator } from "../../../../utils/thousandSeparator";
import { checkBeneficiary } from "../../../../utils/checkBeneficiary";
import { useDispatch, useSelector } from "react-redux";
import { checkAccountType } from "../../../../utils/checkAccountType";
import axios from "axios";
import { fireAlert } from "../../../../utils/Alert";
import { Alert } from "react-bootstrap";
import { getAccounts } from "../../../../redux/ducks/accounts";
import { errorHandler } from "../../../../utils/errorHandler";

const Sendmoney2 = ({
  transaction,
  step,
  setStep,
  setTransaction,
  beneficiaries,
}) => {
  const dispatch = useDispatch();

  const initailState = {
    source_account_number: "",
    amount: "",
    channel: "",
    beneficiary_account_number: "",
    beneficiary_account_name: "",
    description: "",
    remember: false,
    transaction_reference: "",
    pin: "",
  };

  const accounts = useSelector((state) => state.accounts.accounts);
  const [errors, setErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setShowAlert(false);
    setLoading(true);
    if (!transaction.pin || transaction.pin.length !== 4) {
      setErrors(["Plaese enter your transaction pin"]);
      setLoading(false);
      return setShowAlert(true);
    }

    axios
      .post(`${process.env.REACT_APP_API}/transactions`, transaction)
      .then((res) => {
        //refresh balance
        dispatch(getAccounts());
        // if successful fire alert
        setLoading(false);
        setErrors([]);
        setStep(1);
        setTransaction({ ...transaction, ...initailState });

        let title, html, icon;
        // double checking with status as recomended by Kenny(Backend)
        if (res.data.data.transaction.status === "success") {
          title = "Transfer Successful";
          html = `Your transfer of ₦ ${thousandSeparator(
            transaction.amount
          )} to ${transaction.beneficiary_account_name} was successful`;
          icon = "success";
          fireAlert(title, html, icon);
        } else if (res.data.data.transaction.status === "pending") {
          title = "Transaction Pending";
          html = `Your transfer of ₦ ${thousandSeparator(
            transaction.amount
          )} to ${transaction.beneficiary_account_name} will be processed`;
          icon = "info";
          fireAlert(title, html, icon);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrors([]);
        let html = errorHandler(err.response);
        const title = "Transaction Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  return (
    <div className={step === 2 ? "sendmoney2" : "d-none"}>
      <div className="p-3 p-lg-4 bg-white sendmoney-whitebg">
        <h3 className="text-primary mb-4 new-transact">Review Transaction</h3>
        <div>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Source Account Number:</span>
            <span>{transaction.source_account_number}</span>
          </p>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Source Account Name:</span>
            <span> {checkAccountType(accounts, transaction).name}</span>
          </p>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Source Account Type:</span>
            <span className="text-capitalize">
              {checkAccountType(accounts, transaction).type}
            </span>
          </p>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Amount:</span>
            <span>&#8358; {thousandSeparator(transaction.amount)}</span>
          </p>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Beneficiary Bank:</span>
            <span>{transaction.beneficiary_bank_name}</span>
          </p>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Beneficiary Account Number:</span>
            <span>{transaction.beneficiary_account_number}</span>
          </p>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Beneficiary Name:</span>
            <span>{transaction.beneficiary_account_name}</span>
          </p>
          <p className="d-flex justify-content-between flex-wrap py-3 review-items">
            <span className="mr-2">Transaction Description:</span>
            <span>{transaction.description}</span>
          </p>
          <div
            className={
              checkBeneficiary(beneficiaries, transaction)
                ? "d-none"
                : "form-check"
            }
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="remember"
              onChange={() =>
                setTransaction({
                  ...transaction,
                  remember: !transaction.remember,
                })
              }
            />
            <label className="form-check-label review-items" htmlFor="remember">
              Save Beneficiary
            </label>
          </div>
        </div>
        <div>
          <p className="pt-3 review-items">Transaction Pin</p>
          <OtpInput
            value={transaction.pin}
            onChange={(otp) => {
              setTransaction({ ...transaction, pin: otp });
            }}
            numInputs={4}
            isInputNum={true}
            isInputSecure={true}
            containerStyle={{
              gap: "0.5rem",
            }}
            inputStyle={{
              width: "40px",
              height: "40px",
              background: "#F3F5FC",
              border: "1px solid #D6DCDC",
            }}
            focusStyle={{
              border: "1px solid #008080",
              outline: "1px solid #008080",
            }}
            shouldAutoFocus={true}
            //hasErrored={error ? true : false}
            //errorStyle={{ border: "1px solid red" }}
          />
        </div>

        <div className="pt-4 pb-5">
          <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
            show={showAlert}
          >
            {errors.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </Alert>
          <div className="d-flex justify-content-between flex-wrap">
            <p
              className="btn text-primary review-items review-previous"
              onClick={() => setStep(1)}
            >
              Previous
            </p>
            <button
              className="btn btn-primary px-5 font-weight-bold"
              onClick={handleClick}
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Proceed"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sendmoney2;
