import axios from "axios";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Cleave from "cleave.js/react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { hours } from "../../../utils/hours";

const EditModal = ({
  showModal,
  setShowModal,
  mandate,
  setMandate,
  fetch,
  setFetch,
}) => {
  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setMandate({ id: "", amount: "", schedule: "", end_date: "" });
    setEndDate(null);
    setAlerts({ messages: [], type: "" });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    setAlerts({ messages: [], type: "" });
    const { id, amount, schedule,hour_of_day } = mandate;
    if (!amount || !endDate || !schedule || !hour_of_day) {
      setLoading(false);
      return setAlerts({
        messages: ["All fields are required"],
        type: "danger",
      });
    }

    const update = {
      amount,
      schedule,
      hour_of_day,
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    axios
      .patch(`${process.env.REACT_APP_API}/recurring-payments/${id}`, update)
      .then((res) => {
        setFetch(!fetch);
        setAlerts({
          messages: ["Update successful"],
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Auto Transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alerts.messages.length > 0 && (
          <div
            className={`alert alert-${alerts.type} login-alert w-100`}
            role="alert"
          >
            {alerts.messages.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </div>
        )}
        <div>
          <p className="sendmoney-select_acc-label">Amount</p>
          <Cleave
            options={{
              numeral: true,
              numeralThousandsGroupStyle: "thousand",
              prefix: "₦ ",
              rawValueTrimPrefix: true,
            }}
            className="select-input"
            id="amount-input"
            name="amount"
            value={mandate.amount}
            onChange={(e) =>
              setMandate({ ...mandate, amount: e.target.rawValue })
            }
          />
        </div>
        <div>
          <Form.Group controlId="select-acc">
            <Form.Label className="sendmoney-select_acc-label">
              Schedule
            </Form.Label>
            <Form.Control
              as="select"
              name="schedule"
              value={mandate.schedule}
              onChange={(e) =>
                setMandate({ ...mandate, schedule: e.target.value })
              }
              className="select-input sendmoney1-select_acc"
              // style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="bi_weekly">
                Bi-weekly (once every two weeks)
              </option>
              <option value="monthly">Monthly</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <Form.Group controlId="select-acc">
            <Form.Label className="sendmoney-select_acc-label">Time</Form.Label>
            <Form.Control
              as="select"
              name="hour_of_day"
              value={mandate.hour_of_day}
              onChange={(e) =>
                setMandate({ ...mandate, hour_of_day: e.target.value })
              }
              className="select-input sendmoney1-select_acc"
              // style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
            >
              {hours.map((hour, index) => (
                <option key={index} value={hour.value}>
                  {hour.time}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <div className="w-100 mt-n3">
            <p className="sendmoney-select_acc-label">End Date</p>
            <DatePicker
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              selected={endDate}
              placeholderText="DD/MM/YYYY"
              onChange={(date) => setEndDate(date)}
              className="select-input sendmoney1-select_acc form-control"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-light"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? "Processing..." : "Update"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
