import { call, put } from "redux-saga/effects";
import { setBanks,loadingBanks } from "../../ducks/banks";
import { requestGetBanks } from "../request/banks";

export function* handleGetBanks(action) {
    console.log(action);
  try {
      console.log("YES")
      //yield put(loadingBanks());
    // make the get request
    const response = yield call(requestGetBanks(action.source_account_number));
    const { data } = response;
    // dispatch to redux
    yield put(setBanks(data.data.banks));
  } catch (error) {
    console.log(error);
  //  yield put(setBanks(undefined));
    alert("Error getting banks");
  }
}
