export const validateTransfer = (transaction) => {
  const result = [];
  //if auto transfer then it requires account id
  if (!transaction.source_account_number && !transaction.account_id)
    result.push("Please choose source account");

  if (!transaction.amount) result.push("Please enter amount");

  if (!transaction.channel) result.push("Please select transfer type");

  if (!transaction.beneficiary_bank_name) result.push("Please select a bank");

  if (!transaction.beneficiary_account_number)
    result.push("Account number is required");

  if (
    transaction.beneficiary_account_number &&
    transaction.beneficiary_account_number.length < 10
  )
    result.push("Account number must not be less than 10 digits");

  if (!transaction.beneficiary_account_name)
    result.push("Account name is required");

  // for automatic funds transfer
  if ("schedule" in transaction) {
    if (!transaction.schedule) result.push("Schedule is required");
    if (!transaction.start_date) result.push("Start date is required");
    if (!transaction.end_date) result.push("End date is required");
    if (!transaction.hour_of_day) result.push("Time is required");
    if (transaction.start_date && transaction.end_date) {
      const startDate = new Date(transaction.start_date).toJSON().slice(0, 10);
      const endDate = new Date(transaction.end_date).toJSON().slice(0, 10);
      if (startDate > endDate)
        result.push("End date cannot be before start date");
    }
  }

  return result;
};
