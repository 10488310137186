import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { fireAlert } from "../../../../utils/Alert";
import { errorHandler } from "../../../../utils/errorHandler";
import { thousandSeparator } from "../../../../utils/thousandSeparator";
import "./InvestmentForm2.scss";

const InvestmentForm2 = ({
  setStep,
  formValues,
  investmentTypes,
  rolloverTypes,
  setFormValues,
  history,
}) => {
  const [isLoading, setLoading] = useState(false);

  const rollover = formValues.rollover_type
  ? rolloverTypes.filter(
      (type) => type.id === formValues.rollover_type
    )[0]
  : "";

  const type = formValues
    ? investmentTypes.filter(
        (type) => type.id === formValues.investment_type
      )[0]
    : "";
  const startDate = moment().format("MMMM Do, YYYY");
  const maturityDate = type
    ? moment().add(type.duration, "M").format("MMMM Do, YYYY")
    : "";

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    
    axios
      .post(`${process.env.REACT_APP_API}/investments`, formValues)
      .then((res) => {
        setLoading(false);
        setFormValues({});
        const title = "Invesment Successful";
        const html =
          "Your investment has been successfully created and added to your account";
        const icon = "success";
        fireAlert(title, html, icon);
        history.replace("/investment");
      })
      .catch((err) => {
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Unsuccessful";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };
  return (
    <div>
      <div className="bg-white rounded p-4 mt-2 text-capitalize">
        <div className="invest-review-header">
          <h2 className="text-primary">Investment Name</h2>
          <p>{formValues.investment_type && type.name} Plan</p>
        </div>
        <hr />
        <div className="mb-4 mt-3">
          <p>Amount</p>
          <p className="mt-n2">
            &#8358;{formValues.amount && thousandSeparator(formValues.amount)}
          </p>
        </div>
        <div className="d-flex justify-content-between my-3">
          <div>
            <p>Start Date</p>
            <p className="mt-n2">{startDate}</p>
          </div>
          <div className="text-right">
            <p>Maturity Date</p>
            <p className="mt-n2">{maturityDate}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div>
            <p>Interest Rate</p>
            <p className="mt-n2">
              {formValues.investment_type && type.interest_rate}%
            </p>
          </div>
          <div className="text-right">
            <p>Rollover Type</p>
            <p className="mt-n2">
              {formValues.rollover_type
                ? rollover.name
                : "No rollover"}
            </p>
          </div>
        </div>
      </div>
      <div className="my-3">
        <button
          className="btn btn-primary py-2 px-3 d-block ml-auto"
          style={{ fontSize: "14px" }}
          onClick={handleClick}
          disabled={isLoading}
        >
          {isLoading ? "Processing..." : "Complete Investment"}
        </button>
      </div>
    </div>
  );
};

export default withRouter(InvestmentForm2);
