import axios from "axios";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { thousandSeparator } from "../../../utils/thousandSeparator";

const RightTransactions = ({ history }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API}/recent-transactions`, {
          cancelToken: source.token,
        });
        if (isMounted.current) {
          setTransactions([...res.data.data.transactions]);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted.current) {
          console.log(err);
          setLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup
    return () => {
      isMounted.current = false;
      source.cancel();
    };
  }, []);

  // Show all transactions
  const showAll = () => {
    history.push("/transactions");
  };

  return (
    <div className="right-transactions">
      <div className="mt-3 mx-2 d-flex justify-content-between dashtransact-heading">
        <h4>Recent Transactions</h4>
        <p onClick={showAll}>View all</p>
      </div>
      <div className="mt-2">
        {isLoading && <p className="text-center">Loading...</p>}
        {!isLoading && transactions.length < 1 && (
          <div className="text-primary text-center">
            <p>You have no transactions</p>
          </div>
        )}
        <ul className="right-transaction-list">
          {transactions.map((item, index) => (
            <li
              key={index}
              className="d-flex justify-content-between right-transaction-listitem"
            >
              <div className="d-flex">
                <div
                  className={
                    item.type === "debit"
                      ? "right-transaction-bigdot bg-danger"
                      : "right-transaction-bigdot"
                  }
                ></div>
                <div>
                  <h6 className="right-transaction-list_heading">
                    {item.transaction_reference &&
                      item.transaction_reference.substring(0, 10)}
                    ...
                  </h6>
                  <p className="right-transaction-list_subheading">
                    {item.narration && item.narration.substring(0, 15)}...
                  </p>
                </div>
              </div>
              <div className="mr-1">
                <h6
                  className={
                    item.type === "debit"
                      ? "text-danger right-transaction-amount text-right"
                      : "text-primary right-transaction-amount text-right"
                  }
                >
                  ₦{item.amount && thousandSeparator(item.amount)}
                </h6>
                <p>
                  {item.created_at &&
                    moment(item.created_at).format("MMMM Do, YYYY")}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(RightTransactions);
