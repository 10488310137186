import React from "react";
import { Spinner } from "react-bootstrap";

const PageLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center user-dashboard">
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default PageLoader;
