import React, { useEffect, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Form, Modal, Spinner } from "react-bootstrap";
import InputField from "../../../components/InputField.jsx";
import InputDropDown from "../../../components/InputDropDown";
import LoadingImage from "../../../assets/card-loading.svg";
import { validateCardOrderForm } from "./validateCardOrderForm.js";
import NewCardLeft from "./NewCardLeft.jsx";
import axios from "axios";
import LoadingSpinner from "../../../components/LoadingSpinner.jsx";
import { thousandSeparator } from "../../../utils/thousandSeparator.js";
import { fireAlert } from "../../../utils/Alert";
import { deliveryFee } from "../../../utils/deliveryFee.js";
import { errorHandler } from "../../../utils/errorHandler.js";

const cardFormStyles = {
  // background: "#e2f3f3",
  // border: "1px solid #79b0b0",
  width: "100%",
};

const AddCardForm = ({ history, handleCardFormSubmit }) => {
  const [showCardInTransitModal, setShowCardInTransitModal] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [countries, setCountries] = useState(null);
  const [states, setStates] = useState(null);
  const [loadingStates, setLoadingStates] = useState(false);
  const [stateError, setStateError] = useState("");
  const [loadingCardData, setLoadingCardData] = useState(true);
  const [loading, setLoading] = useState(false);

  const getStates = (countryId) => {
    setLoadingStates(true);
    setStates(null);
    axios
      .get(`${process.env.REACT_APP_API}/countries/${countryId}/states`)
      .then((res) => {
        setLoadingStates(false);
        setStates(res.data.data.states);
      })
      .catch((err) => {
        setLoadingStates(false);
        console.log(err);
      });
  };

  const requestCardData = axios.get(
    `${process.env.REACT_APP_API}/card-requests/create`
  );
  const requestCountries = axios.get(`${process.env.REACT_APP_API}/countries`);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .all([requestCardData, requestCountries])
      .then(
        axios.spread((res1, res2) => {
          setLoadingCardData(false);
          setCardData(res1.data.data);
          setCountries(res2.data.data.countries);
        })
      )
      .catch((err) => {
        setLoadingCardData(false);
        console.log(err);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, []);

  const handleCloseCardInTransitModal = () => {
    setShowCardInTransitModal(false);
    history.push("/cards");
  };

  // Show Card in Transit Modal and go to card page with cards already added
  const handleSubmit = (values) => {
    setLoading(true);
    //Ensure a user pick a state if required
    if (
      !states &&
      values.existing_address !== "" &&
      values.existing_address !== "true"
    ) {
      setLoading(false);
      return setStateError("Please choose a state");
    }

    if (
      states &&
      states.length > 0 &&
      values.state === "" &&
      values.existing_address !== "true"
    ) {
      setLoading(false);
      return setStateError("Please choose a state");
    }

    const { account_id, card_type, delivery_option, existing_address, pin } =
      values;

    let newRequest = {};

    if (delivery_option === "pickup") {
      newRequest = { account_id, card_type, delivery_option, pin };
    } else if (existing_address === "true") {
      newRequest = {
        account_id,
        card_type,
        delivery_option,
        existing_address: true,
        pin,
      };
    } else {
      newRequest = { ...values, existing_address: false };
    }

    axios
      .post(`${process.env.REACT_APP_API}/card-requests`, newRequest)
      .then((res) => {
        setLoading(false);
        setShowCardInTransitModal(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
    // handleCardFormSubmit();
  };

  if (loadingCardData) {
    return <LoadingSpinner />;
  }

  //if after loading and there's no data
  if (!cardData || !cardData.card_types[0].id) {
    return (
      <p className="text-primary text-center mt-5">
        Something went wrong, please try again
      </p>
    );
  }

  return (
    <>
      <div className="new-card-wrapper">
        <NewCardLeft />
        <div className="new-card-right">
          <Formik
            // enableReinitialize
            initialValues={{
              account_id: "",
              card_type: cardData.card_types[0].id,
              delivery_option: "",
              existing_address: "",
              address_line_one: "",
              address_line_two: "",
              country: "",
              state: "",
              city: "",
              pin: "",
            }}
            validationSchema={validateCardOrderForm}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <FormikForm>
                <div>
                  <div className="p-3 p-lg-4 bg-white sendmoney-whitebg">
                    <InputDropDown
                      label="Card type"
                      name="card_type"
                      // noValue="-- Choose Card Type --"
                      options={[
                        ...cardData.card_types.map(
                          (card) =>
                            card.name +
                            " - " +
                            "₦" +
                            thousandSeparator(card.price)
                        ),
                      ]}
                      values={[...cardData.card_types.map((card) => card.id)]}
                      style={cardFormStyles}
                    />

                    <InputDropDown
                      label="Which account would this be linked to?"
                      noValue="-- Choose Account --"
                      name="account_id"
                      options={[
                        ...cardData.accounts.map(
                          (account) =>
                            account.account_name +
                            " - " +
                            account.account_number +
                            " - " +
                            "₦" +
                            thousandSeparator(account.available_balance)
                        ),
                      ]}
                      values={[
                        ...cardData.accounts.map((account) => account.id),
                      ]}
                      style={cardFormStyles}
                    />

                    <InputDropDown
                      label="Delivery Method"
                      noValue="-- Choose Delivery Method --"
                      name="delivery_option"
                      options={["Pickup at our Office", "Door Delivery"]}
                      values={["pickup", "dispatch"]}
                      style={cardFormStyles}
                    />

                    {/* Only show when pickup option is chosen */}

                    {formik.values.delivery_option === "pickup" && (
                      <>
                        <div>
                          <Form.Group controlId="pickup-address">
                            <Form.Label className="sendmoney-select_acc-label">
                              Pick Up Address
                            </Form.Label>
                            <Form.Control
                              disabled
                              as="textarea"
                              rows={3}
                              value="No.3 John Great Court, Chevron Drive, Lekki Expressway, Lekki, Lagos"
                              className="sendmoney-textarea"
                              style={cardFormStyles}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}

                    {/* Make API call and post request if pick up option is chosen */}

                    {/* if Door Delivery(Dispatch) is chosen, show "use existing address option" */}

                    {formik.values.delivery_option === "dispatch" && (
                      <InputDropDown
                        label="Use Existing address"
                        noValue="-- Select Option --"
                        name="existing_address"
                        options={["Yes", "No"]}
                        values={[true, false]}
                        style={cardFormStyles}
                      />
                    )}

                    {/* if useExistingAddress is true, make API call and post request" */}

                    {/* if useExistingAddress is false, show Address Line Fields, Country, State and City options" */}

                    {formik.values.existing_address === "false" &&
                      formik.values.delivery_option === "dispatch" && (
                        <>
                          <InputField
                            label="Address Line 1"
                            name="address_line_one"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputField
                            label="Address Line 2"
                            name="address_line_two"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputDropDown
                            label="Country"
                            noValue="-- Select Country --"
                            name="country"
                            options={[
                              ...countries.map((country) => country.name),
                            ]}
                            values={[...countries.map((country) => country.id)]}
                            style={cardFormStyles}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "country",
                                event.currentTarget.value
                              );
                              formik.setFieldValue("state", "");
                              getStates(event.currentTarget.value);
                            }}
                          />

                          {/* <div className={loadingStates ? "d-none" : ""}>
                          <InputDropDown
                            label="State"
                            noValue="-- Select State --"
                            name="state"
                            options={["Lagos", "Oyo"]}
                            values={["lagos", "oyo"]}
                            style={cardFormStyles}
                          />
                        </div> */}
                          <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label className="sendmoney-select_acc-label">
                              State
                            </Form.Label>
                            <p className={loadingStates ? "" : "d-none"}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </p>
                            <Form.Control
                              as="select"
                              name="state"
                              className={
                                loadingStates ? "d-none" : "select-input"
                              }
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "state",
                                  event.currentTarget.value
                                );
                              }}
                            >
                              <option value="">-- Select State --</option>
                              {states &&
                                states.map((state, index) => (
                                  <option key={index} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                          <InputField
                            label="City"
                            name="city"
                            type="text"
                            style={cardFormStyles}
                          />
                        </>
                      )}
                    <InputField
                      label="Transaction pin"
                      name="pin"
                      type="password"
                      style={cardFormStyles}
                      maxLength="4"
                    />
                    <div
                      className={
                        formik.values.existing_address === "false"
                          ? ""
                          : "d-none"
                      }
                    >
                      <p className="d-flex justify-content-between text-primary">
                        <span>Delivery Fee:</span>
                        <span>
                          {deliveryFee(formik.values, countries, states)
                            ? "₦" +
                              thousandSeparator(
                                deliveryFee(formik.values, countries, states)
                              )
                            : "Applicable"}
                        </span>
                      </p>
                    </div>
                    <div
                      className={stateError ? "alert alert-danger" : "d-none"}
                      role="alert"
                    >
                      {stateError}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
                    disabled={loading}
                  >
                    {loading ? "Processing..." : " Request Card"}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>

      {/* Card in Transit Modal */}

      <Modal
        show={showCardInTransitModal}
        onHide={handleCloseCardInTransitModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="cards-modal-content card-in-transit"
      >
        <p
          className="ml-auto text-danger btn"
          style={{ fontSize: "24px" }}
          onClick={handleCloseCardInTransitModal}
        >
          <strong>&#128473;</strong>
        </p>
        <div className="d-flex align-items-center justify-content-center text-center flex-column">
          <div className="mb-2">
            <img src={LoadingImage} alt="Card Loading" />
          </div>
          <h5 className="card-in-transit-header">Thank you</h5>
          <p className="card-in-transit-text notice">
            Your card has been shipped and would be delivered to the given
            address
          </p>
          <p className="card-in-transit-text mail">
            A mail has been sent on the estimated delivery date. Thank you
          </p>
        </div>
      </Modal>
    </>
  );
};

export default AddCardForm;
