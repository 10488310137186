import * as Yup from "yup";

// Formik Validation Schema for Get New Card Form
export const validateEditRequest = Yup.object().shape({
  delivery_option: Yup.string().required("Please select delivery option"),
  existing_address: Yup.string().when("pos_payment_method", {
    is: ["installation", "one-off", "c-caution-deposit"],
    then: Yup.string().required("Please select existing address option."),
  }),
  address_line_one: Yup.string().when("existing_address", {
    is: "false",
    then: Yup.string().required("Please enter address."),
  }),
  // address_line_two: Yup.string(),
  country: Yup.string().when("existing_address", {
    is: "false",
    then: Yup.string().required("Please select country."),
  }),
  state: Yup.string().when("existing_address", {
    is: "false",
    then: Yup.string().required("Please select state."),
  }),
  // city: Yup.string().when("existing_address", {
  //   is: "false",
  //   then: Yup.string().required("Please select city."),
  // }),
  paper_display: Yup.string().required("Please choose an option"),
  stand: Yup.string().required("Please choose an option"),
  pos_payment_method: Yup.string().required("Please select a payment method"),
  installment_amount: Yup.string().when("pos_payment_method", {
    is: "installment",
    then: Yup.string().required("Please select installment amount."),
  }),
  caution_fee: Yup.string().when("pos_payment_method", {
    is: "c-caution-deposit",
    then: Yup.string().required("Please select caution fee."),
  }),
  pin: Yup.string().max(4).required("Please enter pin"),
});
