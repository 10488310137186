export const deliveryFee = (values, countries, states) => {
  let fee = null;
  if (
    values.country &&
    values.delivery_option === "dispatch" &&
    values.existing_address !== "true"
  ) {
    let countryFee = countries.filter(
      (country) => country.id === values.country
    )[0].price;

    countryFee && (fee = countryFee);
  }

  if (
    values.state &&
    values.delivery_option === "dispatch" &&
    values.existing_address !== "true"
  ) {
    let stateFee = states.filter((state) => state.id === values.state)[0].price;

    stateFee && (fee = stateFee);
  }

  return fee;
};
