import { ErrorMessage, useField } from "formik";
import React from "react";

// the css for the non bootstrap classes can be found in register/Register.scss
const InputField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="py-3">
      {/* render label if there is a label */}
      {label && (
        <label htmlFor={field.name} className="reg-label">
          {label}
        </label>
      )}
      <input
        className={`form-control shadow-name reg-input ${
          meta.touched && meta.error && "is-invalid"
        } ${props.customclassname}`}
        {...field}
        {...props}
        autoComplete="new-password"
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
};

export default InputField;
