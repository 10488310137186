import React, { useState } from "react";
import ResetPin1 from "./ResetPin1";
import ResetPin2 from "./ResetPin2";
import { useSelector } from "react-redux";
import Unathorized from "../Unathorized";

const ResetPin = () => {
  const user = useSelector((state) => state.user);

  const [page, setPage] = useState(1);

  if (!user.has_transaction_pin) {
    return (
      <Unathorized message="You don't have a transaction pin. Please create a new pin" />
    );
  }

  return (
    <div className="edit-profile mt-5">
      <h2 className="settings-small-header">Reset Transaction Pin</h2>
      <ResetPin1 page={page} setPage={setPage} />
      <ResetPin2 page={page} setPage={setPage} />
    </div>
  );
};

export default ResetPin;
