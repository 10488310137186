export const checkBeneficiary = (beneficiaries, transaction) => {
  let isSaved = false;
  if (
    beneficiaries &&
    beneficiaries.length > 0 &&
    transaction.beneficiary_account_name
  ) {
    // check if the transaction account details is already saved in beneficiaries
    const isSavedBeneficiary = beneficiaries.filter(
      (account) =>
        account.account_name === transaction.beneficiary_account_name &&
        account.account_number === transaction.beneficiary_account_number
    );
    isSavedBeneficiary.length > 0 && (isSaved = true);
  }
  return isSaved;
};
