import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import * as MdIcons from "react-icons/md";
import Unathorized from "../settings/Unathorized";

const BeneficiaryCard = ({ beneficiaries, setBeneficiaries,error }) => {
  const [deleting, setDeleting] = useState(false);

  const deleteBeneficiary = (id) => {
    setDeleting(true);

    axios
      .delete(`${process.env.REACT_APP_API}/beneficiaries/${id}`)
      .then((res) => {
        setDeleting(false);
        //live update removes the beneficiary from list
        const newBeneficiaries = beneficiaries.filter(
          (beneficiary) => beneficiary.id != id
        );
        setBeneficiaries(newBeneficiaries);
      })
      .catch((err) => setDeleting(false));
  };

  if (error) {
    return (
      <p className="text-center text-primary">
        Something went wrong, please try again
      </p>
    );
  }

  if (beneficiaries.length == 0) {
    return <Unathorized message="You have no saved beneficiary" />;
  }

  return (
    <>
      <div
        className={
          deleting
            ? "d-flex justify-content-center align-items-center delete-indicator"
            : "d-none"
        }
      >
        <p>
          <Spinner animation="border" variant="secondary" />
        </p>
      </div>
      {beneficiaries &&
        beneficiaries.map((beneficiary, index) => (
          <div
            key={index}
            className="mb-2 d-flex justify-content-between py-1 px-2 rounded border border-primary"
          >
            <p className="mb-n1 align-self-center">
              {" "}
              {beneficiary.account_name +
                " " +
                beneficiary.account_number +
                " " +
                beneficiary.bank_name}
            </p>
            <button
              className="btn btn-outline-danger"
              disabled={deleting}
              onClick={() => deleteBeneficiary(beneficiary.id)}
            >
              <MdIcons.MdDeleteForever />
            </button>
          </div>
        ))}
    </>
  );
};

export default BeneficiaryCard;
