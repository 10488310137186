import axios from "axios";
import React, { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Form, Modal, Spinner, Toast } from "react-bootstrap";
import InputField from "../../../components/InputField.jsx";
import InputDropDown from "../../../components/InputDropDown";
import { validateEditRequest } from "./validateEditRequest.js";
import { thousandSeparator } from "../../../utils/thousandSeparator.js";

const EditReqModal = ({
  showModal,
  setShowModal,
  request,
  setRequest,
  fetch,
  setFetch,
  posData,
}) => {
  console.log(
    "🚀 ~ file: EditReqModal.jsx:19 ~ posData:",
    posData,
    "request\n",
    request
  );
  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState(null);
  const [loadingStates, setLoadingStates] = useState(false);
  const [show, setShow] = useState(false);
  const [showCautionModal, setShowCautionModal] = useState(false);
  const [isCautionAccepted, setIsCautionAccepted] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setRequest(null);
    setAlerts({ messages: [], type: "" });
  };

  const handleCloseCaution = () => {
    setShowCautionModal(false);
    setAlerts({ messages: [], type: "" });
  };

  const handleAcceptCautionTerms = () => {
    setShowCautionModal(false);
    setAlerts({
      messages: ["You Accepted Terms and conditions"],
      type: "info",
    });
    setIsCautionAccepted(true);
  };

  const handleRejectCautionTerms = () => {
    setShowCautionModal(false);
    setAlerts({
      messages: ["You Rejected Terms and conditions"],
      type: "info",
    });
    setIsCautionAccepted(false);
  };

  const getStates = (countryId) => {
    setLoadingStates(true);
    setStates(null);
    axios
      .get(`${process.env.REACT_APP_API}/countries/${countryId}/states`)
      .then((res) => {
        setLoadingStates(false);
        setStates(res.data.data.states);
      })
      .catch((err) => {
        setLoadingStates(false);
        console.log(err);
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    setAlerts({ messages: [], type: "" });

    const {
      delivery_option,
      existing_address,
      stand,
      paper_display,
      pin,
      pos_payment_method,
      installment_amount,
      referrer_code,
    } = values;

    let editData = {};
    if (delivery_option === "pickup") {
      editData = { delivery_option, pos_payment_method, pin };
    } else if (existing_address === "true") {
      editData = {
        delivery_option,
        existing_address: true,
        pos_payment_method,
        pin,
      };
    } else {
      editData = { ...values, existing_address: false };
    }

    if (stand === "true") {
      editData.stand = true;
    } else {
      editData.stand = false;
    }

    if (paper_display === "true") {
      editData.paper_display = true;
    } else {
      editData.paper_display = false;
    }

    if (pos_payment_method === "installment") {
      editData.installment_amount = installment_amount;
    }

    if (referrer_code) Object.assign(editData, { referrer_code });

    if (values?.pos_payment_method === "c-caution-deposit")
      Object.assign(editData, { caution_fee: values?.caution_fee });

    // open modal
    if (
      values?.pos_payment_method === "c-caution-deposit" &&
      !showCautionModal &&
      !isCautionAccepted
    ) {
      setShowCautionModal(true);
      setLoading(false);
      return;
    }

    if (
      values?.pos_payment_method === "c-caution-deposit" &&
      !isCautionAccepted
    ) {
      setAlerts({
        messages: ["Caution Payment Terms must be accepted"],
        type: "danger",
      });
      setLoading(false);
      return;
    }

    axios
      .patch(
        `${process.env.REACT_APP_API}/pos-requests/${request.id}`,
        editData
      )
      .then((res) => {
        setFetch(!fetch);
        setAlerts({
          messages: ["Your POS request has been updated successfully"],
          type: "success",
        });
        setLoading(false);
        resetForm(values);
        setShow(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({
            type: "danger",
            messages: [err.response.data.message],
          });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  const cardFormStyles = {
    width: "100%",
  };

  return (
    <>
      <Toast
        className="m-auto text-white bg-primary"
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
      >
        <Toast.Body>Your request has been updated successfully</Toast.Body>
      </Toast>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Card Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alerts.messages.length > 0 && (
            <div
              className={`alert alert-${alerts.type} login-alert w-100`}
              role="alert"
            >
              {alerts.messages.map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </div>
          )}
          <div className="d-flex justify-content-between">
            <p className="text-primary mr-2">Linked Account:</p>
            <p>{request && request.account.account_number}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-primary mr-2">POS Type</p>
            <p>{request && request.pos_type.name}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-primary mr-2">Installmental Amount:</p>
            <p>{`₦${request?.installment_amount}`}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-primary mr-2">Repayment Duration:</p>
            <p style={{ textTransform: "capitalize" }}>
              {request?.repayment_duration
                ? posData?.repayment_duration[request.repayment_duration]
                : null}
            </p>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                pos_payment_method: request ? request.pos_payment_method : "",
                installment_amount:
                  request && request.installment_amount
                    ? request.installment_amount.split(".")[0]
                    : "",
                paper_display:
                  request && request?.paper_display_fee ? "true" : "false",
                stand: request && request?.stand_fee ? "true" : "false",
                delivery_option: request ? request.delivery_option : "",
                existing_address: "",
                address_line_one: !request
                  ? ""
                  : request.address_line_one
                  ? request.address_line_one
                  : "",
                address_line_two: !request
                  ? ""
                  : request.address_line_two
                  ? request.address_line_two
                  : "",
                country: "",
                referrer_code: request?.referrer_code
                  ? request.referrer_code
                  : "",
                state: "",
                city: !request ? "" : request.city ? request.city : "",
                pin: "",
                caution_fee: "",
              }}
              validationSchema={validateEditRequest}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <FormikForm>
                  {formik.values.pos_payment_method === "installment" ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <p className="text-primary mr-2">
                          Installmental Amount:
                        </p>
                        <p>
                          {!!request?.installment_amount
                            ? `₦${request?.installment_amount}`
                            : "None"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="text-primary mr-2">Repayment Duration:</p>
                        <p style={{ textTransform: "capitalize" }}>
                          {!!request?.repayment_duration
                            ? posData?.repayment_duration[
                                request.repayment_duration
                              ]
                            : "None"}
                        </p>
                      </div>
                    </>
                  ) : null}

                  <div>
                    <div>
                      <InputDropDown
                        label="Payment Method"
                        noValue="-- Choose Payment Method --"
                        name="pos_payment_method"
                        options={[
                          "Installment",
                          "One-off",
                          "C_Caution_Deposit",
                        ]}
                        values={["installment", "one-off", "c-caution-deposit"]}
                        style={cardFormStyles}
                      />

                      {formik.values.pos_payment_method ===
                        "c-caution-deposit" && (
                        <div>
                          <InputDropDown
                            label="Caution Deposit"
                            noValue="-- Select Option --"
                            name="caution_fee"
                            options={Object.keys(posData.caution_fee).map(
                              (amount) => posData.caution_fee[amount]
                            )}
                            values={Object.keys(posData.caution_fee)}
                            style={cardFormStyles}
                          />
                        </div>
                      )}

                      {formik.values.pos_payment_method === "installment" && (
                        <div>
                          <InputDropDown
                            label="Installment Amount"
                            noValue="-- Select Option --"
                            name="installment_amount"
                            options={Object.keys(
                              posData.installment_amount_options
                            ).map((amount) => `₦${thousandSeparator(amount)}`)}
                            values={Object.keys(
                              posData.installment_amount_options
                            )}
                            style={cardFormStyles}
                          />
                        </div>
                      )}
                      <InputDropDown
                        label="Delivery Method"
                        noValue="-- Choose Delivery Method --"
                        name="delivery_option"
                        options={[/*"Pickup at our Office",*/ "Door Delivery"]}
                        values={[/*"pickup", */ "dispatch"]}
                        style={cardFormStyles}
                      />

                      {/* Only show when pickup option is chosen */}

                      {formik.values.delivery_option === "pickup" && (
                        <>
                          <div>
                            <Form.Group controlId="pickup-address">
                              <Form.Label className="sendmoney-select_acc-label">
                                Pick Up Address
                              </Form.Label>
                              <Form.Control
                                disabled
                                as="textarea"
                                rows={3}
                                value="No.3 John Great Court, Chevron Drive, Lekki Expressway, Lekki, Lagos"
                                className="sendmoney-textarea"
                                style={cardFormStyles}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}

                      {/* Make API call and post request if pick up option is chosen */}

                      {/* if Door Delivery(Dispatch) is chosen, show "use existing address option" */}

                      {request &&
                        formik.values.delivery_option === "dispatch" &&
                        request.delivery_option !== "dispatch" && (
                          <InputDropDown
                            label="Use Existing address"
                            noValue="-- Select Option --"
                            name="existing_address"
                            options={["Yes", "No"]}
                            values={[true, false]}
                            style={cardFormStyles}
                          />
                        )}

                      {/* if useExistingAddress is true, make API call and post request" */}

                      {/* if useExistingAddress is false, show Address Line Fields, Country, State and City options" */}

                      {formik.values.existing_address === "false" && (
                        <>
                          <InputField
                            label="Address Line 1"
                            name="address_line_one"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputField
                            label="Address Line 2"
                            name="address_line_two"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputDropDown
                            label="Country"
                            noValue="-- Select Country --"
                            name="country"
                            options={["Nigeria"]}
                            values={["163"]}
                            style={cardFormStyles}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "country",
                                event.currentTarget.value
                              );
                              formik.setFieldValue("state", "");
                              getStates(event.currentTarget.value);
                            }}
                          />
                          <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label className="sendmoney-select_acc-label">
                              State
                            </Form.Label>
                            <p className={loadingStates ? "" : "d-none"}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </p>
                            <Form.Control
                              as="select"
                              name="state"
                              className={
                                loadingStates ? "d-none" : "select-input"
                              }
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "state",
                                  event.currentTarget.value
                                );
                              }}
                            >
                              <option value="">-- Select State --</option>
                              {states &&
                                states.map((state, index) => (
                                  <option key={index} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                          <InputField
                            label="City"
                            name="city"
                            type="text"
                            style={cardFormStyles}
                          />
                        </>
                      )}

                      <div
                        className={
                          formik.values.existing_address === "false"
                            ? ""
                            : "d-none"
                        }
                      ></div>
                    </div>
                    <InputDropDown
                      label="Stand - ₦5000"
                      noValue="-- Select --"
                      name="stand"
                      options={["Yes", "No"]}
                      values={[true, false]}
                      style={cardFormStyles}
                    />
                    <InputDropDown
                      label="Paper Display - ₦500"
                      noValue="-- Select --"
                      name="paper_display"
                      options={["Yes", "No"]}
                      values={[true, false]}
                      style={cardFormStyles}
                    />
                    <InputField
                      label="Transaction pin"
                      name="pin"
                      type="password"
                      style={cardFormStyles}
                      maxLength="4"
                    />
                    <InputField
                      label="Referrer Code (Optional)"
                      name="referrer_code"
                      type="text"
                      style={cardFormStyles}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary mt-4 mb-2 w-100"
                      disabled={loading}
                    >
                      {loading ? "Processing..." : "Change for me"}
                    </button>
                  </div>
                  <button
                    className="btn btn-outline-danger w-100"
                    onClick={handleClose}
                    disabled={loading}
                  >
                    Cancel
                  </button>

                  <Modal show={showCautionModal} onHide={handleCloseCaution}>
                    <Modal.Header closeButton>
                      <Modal.Title>Caution Payment Terms</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      POS Must Be Put to Judicious use as Shanono By Shanono
                      Reserves the right to retrieve any unproductive POS
                      terminal and caution fee of 12,500 will be returned (based
                      on the working condition of the POS terminal)
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-primary p-2"
                        onClick={handleAcceptCautionTerms}
                      >
                        Accept
                      </button>
                      <button
                        className="btn btn-danger p-2"
                        onClick={handleRejectCautionTerms}
                      >
                        Reject
                      </button>
                    </Modal.Footer>
                  </Modal>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditReqModal;
