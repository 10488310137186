import { Form, Formik } from "formik";
import React, { useState } from "react";
import InputField from "../../../components/InputField";
import * as Yup from "yup";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert";
import { Alert } from "react-bootstrap";
import { errorHandler } from "../../../utils/errorHandler";

const VerifyEmailForm2 = ({ email }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    axios
      .patch(`${process.env.REACT_APP_API}/user/email`, values)
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "Your email has been verified successfully";
        const icon = "success";
        fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  const validate = Yup.object({
    email: Yup.string().email().required("Email is required"),
    token: Yup.string().required("Token is required"),
  });

  return (
    <div className="edit-profile">
      <Alert variant="primary" className="change-password_alert">
        Enter the token sent to your email
      </Alert>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            email,
            token: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputField
                label="Email Address"
                name="email"
                type="email"
                style={{ width: "100%" }}
                disabled
              />
              <InputField
                label="Token"
                name="token"
                type="text"
                style={{ width: "100%" }}
              />

              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={loading}
              >
                {loading ? "Processing..." : "Verify Email"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VerifyEmailForm2;
