import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import InputField from "../../../components/InputField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Unathorized from "../Unathorized";
import { errorHandler } from "../../../utils/errorHandler";

const CreatePin = ({ history }) => {
  const user = useSelector((state) => state.user);
  
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/security/pin`, values)
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "Your pin has been created successfully";
        const icon = "success";
        fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  const validate = Yup.object({
    pin: Yup.string()
      .min(4, "Pin must be at least 4 digits")
      .max(4, "Pin cannot be more than 4 digits")
      .required("Pin is required"),
    pin_confirmation: Yup.string()
      .oneOf([Yup.ref("pin"), null], "pins must match")
      .required("Please confirm pin"),
  });

  if (user.has_transaction_pin) {
    return <Unathorized message="You already have a transaction pin" />;
  }

  return (
    <div className="edit-profile">
      <h2 className="settings-small-header">Change pin</h2>
      <Alert variant="primary" className="change-pin_alert">
        You cannot create a new pin if you already have one. Click{" "}
        <Link to="/settings/change-pin">here</Link> to change your pin.
      </Alert>
      <div>
        <Formik
          initialValues={{
            pin: "",
            pin_confirmation: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputField
                label="Enter Pin"
                name="pin"
                type="password"
                maxLength="4"
                style={{ width: "100%" }}
              />
              <InputField
                label="Confirm pin"
                name="pin_confirmation"
                type="password"
                maxLength="4"
                style={{ width: "100%" }}
              />

              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Create Pin"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreatePin;
