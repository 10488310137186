import React from "react";
import { ChartDonut, ChartLabel } from "@patternfly/react-charts";
import { thousandSeparator } from "../../../../utils/thousandSeparator";

const TotalDonutChart = ({
  totalBusinessAmount,
  totalSavingsAmount,
  totalInvestmentsAmount,
}) => {
  let total =
    +totalBusinessAmount + +totalSavingsAmount + +totalInvestmentsAmount;
  total = Math.round(total * 100) / 100;
  return (
    <div className="donut-container">
      <div style={{ height: "200px", width: "200px" }}>
        <ChartDonut
          ariaDesc="A donut chart on how much you have in your various accounts"
          ariaTitle="Account Breakdown"
          constrainToVisibleArea={true}
          data={[
            { x: "Savings", y: totalSavingsAmount },
            { x: "Business", y: totalBusinessAmount },
            { x: "Investment", y: totalInvestmentsAmount },
          ]}
          subTitle={"₦" + thousandSeparator(total)}
          title="Total"
          titleComponent={
            <ChartLabel style={{ fontSize: "12px", lineHeight: "12px" }} />
          }
          subTitleComponent={
            <ChartLabel
              style={{
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "bold",
              }}
            />
          }
          colorScale={["#008080", "#DB261B", "#658383"]}
          height={190}
          width={190}
          padAngle={0}
          innerRadius={60}
        />
      </div>
    </div>
  );
};

export default TotalDonutChart;
