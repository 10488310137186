import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import axios from "axios";
import { fireAlert } from "../../../../utils/Alert";
import { errorHandler } from "../../../../utils/errorHandler";
import LoadingOverLay from "../../../../components/LoadingOverLay";

const ChooseBank = ({ transaction, setTransaction, loading }) => {
  const accounts = useSelector((state) => state.accounts.accounts);

  const [availableBanks, setAvailableBanks] = useState([]);

  const [loadingBanks, setLoadingBanks] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    if (transaction.account_id) {
      setSelectedBank("");
      setLoadingBanks(true);
      setTransaction({
        ...transaction,
        beneficiary_bank_name: "",
        beneficiary_bank_code: "",
        beneficiary_account_name: "",
      });
      const accountNumber =
        accounts.filter((account) => account.id === transaction.account_id)[0]
          ?.account_number ?? "";

      axios
        .get(
          `${process.env.REACT_APP_API}/banks/nip?source=${accountNumber}`
        )
        .then((res) => {
          const { banks } = res.data.data;
          setAvailableBanks(() =>
            banks.map((bank) => ({
              value: bank.nip_bank_code,
              label: bank.bank_name,
            }))
          );
          setLoadingBanks(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingBanks(false);
          let html = errorHandler(err.response);
          const title = "Error";
          const icon = "error";
          fireAlert(title, html, icon);
        });
    }

    return () => {
      setAvailableBanks([]);
    };
  }, [accounts, setTransaction, transaction, transaction.account_id]);

  const handleChange = (selectedBank) => {
    setSelectedBank({ selectedBank });
    // set transaction
    setTransaction({
      ...transaction,
      beneficiary_bank_name: selectedBank.label,
      beneficiary_bank_code: selectedBank.value,
    });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      // provided adds react-select default styles
      ...provided,
      background: "#f3f5fc",
      height: "43px",
      outline: state.isFocused ? "1px solid #01ffff !important" : "",
      border: state.isFocused ? "1px solid #01ffff !important" : "",
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 128 128 / 25%)" : "",
    }),
    singleValue: (provided, state) => {
      return { ...provided };
    },
  };

  if (transaction.channel === "internal") {
    return null;
  }

  return (
    <>
      <LoadingOverLay loading={loadingBanks} />
      <div>
        <Form.Group controlId="exampleForm.ControlSelect2">
          <Form.Label className="sendmoney-select_acc-label">
            Beneficiary Bank
          </Form.Label>
          <Select
            value={selectedBank ? selectedBank.label : ""}
            onChange={handleChange}
            options={availableBanks}
            styles={customStyles}
            isDisabled={loading}
          />
        </Form.Group>
      </div>
    </>
  );
};

export default ChooseBank;
