import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getInterestFreeRolloverTypes } from "../../../utils/getInterestFreeRollOverTypes";

const EditModal = ({
  showModal,
  setShowModal,
  investment,
  setInvestment,
  fetch,
  setFetch,
}) => {
  const rolloverTypes = useSelector((state) => state.investments.rolloverTypes);
  const investmentTypes = useSelector(
    (state) => state.investments.investmentTypes
  );

  const [isInterestFree, setIsInterestFree] = useState(false);

  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);
  const [rolloverId, setRolloverId] = useState("");

  useEffect(() => {
    if (!!investment?.type_id && !!investmentTypes) {
      const investmentType = investmentTypes?.find(
        (investmentType) => investmentType?.id === investment?.type_id
      );
      setIsInterestFree(investmentType?.name?.toLowerCase() === "msa fund");
    }
  }, [investmentTypes, investment?.type_id]);

  useEffect(() => {
    if (investment.rollover) {
      setRolloverId(investment.rollover.id);
    } else {
      setRolloverId("");
    }
  }, [investment]);

  const handleClose = () => {
    setShowModal(false);
    setInvestment({ id: "", title: "", amount: "", rollover: "" });
    setAlerts({ messages: [], type: "" });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    setAlerts({ messages: [], type: "" });
    const { title } = investment;
    if (!title) {
      setLoading(false);
      return setAlerts({ messages: ["Title is required"], type: "danger" });
    }

    const update = {
      title,
      rollover: rolloverId ? true : false,
      rollover_type: rolloverId,
    };
    axios
      .patch(
        `${process.env.REACT_APP_API}/investments/${investment.id}`,
        update
      )
      .then((res) => {
        setFetch(!fetch);
        setAlerts({
          messages: ["Your investment has been updated successfully"],
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Investment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alerts.messages.length > 0 && (
          <div
            className={`alert alert-${alerts.type} login-alert w-100`}
            role="alert"
          >
            {alerts.messages.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </div>
        )}
        <div>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={investment.title}
              onChange={(e) =>
                setInvestment({ ...investment, title: e.target.value })
              }
              className="reg-input w-100"
            />
          </Form.Group>
        </div>
        <div>
          <Form.Group controlId="select-acc">
            <Form.Label className="sendmoney-select_acc-label">
              Rollover Type
            </Form.Label>
            <Form.Control
              as="select"
              name="source_account"
              value={rolloverId}
              onChange={(e) => setRolloverId(e.target.value)}
              className="select-input sendmoney1-select_acc mb-n2"
            >
              <option value="">No Rollover</option>

              {isInterestFree
                ? rolloverTypes
                    ?.filter((type) =>
                      getInterestFreeRolloverTypes(
                        type?.name,
                        investment.type_id,
                        investmentTypes
                      )
                    )
                    .map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.name}
                      </option>
                    ))
                : rolloverTypes?.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.name}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-light"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? "Processing..." : "Update Investment"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
