import axios from "axios";

export function requestGetInvestmentTypes() {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API}/investment-types`,
  });
}

export function requestGetRolloverTypes() {
  return axios.request({
    method: "get",
    url: `${process.env.REACT_APP_API}/rollover-types`,
  });
}
