import { call, put } from "redux-saga/effects";
import { setUser } from "../../ducks/user";
import { requestGetUser } from "../request/user";

export function* handleGetUser(action) {
  try {
    // make the get request
    const response = yield call(requestGetUser);
    const { data } = response;
    // dispatch to redux
    yield put(setUser(data.data.user));
  } catch (error) {
    console.log(error);
  }
}
