import { call, put } from "redux-saga/effects";
import { setProviders } from "../../ducks/providers";
import { requestGetProviders } from "../request/providers";

export function* handleGetProviders(action) {
  try {
    // make the get request
    const response = yield call(requestGetProviders);
    const { data } = response;
    // dispatch to redux
    yield put(setProviders(data.data.providers));
  } catch (error) {
    console.log(error);
  }
}
