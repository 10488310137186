import axios from "axios";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const DeleteReqModal = ({
  showModal,
  setShowModal,
  request,
  fetch,
  setFetch,
}) => {
  const accounts = useSelector((state) => state.accounts.accounts);

  const [pin, setPin] = useState(null);
  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setAlerts({ messages: [], type: "" });
  };

  const handleClick = (e) => {
    const body = {pin}
    e.preventDefault();
    setLoading(true);
    if (!pin) {
      setLoading(false);
      return setAlerts({ messages: ["Pin is required"], type: "danger" });
    }
    axios
      .delete(`${process.env.REACT_APP_API}/pos-requests/${request.id}`, {data:{pin}})
      .then((res) => {
        setPin("")
        setFetch(!fetch);
        setAlerts({
          messages: ["POS Request deleted successfully"],
          type: "success",
        });
        setLoading(false);
        setPin(null);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Card Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alerts.messages.length > 0 && (
          <div
            className={`alert alert-${alerts.type} login-alert w-100`}
            role="alert"
          >
            {alerts.messages.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <p className="text-primary mr-2">Linked Account:</p>
          <p>{request && request.account.account_number}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="text-primary mr-2">Card Type</p>
          <p>{request && request.pos_type.name}</p>
        </div>
        <div>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Transaction pin</Form.Label>
            <Form.Control
              type="password"
              maxLength="4"
              onChange={(e) => setPin(e.target.value)}
              value={pin}
              className="reg-input w-100"
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={handleClose}
          disabled={loading}
        >
          Back
        </button>
        <button
          className="btn btn-danger"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? "Processing..." : "Cancel Request"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteReqModal;
