import axios from "axios";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Cleave from "cleave.js/react";
import { useSelector } from "react-redux";
import { thousandSeparator } from "../../../utils/thousandSeparator";

const TopUpModal = ({
  showModal,
  setShowModal,
  investment,
  setInvestment,
  fetch,
  setFetch,
}) => {
  const accounts = useSelector((state) => state.accounts.accounts);

  const [state, setState] = useState({
    source_account: "",
    amount: "",
    pin: "",
  });

  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setInvestment({ id: "", title: "", amount: "", rollover: "" });
    setState({
      source_account: "",
      amount: "",
      pin: "",
    });
    setAlerts({ messages: [], type: "" });
  };

  const validateTopup = () => {
    const result = [];
    if (!state.source_account) {
      result.push("Please select a source account");
    }
    if (!state.amount) {
      result.push("Please enter amount");
    }
    if (!state.pin) {
      result.push("Transaction pin is required");
    }
    return result;
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateTopup().length > 0) {
      setLoading(false);
      return setAlerts({ messages: [...validateTopup()], type: "danger" });
    }
    axios
      .post(
        `${process.env.REACT_APP_API}/investments/${investment.id}/top-up`,
        state
      )
      .then((res) => {
        setFetch(!fetch);
        setAlerts({
          messages: ["Your top up was successful"],
          type: "success",
        });
        setLoading(false);
        setState({
          source_account: "",
          amount: "",
          pin: "",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Top Up Investment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alerts.messages.length > 0 && (
          <div
            className={`alert alert-${alerts.type} login-alert w-100`}
            role="alert"
          >
            {alerts.messages.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <p className="text-primary mr-2">Title:</p>
          <p>{investment && investment.title}</p>
        </div>
        <div className="d-flex justify-content-between">
          <p className="text-primary mr-2">Current Investment Amount:</p>
          <p>₦{investment && thousandSeparator(investment.amount)}</p>
        </div>
        <div>
          <Form.Group controlId="select-acc">
            <Form.Label className="sendmoney-select_acc-label">
              Source Account
            </Form.Label>
            <Form.Control
              as="select"
              name="source_account"
              value={state.source_account}
              onChange={(e) =>
                setState({ ...state, source_account: e.target.value })
              }
              className="select-input sendmoney1-select_acc mb-n2"
            >
              <option value="">Select</option>
              {accounts &&
                accounts.map((account, index) => (
                  <option key={index} value={account.id}>
                    {account.account_name} - {account.type.name} - &#8358;
                    {thousandSeparator(account.available_balance)}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <p className="sendmoney-select_acc-label">Amount</p>
          <Cleave
            options={{
              numeral: true,
              numeralThousandsGroupStyle: "thousand",
              prefix: "₦ ",
              rawValueTrimPrefix: true,
            }}
            className="select-input"
            id="amount-input"
            name="amount"
            value={state.amount}
            onChange={(e) => setState({ ...state, amount: e.target.rawValue })}
          />
        </div>
        <div>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Transaction Pin</Form.Label>
            <Form.Control
              type="password"
              placeholder="****"
              maxLength="4"
              value={state.pin}
              onChange={(e) => setState({ ...state, pin: e.target.value })}
              className="reg-input w-100"
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-light"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? "Processing..." : "Top up"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TopUpModal;
