import React, { useState } from "react";
import "./Announcement.scss";
import Invest_img from "../../../assets/InvestCard-img.png";
import pos_img from "../../../assets/pos.png";
import { Link, withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Facebook_logo from "../../../assets/facebook-logo.png";
import Twitter_logo from "../../../assets/twitter-logo.png";
import Instagram_logo from "../../../assets/instagram-logo.png";
import LinkedIn_logo from "../../../assets/linkedin-logo.png";
import Cards_img from "../../../assets/cards-dash-modal.png";
import subscription from "../../../assets/subscription.png";
import phoneBills from "../../../assets/data.png";
import utilityBill from "../../../assets/utilityBill.png";
import cableBill from "../../../assets/cableBill.png";
import airtime from "../../../assets/airtime.png";

const quickmenu = [
  {
    label: "Utility",
    icon: utilityBill,
    screen: "/utility-bills",
  },
  {
    label: "TV",
    icon: cableBill,
    screen: "/tv-subscription",
  },
  {
    label: "Airtime",
    icon: airtime,
    screen: "/airtime-payment",
  },
  {
    label: "Buy Data",
    icon: phoneBills,
    screen: "/postpaid-bills",
  },
  {
    label: "Internet",
    icon: subscription,
    screen: "/internet-bills",
  },
];

const MenuCard = ({ item }) => {
  return (
    <div>
      <div className="menuContainer  text-center">
        <Link
          className="text-center"
          to={item.screen}
          style={{ textDecoration: "none" }}
        >
          <img src={item.icon} style={{}} />
        </Link>
      </div>
      <p className="title mt-3 text-center">{item.label}</p>
    </div>
  );
};

const Announcement = ({ history, home }) => {
  // controls modal for social media links
  const [showSocial, setShowSocial] = useState(false);
  // controls modal for apply for cards from /home
  const [showCard, setShowCard] = useState(false);

  return (
    <>
      {/* modal for social media links */}
      <Modal
        show={showSocial}
        onHide={() => setShowSocial(!showSocial)}
        centered
      >
        <Modal.Body className="text-center p-5">
          <p>Follow us on:</p>
          <div className="d-flex justify-content-between">
            <a
              href="https://facebook.com/cloudbankng"
              target="blank"
              rel="noopener noreferrer"
              className=""
            >
              <img src={Facebook_logo} alt="facebook" className="img-fluid" />
            </a>
            <a
              href="https://twitter.com/cloudbankng"
              target="blank"
              rel="noopener noreferrer"
              className=""
            >
              <img src={Twitter_logo} alt="twitter" className="img-fluid" />
            </a>
            <a
              href="https://instagram.com/cloudbankng"
              target="blank"
              rel="noopener noreferrer"
              className=""
            >
              <img src={Instagram_logo} alt="instagram" className="img-fluid" />
            </a>
            <a
              href="https://linkedin.com/company/cloudbank"
              target="blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedIn_logo} alt="linkedin" className="img-fluid" />
            </a>
          </div>
        </Modal.Body>
      </Modal>
      {/* modal for apply for card */}
      <Modal
        show={showCard}
        onHide={() => setShowCard(!showCard)}
        size="lg"
        centered
      >
        <Modal.Body className="text-center p-5">
          <div>
            <img src={Cards_img} alt="cards" className="img-fluid" />
          </div>
          <h2
            style={{ fontSize: "24px", lineHeight: "28px" }}
            className="font-weight-bold"
          >
            Mastercard Request
          </h2>
          <p style={{ lineHeight: "19px", color: "#828282" }}>
            Get access to seamless online payments by pre-ordering the Shanono
            wallet MasterCard
          </p>
          <button
            className="btn btn-primary d-block m-auto p-2"
            onClick={() => history.push("/cards")}
          >
            Yes, I'd like to pre-order
          </button>
          <p
            className="btn"
            style={{ fontSize: "14px", color: "#828282" }}
            onClick={() => setShowCard(!showCard)}
          >
            Not yet. Maybe later
          </p>
        </Modal.Body>
      </Modal>

      <div className="mx-2 announcment-conatainer">
        <p className="announcment-header">Announcement</p>
        {+home.profileCompletion !== 100 && (
          <>
            <div className="announcment">
              <div className="small-circle"></div>
              <p className="mt-3">
                <Link
                  to="/settings/update-profile"
                  className="text-dark text-decoration-none"
                >
                  Finish your KYC
                </Link>
              </p>
            </div>
            <div className="optional mb-3">
              <div></div>
              <p>Optional</p>
              <div></div>
            </div>
          </>
        )}
        <div className="announcment" onClick={() => setShowSocial(!showSocial)}>
          <div className="small-circle"></div>
          <p className="mt-3">Follow us on our social media platforms</p>
        </div>
        <div className="announcment" onClick={() => setShowCard(!showCard)}>
          <div className="small-circle"></div>
          <p className="mt-3">Request for a Shanono mastercard</p>
        </div>
        <div>
          <p className="px-md-4">Quick Actions</p>
          <div className="d-flex  justify-content-around justify-content-md-between px-md-4  flex-wrap  lg-flex-nowrap">
            {quickmenu.map((item, index) => (
              <MenuCard item={item} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-around flex-wrap announce-cards">
        <div
          className="p-3 d-flex justify-content-around align-items-center invest-card"
          onClick={() => history.push("/investment")}
        >
          <div>
            <h4 className="announce-cards_header">Invest with Shanono</h4>
            <p>Invest smartly. Earn up to 18% return on investment per annum</p>
          </div>
          <img src={Invest_img} alt="invest" />
        </div>
        <div
          className="p-3 d-flex justify-content-around align-items-center dashboard-loan-card"
          // onClick={() => history.push("/loan")}
          style={{ cursor: "unset" }}
        >
          <div>
            <h4 className="announce-cards_header">Apply for POS</h4>
            <p>Boost your business with Shanono</p>
          </div>
          <img src={pos_img} alt="pos" style={{ width: "100px" }} />
        </div>
      </div>
    </>
  );
};

export default withRouter(Announcement);
