import React, { useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../components/InputField";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { fireAlert } from "../../../utils/Alert";

const RegisterForm2 = ({ setStep, account, setAccount }) => {
  //const [date, setDate] = useState(null);

  const handleSubmit = (values) => {
    // if (!date) {
    //   const title = "Error";
    //   const html = "Please enter your date of birth";
    //   const icon = "error";
    //   return fireAlert(title, html, icon);
    // }
    let neededValues = {
      ...values,
      // date_of_birth: moment(date).format("YYYY-MM-DD"),
    };
    delete neededValues.confirmPassword;
    setAccount({ ...account, ...neededValues });
    setStep(3);
  };

  const validate = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email_token: Yup.string().when(
      "telephone_token",
      (telephone_token, schema) => {
        if (!telephone_token)
          return schema.required("Email or Phone Token is required");
        return schema;
      }
    ),
    telephone_token: Yup.string(),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm password"),
    // bvn: Yup.string()
    //   .required("Please enter a valid BVN")
    //   .test(
    //     "Is positive?",
    //     "Please enter positive numbers only",
    //     (value) => value > 0
    //   )
    //   .test("len", "The BVN is not correct", (val) => val && val.length === 11),
    referrer_code: Yup.string(),
  });

  return (
    <>
      <div className="reg-msg mt-2 mb-3">
        <h2>
          Almost there
          <br />
          Tell us about you
        </h2>
      </div>
      <div className="text-left form-container">
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email_token: "",
            telephone_token: "",
            password: "",
            confirmPassword: "",
            //bvn: "",
            referrer_code: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <div className="d-flex flex-row reg-input-groups">
                <InputField
                  label="FIRST NAME"
                  name="first_name"
                  type="text"
                  placeholder="first name"
                />
                <InputField
                  label="LAST NAME"
                  name="last_name"
                  type="text"
                  placeholder="last name"
                />
              </div>
              <small className="d-block text-center border-bottom">
                Email or Phone Token is required
              </small>
              <div className="d-flex flex-row border-bottom reg-input-groups">
                <InputField
                  label="EMAIL TOKEN"
                  name="email_token"
                  type="text"
                  placeholder="check your email for email token"
                  inputMode="numeric"
                />
                <InputField
                  label="PHONE TOKEN"
                  name="telephone_token"
                  type="text"
                  placeholder="check your SMS for phone token"
                  inputMode="numeric"
                />
              </div>
              {/* <div className="d-flex flex-row reg-input-groups">
                <InputField
                  label="BVN"
                  name="bvn"
                  type="text"
                  placeholder="12345678901"
                  maxLength={11}
                  inputMode="numeric"
                />
                <div className="align-self-center">
                  <p style={{ fontSize: "16px" }}>DATE OF BIRTH</p>
                  <DatePicker
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={date}
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => setDate(date)}
                    className="form-control reg-input mt-n2"
                  />
                </div>
              </div> */}
              <div className="d-flex flex-row reg-input-groups">
                <InputField
                  label="PASSWORD"
                  name="password"
                  type="password"
                  placeholder="********"
                />
                <InputField
                  label="CONFIRM PASSWORD"
                  name="confirmPassword"
                  type="password"
                  placeholder="********"
                />
              </div>
              <div className="d-flex flex-row reg-input-groups">
                <InputField
                  label="REFERENCE CODE (optional)"
                  name="referrer_code"
                  type="text"
                  placeholder="REFERAL CODE"
                />
              </div>
              <button type="submit" className="btn btn-primary mt-2 reg-btn">
                Create Account
              </button>
            </Form>
          )}
        </Formik>
        <p className="text-center py-4 reg-bottom-text">
          Already have an account?
          <span className="text-primary ml-1">
            <Link to="/login">Login</Link>
          </span>
        </p>
      </div>
    </>
  );
};

export default RegisterForm2;
