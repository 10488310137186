import React, { useState } from "react";
import InvestmentForm1 from "./investment_form1/InvestmentForm1";
import InvestmentForm2 from "./investment_form2/InvestmentForm2";

const InvestmentForm = (props) => {
  const { step, setStep, investmentTypes, rolloverTypes } = props;
  const [formValues, setFormValues] = useState({});

  return (
    <>
      <div className={step === 1 ? "" : "d-none"}>
        <InvestmentForm1
          setStep={setStep}
          setFormValues={setFormValues}
          investmentTypes={investmentTypes}
          rolloverTypes={rolloverTypes}
        />
      </div>
      <div className={step === 2 ? "" : "d-none"}>
        <InvestmentForm2
          formValues={formValues}
          investmentTypes={investmentTypes}
          setFormValues={setFormValues}
          rolloverTypes={rolloverTypes}
          setStep={setStep}
        />
      </div>
    </>
  );
};

export default InvestmentForm;
