export const GET_ACCOUNTS_TYPES = "get account types";
const SET_ACCOUNT_TYPES = "set account types";
const REMOVE_ACCOUNT_TYPES = "remove account types";

export const setAccountTypes = (accountTypes) => ({
  type: SET_ACCOUNT_TYPES,
  accountTypes,
});

export const getAccountTypes = () => ({ type: GET_ACCOUNTS_TYPES });

export const removeAccountTypes = () => ({ type: REMOVE_ACCOUNT_TYPES });

const initailState = {
  accountTypes: undefined,
};

const accountTypesReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_TYPES:
      return { ...state, accountTypes: [...action.accountTypes] };
    case REMOVE_ACCOUNT_TYPES:
      return { ...state, accountTypes: undefined };
    default:
      return state;
  }
};

export default accountTypesReducer;
