import React from "react";
import { Spinner } from "react-bootstrap";

const styles = {
  position: "fixed",
  top: "0",
  left: "0",
  height: "100vh",
  width: "100vw",
  backgroundColor: "rgba(0,0,0,0.1)",
};

const LoadingOverLay = ({ loading }) => {
  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={styles}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return null;
};

export default LoadingOverLay;
