import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import DayAndDate from "../../components/DayAndDate";
import AddPos from "./add_pos/AddPos";
import PosRequests from "./pos_requests/PosRequests";
const Pos = ({ history, location }) => {
  return (
    <>
      <Helmet>
        <title>POS - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-2 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">POS</h1>
          <DayAndDate />
          <div>
            <button
              className={
                location.pathname === "/pos/create"
                  ? "d-none"
                  : "btn btn-primary d-block ml-auto mr-2 mb-1"
              }
              onClick={() => history.push("/pos/create")}
            >
              Request a new POS
            </button>
          </div>
          <Switch>
            <Route exact path="/pos" component={PosRequests} />
            <Route exact path="/pos/create" component={AddPos} />
          </Switch>
        </main>
      </div>
    </>
  );
};

export default Pos;
