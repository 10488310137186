import React, { useState } from "react";
import DayAndDate from "../../components/DayAndDate";
import "./SendMoney.scss";
import SendMoney1 from "./sendmoney_forms/sendmoney1/SendMoney1";
import { Helmet } from "react-helmet-async";
import SendMoney2 from "./sendmoney_forms/sendmoney2/SendMoney2";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getBeneficiaries } from "../../redux/ducks/beneficiaries";
//import { getBanks } from "../../redux/ducks/banks";
import { formatAccNumber } from "../../utils/cardFieldFormatting";

const SendMoney = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    //load user beneficiaries
    dispatch(getBeneficiaries());
    //load banks
    //dispatch(getBanks());
  }, [dispatch]);

  // form steps
  const [step, setStep] = useState(1);
  
  const [beneficiaries, setBeneficiaries] = useState(null);

  const [transaction, setTransaction] = useState({
    source_account_number: "",
    amount: "",
    channel: "",
    beneficiary_bank_name: "",
    beneficiary_bank_code: "",
    beneficiary_account_number: "",
    beneficiary_account_name: "",
    description: "",
    remember: false,
    transaction_reference: "",
    pin: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "channel") {
      if (e.target.value === "internal") {
        return setTransaction({
          ...transaction,
          [e.target.name]: e.target.value,
          beneficiary_bank_name: "SHANONO MICROFINANCE BANK",
          beneficiary_bank_code: "000000",
        });
      }
    }
    if (e.target.name === "amount") {
      return setTransaction({
        ...transaction,
        [e.target.name]: e.target.rawValue,
      });
    }

    if(e.target.name === "beneficiary_account_number"){
      e.target.value = formatAccNumber(e.target.value);
    }
    return setTransaction({ ...transaction, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Helmet>
        <title>Send Money - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-2 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Transactions</h1>
          <DayAndDate />
          <div className="mt-5 d-flex flex-wrap justify-content-between sendmoney-formarea">
            <div className="sendmoney-left">
              <h2 className="text-primary sendmoney-bigheader">Send Money</h2>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                tellus felis, tincidunt at neque in, ornare interdum enim.
              </p> */}
            </div>
            <SendMoney1
              transaction={transaction}
              step={step}
              setStep={setStep}
              setTransaction={setTransaction}
              handleChange={handleChange}
              beneficiaries={beneficiaries}
              setBeneficiaries={setBeneficiaries}
            />
            <SendMoney2
              transaction={transaction}
              step={step}
              setStep={setStep}
              setTransaction={setTransaction}
              handleChange={handleChange}
              beneficiaries={beneficiaries}
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default SendMoney;
