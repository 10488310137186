import React, { useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { hours } from "../../../../../utils/hours";

const Schedule = ({ transaction, handleChange, setTransaction }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  const dateChange = (date, dateType) => {
    if (dateType === "start_date") {
      setStartDate(date);
      setTransaction({ ...transaction, start_date: formatDate(date) });
    } else {
      setEndDate(date);
      setTransaction({ ...transaction, end_date: formatDate(date) });
    }
  };

  return (
    <div>
      <Form.Group controlId="select-acc">
        <Form.Label className="sendmoney-select_acc-label"></Form.Label>
        <Form.Control
          as="select"
          name="schedule"
          value={transaction.schedule}
          onChange={handleChange}
          className="select-input sendmoney1-select_acc"
          // style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
        >
          <option value="">-- Choose Schedule --</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="bi_weekly">Bi-weekly (once every two weeks)</option>
          <option value="monthly">Monthly</option>
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="select-acc">
        <Form.Label className="sendmoney-select_acc-label"></Form.Label>
        <Form.Control
          as="select"
          name="hour_of_day"
          value={transaction.hour_of_day}
          onChange={handleChange}
          className="select-input sendmoney1-select_acc"
          // style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
        >
          <option value="">-- Time --</option>
          {hours.map((hour, index) => (
            <option key={index} value={hour.value}>
              {hour.time}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <div className="mt-n3 d-flex justify-content-between">
        <div className="w-100">
          <p className="sendmoney-select_acc-label">Start Date</p>
          <DatePicker
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            selected={startDate}
            placeholderText="DD/MM/YYYY"
            onChange={(date) => dateChange(date, "start_date")}
            className="select-input sendmoney1-select_acc form-control"
          />
        </div>
        <div className="w-100">
          <p className="sendmoney-select_acc-label">End Date</p>
          <DatePicker
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat="dd/MM/yyyy"
            minDate={startDate ? startDate : new Date()}
            selected={endDate}
            placeholderText="DD/MM/YYYY"
            onChange={(date) => dateChange(date, "end_date")}
            className="select-input sendmoney1-select_acc form-control"
          />
        </div>
      </div>
    </div>
  );
};

export default Schedule;
