import React from "react";
import { Helmet } from "react-helmet-async";
import DayAndDate from "../../components/DayAndDate";
import "./Airtime.scss";
import { Alert, Form } from "react-bootstrap";
import LoadingOverLay from "../../components/LoadingOverLay";
import axios from "axios";
import { useSelector } from "react-redux";
import { thousandSeparator } from "../../utils/thousandSeparator";
import { validateTvAndCablePayment } from "./paymentValidation";
import { useHistory } from "react-router-dom";
import { fireAlert } from "../../utils/Alert";
import { errorHandler } from "../../utils/errorHandler";
import { BsChevronLeft } from "react-icons/bs";

function TvSubscription() {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [billers, setBillers] = React.useState([]);
  const [loadBillers, setLoadBillers] = React.useState("");

  const [billersItem, setBillersItem] = React.useState([]);
  const [loadBillersItem, setLoadBillersItem] = React.useState("");
  const [name, setName] = React.useState("");

  const [errors, setErrors] = React.useState([]);

  const initialState = {
    sourceAccount: "",
    billerName: "",
    billerItem: "",
    customerId: "",
    customerMobile: "",
    amount: "",
    pin: "",
    biller: "",
  };
  const [formData, setFormData] = React.useState(initialState);

  const accounts = useSelector((state) => state.accounts.accounts);

  const handleChange = (prop) => (e) => {
    setFormData({
      ...formData,
      [prop]: e.target.value,
    });
  };

  const handlePayment = () => {
    formData.name = name;
    formData.amount = formData.billerItem
      ? JSON.parse(formData.billerItem)?.amount
      : "";

    if (validateTvAndCablePayment(formData).length > 0) {
      setErrors(validateTvAndCablePayment(formData));
      return setShowAlert(true);
    }

    const billerDetails =
      formData.billerItem.length > 0 ? JSON.parse(formData.billerItem) : "";
    let number = formData.customerMobile.substr(1);
    number = `+234${number}`;
    setLoading(true);
    const payload = {
      source_account_number: formData.sourceAccount,
      amount: billerDetails?.amount,
      // customer_mobile: number,
      payment_code: billerDetails?.payment_code,
      pin: formData.pin,
      category_id: billerDetails?.category_id,
      biller_name: billerDetails?.payment_item_name,
      payment_item_name: billerDetails?.payment_item_name,
      customer_id: formData.customerId,
    };
    let title, html, icon;
    axios
      .post(`${process.env.REACT_APP_API}/bills/payment`, payload)
      .then((res) => {
        setFormData({ ...initialState });
        setName("");
        if (res.data.data.bill.status == "success") {
          setShowAlert(false);
          title = "Subscription  Successful";
          html = `Your subscription of ₦ ${thousandSeparator(
            billerDetails?.amount
          )} to ${formData.customerId} was successful`;
          icon = "success";
          fireAlert(title, html, icon);
        } else if (res.data.data.bill.status == "pending") {
          setShowAlert(false);
          title = "Subscription  Pending";
          html = `Your subscription of ₦ ${thousandSeparator(
            billerDetails?.amount
          )} to ${formData.customerId} is pending`;
          icon = "info";
          fireAlert(title, html, icon);
        } else {
          title = "Subscription UnSuccessful";
          html = `Your subscription of ₦ ${thousandSeparator(
            billerDetails?.amount
          )} to ${formData.customerId} was unsuccessful`;
          icon = "info";
          fireAlert(title, html, icon);
        }
      })
      .catch((err) => {
        title = "Subscription Failed";
        html = "An error occured";
        icon = "error";
        fireAlert(title, html, icon);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTvBiller = () => {
    setLoadBillers("loading...");
    axios
      // .get(`${process.env.REACT_APP_API}/bills/categories`)
      .get(`${process.env.REACT_APP_API}/bills/categories/2`)
      .then((res) => {
        if (res.data.status == "success") {
          setErrors([]);
          setLoadBillers("");
          setBillers(res.data.data.billers);
          return setShowAlert(false);
        } else {
          setErrors(["Unable to fetch tv "]);
          return setShowAlert(true);
        }
      })
      .catch((err) => {
        setErrors(["An error occured"]);
        return setShowAlert(true);
      });
  };

  const getBillerItem = () => {
    setLoadBillersItem("Loading...");
    axios
      .get(
        `${process.env.REACT_APP_API}/bills/payment-item/${formData.billerName}`
      )
      .then((res) => {
        setLoadBillersItem("");
        if (res.data.status == "success") {
          setErrors([]);
          setBillersItem(res.data?.data?.payment_item);
          return setShowAlert(false);
        } else {
          setErrors(["Unable to fetch tv "]);
          return setShowAlert(true);
        }
      })
      .catch((err) => {
        setLoadBillersItem("");
        setErrors(["An error occured"]);
        return setShowAlert(true);
      });
  };

  const validateCustomer = () => {
    if (
      formData.customerId &&
      formData.billerName &&
      formData.billerItem.length > 0
    ) {
      setName("Validating Smart card number...");
      const payload = {
        customer_id: formData.customerId,
        payment_code: JSON.parse(formData.billerItem)?.payment_code,
      };
      axios
        .post(`${process.env.REACT_APP_API}/bills/validate-customer`, payload)
        .then((res) => {
          if (res.data.status == "success") {
            setName(res.data?.data?.customer?.full_name);
            setErrors([]);
            setShowAlert(false);
          } else {
            setName("");
            setErrors(["Unable to validate smart card no"]);
            return setShowAlert(true);
          }
        })
        .catch((err) => {
          setName("");
          setErrors(["An error occured validating  smart card no"]);
          return setShowAlert(true);
        });
    }
  };
  React.useEffect(() => {
    fetchTvBiller();
  }, []);

  React.useEffect(() => {
    if (formData.billerName) {
      getBillerItem();
    }
  }, [formData.billerName]);
  return (
    <div className="vh-100">
      <LoadingOverLay loading={loading} />
      <Helmet>
        <title>Bills payment - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Bills payment</h1>
          <DayAndDate />
          <button
            type="button"
            className="btn btn-link text-dark"
            onClick={() => history.goBack()}
          >
            <BsChevronLeft />
            Back
          </button>
          <div className="airtime-container px-4">
            <h3 className="text-primary sendmoney-header py-2">
              TV Subscription
            </h3>

            <Alert
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
              show={showAlert}
            >
              {errors.map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </Alert>
            <div className="">
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                The account to be debited
                <Form.Control
                  as="select"
                  name="accountNumber"
                  value={formData.sourceAccount}
                  onChange={handleChange("sourceAccount")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Account --</option>
                  {accounts &&
                    accounts.map((account, index) => (
                      <option
                        key={index}
                        value={account.account_number}
                        style={{}}
                      >
                        {account.account_name} - {account.type.name} - &#8358;
                        {thousandSeparator(account?.available_balance)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Tv Provider
                <Form.Control
                  as="select"
                  name="billerName"
                  value={formData.billerName}
                  onChange={handleChange("billerName")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">-- Choose Tv --</option>
                  {billers &&
                    billers.map((biller, index) => (
                      <option key={index} value={biller.biller_id} style={{}}>
                        {biller.biller_name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Tv Plan
                <Form.Control
                  as="select"
                  name="billerItem"
                  value={formData.billerItem}
                  onChange={handleChange("billerItem")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                >
                  <option value="">
                    {loadBillersItem ? loadBillersItem : "-- Choose Plan --"}
                  </option>
                  {billersItem &&
                    billersItem.map((biller, index) => (
                      <option
                        key={index}
                        // value={billersItem[index]}
                        value={JSON.stringify(biller)}
                        style={{}}
                      >
                        {`${biller.payment_item_name} - ₦ ${biller?.display_amount}`}
                      </option>
                    ))}
                </Form.Control>
                {formData.billerItem && (
                  <p className="text-success mt-n3">
                    Service charge: ₦
                    {JSON.parse(formData.billerItem).display_item_fee}
                  </p>
                )}
              </Form.Group>
              <Form.Group controlId="" className="pb-1">
                <Form.Label className="">{" Smart Card No"}</Form.Label>
                <Form.Control
                  as="input"
                  name="customerId"
                  autoComplete={"new-password"}
                  onBlur={() => validateCustomer()}
                  value={formData.customerId}
                  onChange={handleChange("customerId")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
                <p className="text-success mt-n3">{name}</p>
              </Form.Group>

              {/* <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Phone Number
                <Form.Control
                  as="input"
                  name="customerMobile"
                  value={formData.customerMobile}
                  onChange={handleChange("customerMobile")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group> */}
              <Form.Group controlId="" className="pb-1">
                <Form.Label className=""></Form.Label>
                Pin
                <Form.Control
                  type={"password"}
                  autoComplete="new-password"
                  maxLength={4}
                  as="input"
                  name="pin"
                  value={formData.pin}
                  onChange={handleChange("pin")}
                  className="select-input sendmoney1-select_acc"
                  style={{ background: "#e2f3f3", border: "1px solid #79b0b0" }}
                ></Form.Control>
              </Form.Group>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-5 btn-sendmoney1"
              onClick={handlePayment}
              disabled={name == "Loading..." || name == "" ? true : false}
            >
              Proceed
            </button>
          </div>
        </main>
      </div>
    </div>
  );
}

export default TvSubscription;
