export const GET_INVESTMENTTYPES = "get investment types";
const SET_INVESTMENTTYPES = "set investment types";
const REMOVE_INVESTMENTTYPES = "remove investment types";
export const GET_ROLLOVER_TYPES = "get rollover types";
const SET_ROLLOVER_TYPES = "set rollover types";
const REMOVE_ROLLOVER_TYPES = "remove rollover types";

// investment types
export const setInvestmentTypess = (investmentTypes) => ({
  type: SET_INVESTMENTTYPES,
  investmentTypes,
});

export const getInvestmentTypes = () => ({ type: GET_INVESTMENTTYPES });

export const removeInvestmentTypes = () => ({ type: REMOVE_INVESTMENTTYPES });

// for rollover types
export const setRolloverTypes = (rolloverTypes) => ({
  type: SET_ROLLOVER_TYPES,
  rolloverTypes,
});

export const getRolloverTypes = () => ({ type: GET_ROLLOVER_TYPES });

export const removeRolloverTypes = () => ({ type: REMOVE_ROLLOVER_TYPES });

const initailState = {
  investmentTypes: undefined,
  rolloverTypes: undefined,
};

const investmentsReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_INVESTMENTTYPES:
      return { ...state, investmentTypes: [...action.investmentTypes] };
    case REMOVE_INVESTMENTTYPES:
      return { ...state, investmentTypes: undefined };
    case SET_ROLLOVER_TYPES:
      return { ...state, rolloverTypes: [...action.rolloverTypes] };
    case REMOVE_ROLLOVER_TYPES:
      return { ...state, removeRolloverTypes: undefined };
    default:
      return state;
  }
};

export default investmentsReducer;
