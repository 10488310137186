import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import InputField from "../../components/InputField";
import { Link } from "react-router-dom";
import axios from "axios";
import { validateUsername } from "../../utils/validateUsername";
import Switch from "../../components/Switch";

// non bootstrap classes are in Login.scss
const ForgotPassForm = ({ setStep, setUsername, username }) => {
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ type: "", message: [] });
  const [check, setCheck] = useState(false);
  const [isNumber, setIsNumber] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    setAlert({ type: "", message: [] });
    setUsername(values.username);
    axios
      .post(`${process.env.REACT_APP_API}/tokens/reset/password`, values)
      .then((res) => {
        setLoading(false);
        setAlert({ type: "success", message: [res.data.data.message] });
        setTimeout(() => setStep(2), 1000);
      })
      .catch((err) => {
        setLoading(false);
        //add errors to error array
        const apiErrors = err.response.data.error;
        const errors = [];
        if (err.response.status === 422) {
          for (const property in apiErrors) {
            errors.push(apiErrors[property][0]);
          }
        } else {
          for (const property in apiErrors) {
            errors.push(apiErrors[property]);
          }
        }
        setAlert({
          type: "danger",
          message: [...errors],
        });
      });
  };

  let checkusername = /^\d{11}$/;
  const ifUsernameIsNumber = () => {
    if (username.match(checkusername)) {
      console.log("number");
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }
  };
  //  useEffect(() => {
  //    ifUsernameIsNumber()
  //   }, [username]);

  const validate = Yup.object({
    username: validateUsername(),
  });
  return (
    <div className="text-left form-container">
      {alert.message.length > 0 && (
        <div className={`alert alert-${alert.type} login-alert`} role="alert">
          {/* new Set removes duplicates */}
          {[...new Set(alert.message)].map((msg, index) => (
            <p key={index} style={{ fontSize: "14px" }}>
              {msg}
            </p>
          ))}
        </div>
      )}
      <Formik
        initialValues={{
          username: username,
          whatsapp: check,
        }}
        validationSchema={validate}
        onSubmit={(e) => handleSubmit(e)}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form>
            <InputField
              label="EMAIL OR PHONE NUMBER"
              value={formik.values.username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              name="username"
              type="text"
              placeholder="EMAIL OR PHONE NUMBER"
            />
            {/* {isNumber && ( 
            
           
            <Switch label="Get Token on Whatsapp" 
             
            onChange={() => setCheck(!check)}
            />
            
             )
            
            
            } */}

            <button
              type="submit"
              className="btn btn-primary mt-2 login-btn"
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Reset Password"}
            </button>
          </Form>
        )}
      </Formik>
      <p className="text-center py-4 reg-bottom-text">
        <span className="">
          <Link to="/login">Back to Login</Link>
        </span>
      </p>
    </div>
  );
};

export default ForgotPassForm;
