import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import InputFile from "../../../components/InputFile";

const UpdateCACModal = ({ showModal, setShowModal, account, setAccount }) => {
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState({ messages: [], type: "" });

  const handleClose = () => {
    setShowModal(false);
    setAccount(null);
    setLoading(false);
    setAlerts({ messages: [], type: "" });
  };

  const handleSubmit = (values) => {
    setLoading(true);

    const formData = new FormData();

    //this _method is what the backend wants
    formData.append("_method", "PATCH");

    formData.append("cac_document", values.cac_document);

    axios
      .post(
        `${process.env.REACT_APP_API}/accounts/${account.id}/cac-document`,
        formData
      )
      .then((res) => {
        setAlerts({
          messages: ["CAC document has been updated successfully"],
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  const validate = Yup.object({
    cac_document: Yup.mixed().required("Please choose a valid document"),
  });

  return account ? (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update CAC - {account.account_number}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          cac_document: "",
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Modal.Body>
              {alerts.messages.length > 0 && (
                <div
                  className={`alert alert-${alerts.type} login-alert w-100`}
                  role="alert"
                >
                  {alerts.messages.map((item, index) => (
                    <p key={index} style={{ fontSize: "14px" }}>
                      {item}
                    </p>
                  ))}
                </div>
              )}
              <InputFile
                label="Choose File"
                name="cac_document"
                onChange={(event) => {
                  formik.setFieldValue(
                    "cac_document",
                    event.currentTarget.files[0]
                  );
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-danger"
                type="reset"
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  ) : null;
};

export default UpdateCACModal;
