import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";


export const generateJPEGBlob = async (componentRef) => {
    const element = componentRef.current;
    const canvas = await html2canvas(element, { useCORS: true });
    const imageData = canvas.toDataURL('image/jpeg', 1.0); // Capture as JPEG

    // Convert base64 to Blob
    const byteString = atob(imageData.split(',')[1]);
    const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
};

export const generatePDFBlob = async (componentRef) => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);
    const imageData = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // Get the PDF as a Blob
    return pdf.output('blob');
};

export const onSharePDF = async (componentRef) => {
    try {
        if (navigator.share) {
            const pdfBlob = await generatePDFBlob(componentRef);
            const file = new File([pdfBlob], 'document.pdf', {
                type: 'application/pdf',
            });

            await navigator.share({
                title: 'Transaction Receipt',
                text: 'Here is a PDF document of the transaction receipt.',
                files: [file],
            });

            console.log('Document shared successfully');
        } else {
            alert('Web Share API is not supported in this browser.');
        }
    } catch (error) {
        console.error('Error sharing document:', error);
    }
};

export const onShareJPEG = async (componentRef) => {
    try {
        if (navigator.share) {
            const jpegBlob = await generateJPEGBlob(componentRef);
            const file = new File([jpegBlob], 'image.jpg', {
                type: 'image/jpeg',
            });

            await navigator.share({
                title: 'Transaction Receipt',
                text: 'Here is a JPEG image of the transaction receipt.',
                files: [file],
            });

            console.log('Image shared successfully');
        } else {
            alert('Web Share API is not supported in this browser.');
        }
    } catch (error) {
        console.error('Error sharing image:', error);
    }
};

export const ShareFileType = ({closeModal, shareJpeg, sharePdf}) => {
    return (
        <section style={{ backgroundColor: "white", maxWidth: "28rem", padding: "2rem", borderRadius: "0.5rem", fontSize: "1.2rem", textAlign: "center" }}>
            <p>
                Choose the file type you want to share
            </p>

            <button onClick={() => shareJpeg()} style={{ padding: "0.8rem", backgroundColor: "#348081", width: "100%", borderRadius: "2rem", color: "white", marginTop: "3rem", cursor: "pointer" }}> JPEG </button>
            <button onClick={() => sharePdf()} style={{ padding: "0.8rem", backgroundColor: "#348081", width: "100%", borderRadius: "2rem", color: "white", marginTop: "1rem", cursor: "pointer" }}> PDF </button>
            <div onClick={closeModal} style={{ padding: "0.8rem", backgroundColor: "red", width: "100%", borderRadius: "2rem", color: "white", marginTop: "3rem", cursor: "pointer" }}> Close </div>
        </section>
    )
}
