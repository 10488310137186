export const validateAirtimepurchase = (formData) => {
  const result = [];

  if (!formData.sourceAccount) {
    result.push("Please select an account");
  }

  if (!formData.amount) {
    result.push("Please enter amount");
  }

  if (!formData.phoneNumber) {
    result.push("Please enter a phone number");
  }

  if (!formData.network) {
    result.push("Please select a mobile network");
  }
  if (!formData.pin) {
    result.push("Please enter pin");
  }

  // if (!info.card_number) {
  //   result.push("Please enter card number");
  // }

  // if (!info.expiry_month) {
  //   result.push("Please enter card expiry date");
  // }

  // if (!info.cvv) {
  //   result.push("Please enter card cvv");
  // }

  return result;
};

export const validateDataPurchase = (info) => {
  const result = [];

  if (!info.source_account_number) {
    result.push("Please select an account");
  }

  if (!info.bundle) {
    result.push("Please select a bundle");
  }

  if (!info.phoneNumber) {
    result.push("Please enter a Phone Number");
  }

  if (!info.network) {
    result.push("Please select mobile network");
  }

  return result;
};

export const validateElectricityPayment = (info) => {
  const result = [];

  if (!info.source_account_number) {
    result.push("Please select an account");
  }

  if (!info.provider) {
    result.push("Please select a provider");
  }

  if (!info.meterNumber) {
    result.push("Please enter a meter number");
  }

  if (!info.amount) {
    result.push("Please enter amount");
  }

  return result;
};

export const validateTvAndCablePayment = (info) => {
  const result = [];

  if (!info.sourceAccount) {
    result.push("Please select an account");
  }

  if (!info.billerName) {
    result.push("Please select a provider");
  }

  if (!info.billerItem) {
    result.push("Please enter a plan");
  }
  if (!info.customerId) {
    result.push("Please enter customer id");
  }
  if (!info.name) {
    result.push("Unable to validate customer name");
  }

  if (!info.amount) {
    result.push("Please enter amount");
  }
  if (!info.pin) {
    result.push("Please enter pin");
  }
  return result;
};

export const validatePostpaidBills = (info) => {
  const result = [];

  if (!info.sourceAccount) {
    result.push("Please select an account");
  }

  if (!info.billerName) {
    result.push("Please select a provider");
  }

  if (!info.billerItem) {
    result.push("Please enter a plan");
  }
  if (!info.amount) {
    result.push("Please enter amount");
  }
  if (!info.pin) {
    result.push("Please enter pin");
  }
  return result;
};
