import React, { useState } from "react";
import VerifyPhoneForm1 from "./VerifyPhoneForm1";
import VerifyPhoneForm2 from "./VerifyPhoneForm2";
import "./VerifyPhone.scss";
import { useSelector } from "react-redux";
import Unathorized from "../Unathorized";

const VerifyPhone = () => {
  const [step, setStep] = useState(1);

  const user = useSelector((state) => state.user);
  const telephone = user.telephone;

  // if user already have a phone,use it
  const [phone, setPhone] = useState(telephone);

  if (user.telephone_verified_at) {
    return <Unathorized message="You already verified your telephone number" />;
  }

  return (
    <div className="edit-profile">
      <h2 className="settings-small-header">Verify Phone Number</h2>
      <div className={step === 1 ? "" : "d-none"}>
        <VerifyPhoneForm1
          setStep={setStep}
          phone={phone}
          setPhone={setPhone}
          telephone={telephone}
        />
      </div>
      <div className={step === 2 ? "" : "d-none"}>
        <VerifyPhoneForm2 phone={phone} />
      </div>
    </div>
  );
};

export default VerifyPhone;
