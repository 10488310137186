import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import DayAndDate from "../../components/DayAndDate";
import AllAccounts from "./all_accounts/AllAccounts";
import CreateAccount from "./create_account/CreateAccount";
import "./accounts.scss";

const Accounts = ({ location, history }) => {
  return (
    <>
      <Helmet>
        <title>Accounts - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-2 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Accounts</h1>
          <DayAndDate />
          <div>
            <button
              className={
                location.pathname === "/accounts/create"
                  ? "d-none"
                  : "btn btn-primary d-block ml-auto mr-2 mb-1"
              }
              onClick={() => history.push("/accounts/create")}
            >
              Create another account +
            </button>
          </div>
          <Switch>
            <Route exact path="/accounts" component={AllAccounts} />
            <Route exact path="/accounts/create" component={CreateAccount} />
          </Switch>
        </main>
      </div>
    </>
  );
};

export default Accounts;
