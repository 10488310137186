import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import DayAndDate from "../../components/DayAndDate";
import CardInProcess from "./CardInProcess";
import "./Cards.scss";

import AllCards from "./all_cards/AllCards";
import AddCardForm from "./add_card/AddCardForm";
import { Route, Switch } from "react-router-dom";
import CardRequests from "./card_requests/CardRequests";
import AddCardBox from "./add_card/AddCardBox";

const Cards = ({ history,location}) => {
  const [activePage, setActivePage] = useState("view-cards");
  const [numberOfCards, setNumberOfCards] = useState(0);

  // Ordered Cards Array Placeholder
  let orderedCards = [];

  for (let i = 0; i < numberOfCards; i++) {
    orderedCards.push(<CardInProcess key={i} />);
  }

  // Show Card in Transit Modal and go to card page with cards already added
  const handleCardFormSubmit = () => {
    setNumberOfCards(numberOfCards + 1);
  };

  return (
    <>
      <Helmet>
        <title>Cards - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 loan">
          {/* Set "Cards" Or "New Card" based on active page being rendered */}
          <h1 className="sendmoney-header">
            {activePage === "view-cards" && <>Cards</>}
            {activePage === "get-new-card" && <>New Card</>}
          </h1>
          <div className="mb-4">
            <DayAndDate />
          </div>
          <AddCardBox/>
          <Switch>
            {/* <Route
              exact
              path="/cards"
              render={(props) => (
                <AllCards {...props} orderedCards={orderedCards} />
              )}
            /> */}
            <Route exact path="/cards" component={CardRequests}/>
            <Route
              exact
              path="/cards/create"
              render={(props) => (
                <AddCardForm
                  {...props}
                  handleCardFormSubmit={handleCardFormSubmit}
                />
              )}
            />
          </Switch>
        </main>
      </div>
    </>
  );
};

export default Cards;
