import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import InputField from "../../../components/InputField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./ChangePassword.scss";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { logoutUser } from "../../../redux/configureStore";
import { errorHandler } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const logout = () => {
    delete axios.defaults.headers.common["Authorization"];
    Cookies.remove("token");
    axios.post(`${process.env.REACT_APP_API}/logout`);
    dispatch(logoutUser());
    console.log("You have been logged out successfully");
    toast.success("You have been logged out successfully.");
    history.replace("/login");
  };

  const validate = Yup.object({
    oldPassword: Yup.string().required("Please enter your current password"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm password"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    const password = {
      current_password: values.oldPassword,
      password: values.newPassword,
    };
    axios
      .patch(`${process.env.REACT_APP_API}/security/password`, password)
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "Your password has been changed successfully";
        const icon = "success";
        fireAlert(title, html, icon);
        //log user out
       logout();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };
  return (
    <div className="edit-profile">
      <h2 className="settings-small-header">Change Password</h2>
      <Alert variant="primary" className="change-password_alert">
        You will be logged out after a successful password change!
      </Alert>
      <div>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputField
                label="Old Password"
                name="oldPassword"
                type="password"
                placeholder=""
                style={{ width: "100%" }}
              />
              <InputField
                label="New Password"
                name="newPassword"
                type="password"
                placeholder=""
                style={{ width: "100%" }}
              />
              <InputField
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                placeholder=""
                style={{ width: "100%" }}
              />

              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={loading}
              >
                {loading ? "Processing..." : "Update Password"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;