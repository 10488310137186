import React from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import DayAndDate from "../../components/DayAndDate";
import ChangePassword from "./change_password/ChangePassword";
import ChangePin from "./change_pin/ChangePin";
import ProfilePicture from "./profile_picture/ProfilePicture";
import EditProfile from "./edit_profile/EditProfile";
import "./Settings.scss";
import CreatePin from "./create_pin/CreatePin";
import VerifyPhone from "./verify_phone/VerifyPhone";
import InviteFriends from "./invite_friends/InviteFriends";
import ResetPin from "./reset_pin/ResetPin";
import UpdateReferrer from "./update_referrer/UpdateReferrer";
import VerifyEmail from "./verify_email/VerifyEmail";

const Settings = ({ location, history }) => {
  const { pathname } = location;

  return (
    <>
      <Helmet>
        <title>Settings - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Settings</h1>
          <DayAndDate />
          <div className="mt-4 d-flex justify-content-between bg-white settings-container p-4">
            <div className="mr-2 flex-grow-2 settings-navbar">
              <h2 className="mt-4 ml-4">Account Settings</h2>
              <ul className="mt-4">
                {/* I am not using history.push instead of react router Link because I don't want to scatter the css */}
                <li
                  className={
                    pathname === "/settings" ? "highlighted-nav-item" : ""
                  }
                  onClick={() => history.push("/settings")}
                >
                  Change password
                </li>
                <li
                  className={
                    pathname === "/settings/create-pin"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/create-pin")}
                >
                  Create transaction pin
                </li>
                <li
                  className={
                    pathname === "/settings/change-pin"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/change-pin")}
                >
                  Change transaction pin
                </li>
                <li
                  className={
                    pathname === "/settings/reset-pin"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/reset-pin")}
                >
                  Reset Transaction Pin
                </li>
                {/* if user has city, it means he had already updated profile so remove the link */}
                <li
                  className={
                    pathname === "/settings/update-profile"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/update-profile")}
                >
                  Update Profile
                </li>
                {/* if user already has image remove the link */}
                <li
                  className={
                    pathname === "/settings/upload-profile-picture"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() =>
                    history.push("/settings/upload-profile-picture")
                  }
                >
                  Upload Profile Picture
                </li>
                <li
                  className={
                    pathname === "/settings/verify-phone-number"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/verify-phone-number")}
                >
                  Verify Phone Number
                </li>
                <li
                  className={
                    pathname === "/settings/verify-email-address"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/verify-email-address")}
                >
                  Verify Email Address
                </li>
                <li
                  className={
                    pathname === "/settings/invite"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/invite")}
                >
                  Invite Friends
                </li>
                <li
                  className={
                    pathname === "/settings/update-referrer"
                      ? "highlighted-nav-item"
                      : ""
                  }
                  onClick={() => history.push("/settings/update-referrer")}
                >
                  Update Referrer
                </li>
              </ul>
            </div>
            <Switch>
              <Route
                exact
                path="/settings/update-profile"
                component={EditProfile}
              />
              <Route exact path="/settings" component={ChangePassword} />
              <Route exact path="/settings/change-pin" component={ChangePin} />
              <Route exact path="/settings/create-pin" component={CreatePin} />
              <Route
                exact
                path="/settings/upload-profile-picture"
                component={ProfilePicture}
              />
              <Route
                exact
                path="/settings/verify-phone-number"
                component={VerifyPhone}
              />
              <Route
                exact
                path="/settings/verify-email-address"
                component={VerifyEmail}
              />
              <Route exact path="/settings/invite" component={InviteFriends} />
              <Route exact path="/settings/reset-pin" component={ResetPin} />
              <Route
                exact
                path="/settings/update-referrer"
                component={UpdateReferrer}
              />
            </Switch>
          </div>
        </main>
      </div>
    </>
  );
};

export default Settings;
