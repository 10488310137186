import React, { useState } from "react";
import VerifyEmailForm1 from "./VerifyEmailForm1";
import VerifyEmailForm2 from "./VerifyEmailForm2";
import Unathorized from "../Unathorized";
import { useSelector } from "react-redux";

// a clone of verify phone find css @ VerifyPhone.scss
const VerifyEmail = () => {
  const user = useSelector((state) => state.user);
  const savedEmail = user?.email ?? "";

  const [step, setStep] = useState(1);

  // if user already have a phone,use it
  const [email, setEmail] = useState("");

  if (user.email_verified_at) {
    return <Unathorized message="You already verified your email address" />;
  }

  return (
    <div className="edit-profile">
      <h2 className="settings-small-header">Verify Email Address</h2>
      <div className={step === 1 ? "" : "d-none"}>
        <VerifyEmailForm1
          setStep={setStep}
          email={email}
          setEmail={setEmail}
          savedEmail={savedEmail}
        />
      </div>
      <div className={step === 2 ? "" : "d-none"}>
        <VerifyEmailForm2 email={email} />
      </div>
    </div>
  );
};

export default VerifyEmail;
