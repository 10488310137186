export const errorHandler = (response) => {
  let html = "";
  let apiErrors = [];

  if (response.status === 422) {
    const errors = response.data.error;
    for (const property in errors) {
      if (typeof errors[property] === "string") {
        apiErrors.push(errors[property]);
      } else {
        apiErrors.push(errors[property][0]);
      }
    }
    apiErrors.forEach((err) => (html += `${err}<br/>`));
  } else if (response.status === 403) {
    html = response.data?.message
      ? response.data?.message
      : response.data?.error?.message;
  } else {
    html = response.data?.error?.message ?? "Unknown error occured";
  }

  return html;
};
