import React from "react";
import { Link } from "react-router-dom";
import "./Page404.scss";
import BackArrow404 from "../../assets/404-back-arrow.svg";

const Page404 = () => {
  return (
    <div className="page-404">
      <div className="page-404-main mt-5">
        <h1 className="page-404-main-heading">404</h1>
        <div className="page-404-main-content">
          <Link to="/" className="page-404-main-link">
            <img src={BackArrow404} alt="img-fluid Back Arrow Vector" />
            <span className="page-404-main-link-text">Go Back</span>
          </Link>
          <h2>SORRY!</h2>
          <p>The Page You’re Looking For Was Not Found</p>
        </div>
      </div>
    </div>
  );
};

export default Page404;
