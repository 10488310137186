import { call, put } from "redux-saga/effects";
import { setSchedules } from "../../ducks/schedules";
import { requestGetSchedules } from "../request/schedules";

export function* handleGetSchedules(action) {
  try {
    // make the get request
    const response = yield call(requestGetSchedules);
    const { data } = response;
    // dispatch to redux
    yield put(setSchedules(data.data.schedules));
  } catch (error) {
    console.log(error);
  }
}
