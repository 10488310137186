import { call, put } from "redux-saga/effects";
import { setBeneficiaries } from "../../ducks/beneficiaries";
import { requestGetBeneficiaries } from "../request/beneficiaries";

export function* handleGetBeneficiaries(action) {
  try {
    // make the get request
    const response = yield call(requestGetBeneficiaries);
    const { data } = response;
    // dispatch to redux
    yield put(setBeneficiaries(data.data.beneficiaries));
  } catch (error) {
    console.log(error);
  }
}
