import React, { useEffect, useState } from "react";
import DayAndDate from "../../components/DayAndDate";
import "./Transactions.scss";
import { Helmet } from "react-helmet-async";
import AllTransactions from "./all_transactions/AllTransactions";
import { useSelector } from "react-redux";
import axios from "axios";
import { thousandSeparator } from "../../utils/thousandSeparator";
import { Route, Switch } from "react-router-dom";
import TransactionStatement from "./transaction_statement/TransactionStatement";
import { getCurrencySymbol } from "../../utils/normalize";

const Transactions = ({ history, location }) => {
  const [accountId, setAccountId] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [allTransactions, setAllTransactions] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const accounts = useSelector((state) => state.accounts.accounts);

  useEffect(() => {
    if (accounts) {
      setAccountId(accounts[0].id);
    }
  }, [accounts]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (accountId) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API}/accounts/${accountId}/transactions?page[number]=${pageNumber}&page[size]=${pageSize}`
        )
        .then((res) => {
          setAllTransactions([...res.data.data.transactions.data]);
          setPageCount(res.data.data.transactions.meta.last_page);
          // console.log(res.data.data.transactions);
          setLoading(false);
          setPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setPageLoading(false);
        });
    }

    //cleanup
    return () => {
      source.cancel();
    };
  }, [accountId, pageNumber, pageSize]);

  return (
    <>
      <Helmet>
        <title>Transactions - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-2 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Transactions</h1>
          <DayAndDate />
          <div className="d-flex mb-2">
            <button
              className={
                location.pathname === "/transactions/statement/request"
                  ? "d-none"
                  : "btn btn-outline-primary ml-lg-auto ml-md-auto"
              }
              onClick={() => history.push("/transactions/statement/request")}
            >
              Request Account Statement
            </button>
          </div>
          <div
            className={
              accounts && accounts.length === 1 ? "d-none" : "form-group"
            }
          >
            <label htmlFor="exampleFormControlSelect1" className="text-primary">
              Change Account
            </label>
            <select
              className="form-control bg-primary text-white"
              id="exampleFormControlSelect1"
              value={accountId}
              onChange={(e) => {
                setAccountId(e.target.value);
                setLoading(true);
              }}
            >
              {accounts &&
                accounts.map((account, index) => (
                  <option value={account.id} key={index}>
                    {account.account_name} - {account.type.name} -{" "}
                    {getCurrencySymbol(account)}
                    {thousandSeparator(account.available_balance)}
                  </option>
                ))}
            </select>
          </div>
          <Switch>
            <Route
              exact
              path="/transactions"
              render={(props) => (
                <AllTransactions
                  {...props}
                  allTransactions={allTransactions}
                  isLoading={isLoading}
                  pageCount={pageCount}
                  setPageNumber={setPageNumber}
                  pageLoading={pageLoading}
                  setPageSize={setPageSize}
                />
              )}
            />
            <Route
              exact
              path="/transactions/statement/request"
              render={(props) => (
                <TransactionStatement {...props} accountId={accountId} />
              )}
            />
          </Switch>
        </main>
      </div>
    </>
  );
};

export default Transactions;
