import Cookies from "js-cookie";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get("isAuthenticated") && Cookies.get("token") ? (
          <Redirect to="/" />
        ) : Component ? (
          <Component {...props} />
        ) : (
          rest.render(props)
        )
      }
    />
  );
};

export default AuthRoute;
