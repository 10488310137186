import axios from "axios";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Cleave from "cleave.js/react";
import { thousandSeparator } from "../../../utils/thousandSeparator";

const TopUpModal = ({
  showModal,
  setShowModal,
  mandate,
  setMandate,
  fetch,
  setFetch,
}) => {
  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setMandate({ id: "", amount: "", schedule: "", end_date: "" });
    setAlerts({ messages: [], type: "" });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_API}/recurring-payments/${mandate.id}/terminate`
      )
      .then((res) => {
        setFetch(!fetch);
        setAlerts({
          messages: ["Successfully terminated"],
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>End</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alerts.messages.length > 0 && (
          <div
            className={`alert alert-${alerts.type} login-alert w-100`}
            role="alert"
          >
            {alerts.messages.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </div>
        )}
        <div className="">
          <p>
            Are you sure you want to end automatic transfer of ₦
            {mandate && thousandSeparator(mandate.amount)} to{" "}
            {mandate && mandate.beneficiary}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-light"
          onClick={handleClose}
          disabled={loading}
        >
          No
        </button>
        <button
          className="btn btn-danger"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? "Processing..." : "Yes"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TopUpModal;
