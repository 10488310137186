export const validateFundAccount = (info) => {
  const result = [];

  if (!info.beneficiary_account_number) {
    result.push("Please select an account");
  }

  if (!info.amount) {
    result.push("Please enter amount");
  }

  if (info.type === "recurring") {
    if (!info.schedule) {
      result.push("Please select a schedule");
    }
  }

  // if (!info.card_number) {
  //   result.push("Please enter card number");
  // }

  // if (!info.expiry_month) {
  //   result.push("Please enter card expiry date");
  // }

  // if (!info.cvv) {
  //   result.push("Please enter card cvv");
  // }

  return result;
};
