import React from "react";
import { useSelector } from "react-redux";
import FreshChat from "react-freshchat";

const FreshChatComponent = () => {
  const user = useSelector((state) => state.user);

  if (user.first_name) {
    return (
      <FreshChat
        token={process.env.REACT_APP_FRESHCHAT_TOKEN}
        email={user.email}
        firstName={user.first_name}
        onInit={(widget) => {
          // Use `widget` instead of `window.fcWidget`
          widget.user.setProperties({
            email: user.email,
            firstName: user.first_name,
            lastName: user.last_name,
            phone: user.telephone,
          });
        }}
      />
    );
  }

  return null;
};

export default FreshChatComponent;
