import React, { useEffect, useState } from "react";
import DayAndDate from "../../components/DayAndDate";
import FundAccount1 from "./fundaccount_forms/fundaccount1/FundAccount1";
import { Helmet } from "react-helmet-async";
//import FundAccount2 from "./fundaccount_forms/fundaccount2/FundAccount2";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSchedules } from "../../redux/ducks/schedules";

// FundAccount is a clone of sendmoney, non bootstrap classes came from sendmoney folder
const FundAccount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchedules());
  }, []);

  const [payment, setPayment] = useState({
    beneficiary_account_number: "",
    transaction_reference: "",
    amount: "",
    type: "instant",
    schedule: "",
    callback_url: window.location.origin,
  });

  return (
    <>
      <Helmet>
        <title>Fund Account - Shanono</title>
      </Helmet>
      <div className="user-dashboard">
        <main className="p-3 p-lg-4 sendmoney">
          <h1 className="sendmoney-header">Fund Account</h1>
          <DayAndDate />
          <div className="mt-5 d-flex flex-wrap justify-content-between sendmoney-formarea">
            <div className="sendmoney-left">
              <h2 className="text-primary sendmoney-bigheader">Fund Account</h2>
              <p>
                Add money to your Shanono account with a debit card.
                <br />
                Click <Link to="/fund-account/recurring-payments">here</Link> to
                manage recurring payments.
              </p>
            </div>
            <FundAccount1 payment={payment} setPayment={setPayment} />
            {/* <FundAccount2
              payment={payment}
              step={step}
              setStep={setStep}
              setPayment={setPayment}
              response={response}
              setResponse={setResponse}
            /> */}
          </div>
        </main>
      </div>
    </>
  );
};

export default FundAccount;
