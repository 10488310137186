import axios from "axios";
import React, { useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import { Form, Modal, Spinner, Toast } from "react-bootstrap";
import InputField from "../../../components/InputField.jsx";
import InputDropDown from "../../../components/InputDropDown";
import { validateEditRequest } from "./validateEditRequest.js";
import { thousandSeparator } from "../../../utils/thousandSeparator.js";
import { deliveryFee } from "../../../utils/deliveryFee.js";

const EditReqModal = ({
  showModal,
  setShowModal,
  request,
  setRequest,
  fetch,
  setFetch,
  countries,
}) => {
  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState(null);
  const [loadingStates, setLoadingStates] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setRequest(null);
    setAlerts({ messages: [], type: "" });
  };

  const getStates = (countryId) => {
    setLoadingStates(true);
    setStates(null);
    axios
      .get(`${process.env.REACT_APP_API}/countries/${countryId}/states`)
      .then((res) => {
        setLoadingStates(false);
        setStates(res.data.data.states);
      })
      .catch((err) => {
        setLoadingStates(false);
        console.log(err);
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    setAlerts({ messages: [], type: "" });

    const { delivery_option, existing_address, pin } = values;

    let editData = {};
    if (delivery_option === "pickup") {
      editData = { delivery_option, pin };
    } else if (existing_address === "true") {
      editData = {
        delivery_option,
        existing_address: true,
        pin,
      };
    } else {
      editData = { ...values, existing_address: false };
    }

    axios
      .patch(
        `${process.env.REACT_APP_API}/card-requests/${request.id}`,
        editData
      )
      .then((res) => {
        setFetch(!fetch);
        setAlerts({
          messages: ["Your card request has been updated successfully"],
          type: "success",
        });
        setLoading(false);
        resetForm(values);
        setShow(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  const cardFormStyles = {
    width: "100%",
  };

  return (
    <>
      <Toast
        className="m-auto text-white bg-primary"
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
      >
        <Toast.Body>Your request has been updated successfully</Toast.Body>
      </Toast>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Card Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alerts.messages.length > 0 && (
            <div
              className={`alert alert-${alerts.type} login-alert w-100`}
              role="alert"
            >
              {alerts.messages.map((item, index) => (
                <p key={index} style={{ fontSize: "14px" }}>
                  {item}
                </p>
              ))}
            </div>
          )}
          <div className="d-flex justify-content-between">
            <p className="text-primary mr-2">Linked Account:</p>
            <p>{request && request.account.account_number}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="text-primary mr-2">Card Type</p>
            <p>{request && request.card_type.name}</p>
          </div>
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                delivery_option: request ? request.delivery_option : "",
                existing_address: "",
                address_line_one: !request
                  ? ""
                  : request.address_line_one
                  ? request.address_line_one
                  : "",
                address_line_two: !request
                  ? ""
                  : request.address_line_two
                  ? request.address_line_two
                  : "",
                country: "",
                state: "",
                city: !request ? "" : request.city ? request.city : "",
                pin: "",
              }}
              validationSchema={validateEditRequest}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <FormikForm>
                  <div>
                    <div>
                      <InputDropDown
                        label="Delivery Method"
                        noValue="-- Choose Delivery Method --"
                        name="delivery_option"
                        options={["Pickup at our Office", "Door Delivery"]}
                        values={["pickup", "dispatch"]}
                        style={cardFormStyles}
                      />

                      {/* Only show when pickup option is chosen */}

                      {formik.values.delivery_option === "pickup" && (
                        <>
                          <div>
                            <Form.Group controlId="pickup-address">
                              <Form.Label className="sendmoney-select_acc-label">
                                Pick Up Address
                              </Form.Label>
                              <Form.Control
                                disabled
                                as="textarea"
                                rows={3}
                                value="No.3 John Great Court, Chevron Drive, Lekki Expressway, Lekki, Lagos"
                                className="sendmoney-textarea"
                                style={cardFormStyles}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}

                      {/* Make API call and post request if pick up option is chosen */}

                      {/* if Door Delivery(Dispatch) is chosen, show "use existing address option" */}

                      {request &&
                        formik.values.delivery_option === "dispatch" &&
                        request.delivery_option !== "dispatch" && (
                          <InputDropDown
                            label="Use Existing address"
                            noValue="-- Select Option --"
                            name="existing_address"
                            options={["Yes", "No"]}
                            values={[true, false]}
                            style={cardFormStyles}
                          />
                        )}

                      {/* if useExistingAddress is true, make API call and post request" */}

                      {/* if useExistingAddress is false, show Address Line Fields, Country, State and City options" */}

                      {formik.values.existing_address === "false" && (
                        <>
                          <InputField
                            label="Address Line 1"
                            name="address_line_one"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputField
                            label="Address Line 2"
                            name="address_line_two"
                            type="text"
                            style={cardFormStyles}
                          />

                          <InputDropDown
                            label="Country"
                            noValue="-- Select Country --"
                            name="country"
                            options={[
                              ...countries.map((country) => country.name),
                            ]}
                            values={[...countries.map((country) => country.id)]}
                            style={cardFormStyles}
                            onChange={(event) => {
                              formik.setFieldValue(
                                "country",
                                event.currentTarget.value
                              );
                              formik.setFieldValue("state", "");
                              getStates(event.currentTarget.value);
                            }}
                          />
                          <Form.Group controlId="exampleForm.ControlSelect2">
                            <Form.Label className="sendmoney-select_acc-label">
                              State
                            </Form.Label>
                            <p className={loadingStates ? "" : "d-none"}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </p>
                            <Form.Control
                              as="select"
                              name="state"
                              className={
                                loadingStates ? "d-none" : "select-input"
                              }
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "state",
                                  event.currentTarget.value
                                );
                              }}
                            >
                              <option value="">-- Select State --</option>
                              {states &&
                                states.map((state, index) => (
                                  <option key={index} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </Form.Group>
                          <InputField
                            label="City"
                            name="city"
                            type="text"
                            style={cardFormStyles}
                          />
                        </>
                      )}
                      <InputField
                        label="Transaction pin"
                        name="pin"
                        type="password"
                        style={cardFormStyles}
                        maxLength="4"
                      />
                      <div
                        className={
                          formik.values.existing_address === "false"
                            ? ""
                            : "d-none"
                        }
                      >
                        <p className="d-flex justify-content-between text-primary">
                          <span>Delivery Fee:</span>
                          <span>
                            {deliveryFee(formik.values, countries, states)
                              ? "₦" +
                                thousandSeparator(
                                  deliveryFee(formik.values, countries, states)
                                )
                              : "Applicable"}
                          </span>
                        </p>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary mt-4 mb-2 w-100"
                      disabled={loading}
                    >
                      {loading ? "Processing..." : "Change"}
                    </button>
                  </div>
                  <button
                    className="btn btn-outline-danger w-100"
                    onClick={handleClose}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditReqModal;
