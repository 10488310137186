import axios from "axios";
import {
  //   removeUserLocation,
  setUseLocation,
} from "../redux/ducks/userLocation";
import { fireAlert } from "./Alert";
import { errorHandler } from "./errorHandler";

/**
 * Use Big Data Shanono reverse API to get full details of a particular coordinate
 */
export const getUserLocation = (dispatch) => {
  navigator.geolocation.getCurrentPosition((position) => {
    // Get Geolocation coordinates => Latitude, longitude
    const latitude = position?.coords?.latitude;
    const longitude = position?.coords?.longitude;

    // Get actual location using Big Cloud Data
    axios
      .get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      )
      .then((response) => {
        const location = {
          state: response?.data?.principalSubdivision,
          city: response?.data?.locality,
          country: response?.data?.countryName,
          countryCode: response?.data?.countryCode,
          continent: response?.data?.continent,
          coords: { longitude, latitude },
        };

        //   dispatch location
        dispatch(setUseLocation(location));
      })
      .catch((error) => {
        let html = errorHandler(error?.response);
        const title = "Failed to get user location";
        const icon = "error";
        fireAlert(title, html, icon);
        // dispatch(removeUserLocation());
      });
  });
};
