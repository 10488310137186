import moment from "moment";
import React, { useEffect, useState } from "react";
import Datatable from "react-bs-datatable";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import EndModal from "./EndModal";
import EditModal from "./EditModal";
import Unathorized from "../../settings/Unathorized";
import { withRouter } from "react-router-dom";

const ManageAutoTransfer = ({ history }) => {
  const [mandates, setMandates] = useState(null);
  const [loading, setLoading] = useState(true);
  // for top up modal
  const [showModal, setShowModal] = useState(false);
  // for edit modal
  const [showEdit, setShowEdit] = useState(false);
  // mandate to be editted
  const [mandate, setMandate] = useState({
    id: "",
    amount: "",
    schedule: "",
    hour_of_day: "",
    end_date: "",
  });
  // this is just to fire useEffect and trigger live update when user do topup
  const [fetch, setFetch] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API}/recurring-payments`)
      .then((res) => {
        setMandates(res.data.data.mandates.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, [fetch]);

  if (loading) {
    return (
      <div className="user-dashboard d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  }

  if (!mandates) {
    return (
      <div className="user-dashboard d-flex justify-content-center align-items-center">
        <div className="text-primary text-center mt-5">
          <p>Something went wrong,please try again later</p>
        </div>
      </div>
    );
  }

  if (mandates.length < 1) {
    return (
      <div className="mt-5 m-auto">
        <Unathorized message="You have no automatic transfers" />
      </div>
    );
  }

  //header for data table
  const header = [
    {
      title: "SOURCE",
      prop: "source",
      filterable: true,
    },
    {
      title: "BENEFICIARY",
      prop: "beneficiary",
      filterable: true,
      sortable: true,
    },
    { title: "AMOUNT", prop: "amount", filterable: true },
    { title: "SCHEDULE", prop: "schedule", filterable: true, sortable: true },
    { title: "START DATE", prop: "start_date", filterable: true },
    { title: "END DATE", prop: "end_date", filterable: true },
    { title: "STATUS", prop: "status", filterable: true },
    { title: "", prop: "edit" },
    { title: "", prop: "end" },
  ];

  const body = [];

  mandates.forEach((item) => {
    body.push({
      source: `${
        item.source.account_name + " - " + item.source.account_number
      }`,
      beneficiary: `${
        item.beneficiary_account_name +
        " - " +
        item.beneficiary_account_number +
        " - " +
        item.beneficiary_bank_name
      }`,
      amount: "₦" + thousandSeparator(item.amount),
      schedule: <span className="text-capitalize">{item.schedule}</span>,
      start_date: moment(item.start_date).format("MMMM Do, YYYY"),
      end_date: moment(item.end_date).format("MMMM Do, YYYY"),
      status: (
        <span
          className={`${
            item.status === "pending"
              ? "text-warning"
              : item.status === "ongoing"
              ? "text-primary"
              : "text-danger"
          }`}
        >
          {item.status}
        </span>
      ),
      edit: (
        <button
          className="btn btn-outline-primary topup mt-n2"
          onClick={() => {
            setShowEdit(true);
            setMandate({
              id: item.id,
              amount: item.amount,
              schedule: item.schedule,
              hour_of_day: item.hour_of_day,
              end_date: item.end_date,
            });
          }}
          disabled={item.status === "terminated"}
        >
          Edit
        </button>
      ),
      end: (
        <button
          className="btn btn-outline-danger topup mt-n2"
          onClick={() => {
            setShowModal(true);
            setMandate({
              id: item.id,
              amount: item.amount,
              beneficiary:
                item.beneficiary_account_name +
                " - " +
                item.beneficiary_bank_name +
                " - " +
                item.beneficiary_account_number,
            });
          }}
          style={{ whiteSpace: "nowrap" }}
          disabled={item.status === "finished" || item.status === "terminated"}
        >
          End
        </button>
      ),
    });
  });

  return (
    <>
      <EndModal
        showModal={showModal}
        setShowModal={setShowModal}
        mandate={mandate}
        setMandate={setMandate}
        setFetch={setFetch}
        fetch={fetch}
      />
      <EditModal
        showModal={showEdit}
        setShowModal={setShowEdit}
        mandate={mandate}
        setMandate={setMandate}
        setFetch={setFetch}
        fetch={fetch}
      />
      <div className="user-dashboard">
        <h2 className="text-primary text-center mx-4 p-4">
          <u
            onClick={() => history.push("/auto-transfer")}
            style={{ cursor: "pointer" }}
          >
            Automatic Funds Transfer
          </u>
        </h2>
        <div className="table-responsive container-fluid">
          <Datatable
            tableHeaders={header}
            tableBody={body}
            rowsPerPage={5}
            rowsPerPageOption={[5, 10, 15, 20]}
            //  onRowClick={onColClick}
            classes={{
              table: "mt-3 table",
              thead: "",
              filterClearButton: "clear-btn",
              tbodyCol: "table-data table-width",
            }}
            labels={{ filterPlaceholder: "Filter..." }}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(ManageAutoTransfer);
