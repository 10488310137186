import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="m-4 m-lg-5">
      <header className="text-primary">
        <h1 className="text-capitalize">Terms of service</h1>
        <h3>DECLARATION</h3>
        <hr />
      </header>
      <div>
        <section>
          <p>
            I understand that Shanono Microfinance Bank is a subsidiary of Latrose
            Finance and Company Limited and thus, functions as a part of Latrose
            Finance and Company AND HEREBY REQUEST AND AUTHORISE YOU TO;
          </p>
          <ol>
            <li>
              Open an account in my name and at any time subsequently open
              further accounts as I may direct.
            </li>
            <li>
              Honour all orders which may be drawn on the said account provided
              such orders are signed by me and to debit such order to the said
              account whether such account be for the me being in credit or
              overdrawn or may become overdrawn in consequence of such debit
              without prejudice to your right to refuse to allow any overdraft
              or increase of overdraft and in consideration, I agree to assume
              full responsibility for the genuineness, correctness and validity
              of endorsements appearing on all orders,negotiable instruments,
              receipts and/or documents deposited in my account.
            </li>
            <li>
              To be responsible for the repayment of any overdraft with interest
              and to comply and be bound by Shanono Microfinance Bank’s rules for
              the conduct of a Savings Account receipt of which I hereby
              acknowledge.
            </li>
            <li>
              To free Shanono Microfinance Bank from any responsibility for any
              loss or damage to funds deposited with Shanono Microfinance Bank due
              to any future government order, law, levy, tax, embargo and/or all
              other causes beyond Shanono Microfinance Bank’s control.
            </li>
            <li>
              That all funds standing to my credit are payable on demand only in
              such local currency as may be in circulation.
            </li>
            <li>
              To be bound by any notification of change in conditions governing
              the account directed to my last known address and any notice or
              letter sent to my last known address shall be considered as duly
              delivered and received by me at the time it will be delivered in
              the ordinary course of post.
            </li>
            <li>
              That Shanono Microfinance Bank will accept no liability whatsoever
              for funds handed to members of staff without Shanono Microfinance
              Bank’s authorisation.
            </li>
            <li>
              That any disagreements with entries on my bank statements will be
              made by me within 15 days of the dispatch of the bank statement.
              Failing receipt by Shanono Microfinance Bank of a notice of
              disagreement of entries within 15 days from the date of dispatch
              of my bank statement as rendered is correct.
            </li>
            <li>
              I hereby agree that I shall, at my own expense, indemnify, defend
              and hold harmless Shanono Microfinance Bank from and against any and
              all liability any other loss that may occur, arising from or
              relating to the operation or use of the Account or the Services or
              breach, non-performance or inadequate performance by me of any of
              these Terms or the acts, errors, representations,
              misrepresentations, misconduct or negligence of the Customer in
              performance of its obligations.
            </li>
            <li>
              Under no circumstances shall Shanono Microfinance Bank be liable to
              me for any indirect, incidental, consequential, special or
              exemplary damages in connection with the Account or the Services.
            </li>
            <li>
              Shanono Microfinance Bank shall not be liable for any failure to
              perform any obligation contained in these Terms or for any loss or
              damage whatsoever suffered or incurred by me.
            </li>
            <li>
              I shall keep Shanono Microfinance Bank indemnified at all times
              against, and save Shanono Microfinance Bank harmless from all
              actions, proceedings, claims, losses, damages, costs, interest
              (both before and after judgement) and expenses (including legal
              costs on a solicitor and client basis) which may be brought
              against or suffered or incurred by Shanono Microfinance Bank in
              resolving any dispute relating to the Customer’s Account with
              Shanono Microfinance Bank or in enforcing Shanono Microfinance Bank’s
              rights under or in connection with these Terms and conditio
              contained herein, or which may have arisen either directly or
              indirectly out of or in connection with Shanono Microfinance Bank
              performing its obligations hereunder or accepting instructions,
              including but not limited to, fax and other telecommunications or
              electronic instructions, and acting or failing to act thereon.
            </li>
            <li>
              If any sum due and payable by me is not paid on the due date,
              including without limitation any moneys claimed under this
              Paragraph, I shall be liable to pay interest (both after as well
              as before any judgement) on such unpaid sum at such rate or rates
              as Shanono Microfinance Bank may from time to time stipulate from
              the date payment is due up to the date of payment.
            </li>
            <li>
              I shall solely be responsible for ensuring full compliance with
              all the applicable laws and regulations in any relevant
              jurisdiction in connection with establishment of my Account with
              Shanono Microfinance Bank and shall indemnify and keep indemnified
              Shanono Microfinance Bank from all actions, proceedings claims,
              losses, damages, costs and expenses (including legal costs on a
              solicitor and client basis) which may be brought against or
              suffered or incurred by Shanono Microfinance Bank in connection with
              any failure to comply with any such applicable laws/regulations.
            </li>
            <li>
              The indemnities as aforesaid shall continue notwithstanding the
              termination of the Account.
            </li>
            <li>
              That any sum standing to the debit of the account shall bear
              interest charges at the rate fixed by Shanono Microfinance Bank from
              time to time. Shanono Microfinance Bank is authorized to debit from
              the account the usual banking charges, interest, commissions and
              any service charge set by the Management from time to time.
            </li>
            <li>
              I also agree that in addition to any general lien or similar right
              to which you as bankers may be entitled bylaw you may at any time
              without notice to me combine or consolidate all or any of my
              accounts without any liabilities to you and set off or transfer
              any sum or sums standing to the credit of anyone or more of such
              accounts or any other credits, be it cash, cheques, valuables,
              deposits, securities, negotiable instruments or other assets
              belonging to with you in or towards satisfaction of any of my/our
              liabilities to you or any other account or in any other respect
              whether such liabilities be actual or contingents, primary or
              collateral and joint or several.
            </li>
            <li>
              I shall be solely responsible for the safe-keeping and the
              confidentiality of the statements of account, balance confirmation
              certificate, user id and passwords relating to internet banking
              and such other items relevant or pertaining to the account.
            </li>
          </ol>
        </section>
        <section>
          <h4 className="text-primary">INSTANT SAVINGS ACCOUNT FEATURES</h4>
          <p>Account Features​:</p>
          <ul>
            <li>Zero (0) minimum account opening balance.</li>
            <li>
              Account details can be uploaded via our contact centre or website.
              Transactions are limited to Nigeria.
            </li>
          </ul>
        </section>
        <section>
          <h4 className="text-primary">DISCLOSURE OF INFORMATION</h4>
          <ol style={{ listStyle: "lower-roman" }}>
            <li>
              Shanono Microfinance Bank may hold and process by computer or any
              other means, information obtained about the account holder in
              consequence of this agreement.
            </li>
            <li>
              Shanono Microfinance Bank may disclose information on the account
              holder to:
              <ol style={{ listStyle: "lower-alpha" }} className="m-2 mx-lg-5">
                <li>
                  Any person (including the police) for the purpose of
                  investigation of a fraud related matter.
                </li>
                <li>
                  Any relevant party involved in processing Shanono Microfinance
                  Bank transactions.
                </li>
                <li>
                  Any person who may assume Shanono Microfinance Bank right under
                  this agreement.
                </li>
                <li>
                  Any party, if permitted or compelled to do so by the provision
                  of any enactment, order of a court of law or any regulatory
                  institution.
                </li>
              </ol>
            </li>
          </ol>
        </section>
        <section>
          <h4 className="text-primary">TERMINATION OF THIS AGREEMENT</h4>
          <p>
            Either party may terminate this agreement with seven days written
            notice to the other party provided that the Shanono Microfinance Bank
            may terminate its agreement with or without notice if the
            circumstance so warrants.
          </p>
        </section>
        <section>
          <h4 className="text-primary">GENERAL PROVISIONS</h4>
          <p>
            Shanono Microfinance Bank reserves the right at all times to
            supplement, amend or vary this agreement as a result of a
            requirement of law or product development or such other reason
            communicated to the account holder at the time of notification of
            the change. Any such change will be effective upon notice to the
            account holder and notice shall be by any means the Shanono
            Microfinance Bank deems fit. On receipt of such notification, the
            account holder may at its discretion terminate this agreement in
            accordance with the conditions of this agreement. On termination,
            bankruptcy, dissolution, insolvency, liquidation or death, the
            account holder’s obligations will continue until all outstanding
            indebtedness owed to Shanono Microfinance Bank by the account holder
            is fully repaid. The waiver by Shanono Microfinance Bank of any breach
            of any term of this agreement will not prevent the subsequent
            enforcement of that term and will not be deemed a waiver of any
            subsequent breach.
          </p>
        </section>
        <h5 className="text-primary">
          <small>
            I HEREBY CONFIRM THAT I HAVE READ THE ABOVE TERMS AND CONDITIONS AND
            AFFIRM THAT I TRULY UNDERSTAND AND ACCEPT SAME AS BINDING ON ME.
          </small>
        </h5>
      </div>
    </div>
  );
};

export default TermsAndConditions;
