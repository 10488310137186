import React from "react";
import "../register/Register.scss";
import "./Login.scss";
import Big_logo from "../../assets/Cloudbank_logo-Big.png";
import LoginForm from "./LoginForm";
import { Helmet } from "react-helmet-async";

// this component and its children use some classes in register/Register.scss
const Login = () => {
  return (
    <div className="register">
      <Helmet>
        <title>Login - Shanono</title>
      </Helmet>
      <h1 className="reg-logo pt-5">
        {/* Adjust width and height of the image */}
        <img
          className="img-fluid"
          src={Big_logo}
          alt="shanono logo"
          style={{ width: "150px", height: "auto" }}
        />
      </h1>

      <div className="reg-msg mt-2 mb-3">
        <h2>Login to your Shanono Bank account</h2>
      </div>
      <LoginForm />
    </div>
  );
};

export default Login;
