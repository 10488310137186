import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert";
import InputField from "../../../components/InputField";
import { errorHandler } from "../../../utils/errorHandler";

const UpdateReferrer = ({ history }) => {

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    axios
      .patch(`${process.env.REACT_APP_API}/user/referrer`, values)
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "Your referrer has been successfully updated.";
        const icon = "success";
        fireAlert(title, html, icon);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  const validate = Yup.object({
   referrer_code: Yup.string().required("Required")
  });

  return (
    <div className="edit-profile">
      <h2 className="settings-small-header">Update Referrer</h2>
      <div>
        <Formik
          initialValues={{
            referrer_code: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
             <InputField
                  label="Referrer Code"
                  name="referrer_code"
                  type="text"
                />
              <button
                type="submit"
                className="btn btn-primary mt-2"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Update"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateReferrer;
