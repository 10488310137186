import React, { useState } from "react";
import "./Register.scss";
import { Helmet } from "react-helmet-async";
import RegisterForm2 from "./register_forms/RegisterForm2";
import Big_logo from "../../assets/Cloudbank_logo-Big.png";
import RegisterForm1 from "./register_forms/RegisterForm1";
import RegisterForm3 from "./register_forms/RegisterForm3";

const Register = () => {
  const [step, setStep] = useState(1);
  const [account, setAccount] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    password: "",
    email_token: "",
    telephone_token: "",
    referrer_code: "",
    //bvn: "",
    date_of_birth: "",
    //account_type: "",
    terms: "on", //last form will check ifaccount checked the box for this
    //account_name: "",
    // cac_document: "",
  });
  return (
    <div className="register">
      <Helmet>
        <title>Register - Shanono</title>
      </Helmet>
      <h1 className="reg-logo pt-5">
      <img
          className="img-fluid"
          src={Big_logo}
          alt="shanono logo"
          style={{ width: "150px", height: "auto" }}
        />
      </h1>
      <h2 style={{ fontSize: "24px" }}>Create new account</h2>
      <div className={step === 1 ? "" : "d-none"}>
        <RegisterForm1
          setStep={setStep}
          account={account}
          setAccount={setAccount}
        />
      </div>
      <div className={step === 2 ? "" : "d-none"}>
        <RegisterForm2
          setStep={setStep}
          account={account}
          setAccount={setAccount}
        />
      </div>
      <div className={step === 3 ? "" : "d-none"}>
        <RegisterForm3
          setStep={setStep}
          account={account}
          setAccount={setAccount}
        />
      </div>
    </div>
  );
};

export default Register;
