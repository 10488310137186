import { Form, Formik } from "formik";
import React, { useState } from "react";
import InputField from "../../../components/InputField";
import * as Yup from "yup";
import axios from "axios";
import { fireAlert } from "../../../utils/Alert";
import { Alert } from "react-bootstrap";
import { errorHandler } from "../../../utils/errorHandler";

const ResetPin2 = ({ page, setPage }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    const { token, pin } = values;
    axios
      .put(`${process.env.REACT_APP_API}/security/pin`, { token, pin })
      .then((res) => {
        resetForm(values);
        setLoading(false);
        const title = "Successful";
        const html = "You have successfully reset your pin";
        const icon = "success";
        fireAlert(title, html, icon);
        setPage(1);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let html = errorHandler(err.response);
        const title = "Failed";
        const icon = "error";
        fireAlert(title, html, icon);
      });
  };

  const validate = Yup.object({
    token: Yup.string().required("Enter token sent to you"),
    pin: Yup.string()
      .min(4, "Pin must be at least 4 digits")
      .max(4, "Pin cannot be more than 4 digits")
      .required("Pin is required"),
    pin_confirmation: Yup.string()
      .oneOf([Yup.ref("pin"), null], "pins must match")
      .required("Please confirm pin"),
  });

  return (
    <div className={page === 1 ? "d-none" : ""}>
      <Alert variant="primary" className="change-pin_alert">
        A verification token was sent to your email
      </Alert>
      <div>
        <Formik
          initialValues={{
            token: "",
            pin: "",
            pin_confirmation: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputField
                label="Enter Token"
                name="token"
                type="text"
                maxLength="6"
                style={{ width: "100%" }}
              />
              <InputField
                label="Enter New Pin"
                name="pin"
                type="password"
                maxLength="4"
                style={{ width: "100%" }}
              />
              <InputField
                label="Confirm New Pin"
                name="pin_confirmation"
                type="password"
                maxLength="4"
                style={{ width: "100%" }}
              />

             <div className="d-flex justify-content-between mt-3">
             <button
                className="btn btn-outline-secondary w-100 mr-2"
                onClick={() => setPage(1)}
              >
                Back
              </button>
             <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Reset Pin"}
              </button>
            
             </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPin2;
