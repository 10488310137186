import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as ImIcons from "react-icons/im";
import { Link, withRouter } from "react-router-dom";
import "./UserMenu.scss";
import Big_logo from "../../assets/Cloudbank_logo-Big.png";
import { IconContext } from "react-icons/lib";
import { SidebarData } from "./SidebarData";
import { logoutUser } from "../../redux/configureStore";
import { useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const UserMenu = ({ location, history }) => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const hideInLocation = [
    "/login",
    "/register",
    "/register/enter-otp",
    "/forget-password",
    "/terms-of-service",
    "/fund-account/success"
  ];

  const dispatch = useDispatch();

  const logout = () => {
    delete axios.defaults.headers.common["Authorization"];
    Cookies.remove("token");
    Cookies.remove("isAuthenticated");
    Cookies.remove("refreshToken");
    dispatch(logoutUser());
    console.log("You have been logged out successfully");
    toast.success("You have been logged out successfully.");
    history.replace("/login");
  };

  return (
    <div className="hide-on-print">
      <div
        className={`"" ${
          hideInLocation.includes(location.pathname) && "hide-nav"
        }`}
      >
        <IconContext.Provider value={{}}>
          <div className="navbar">
            <Link to="#" className="menu-bars">
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
            <Link to="#" className="menu-bars">
              <img
                src={Big_logo}
                alt="shanono logo"
                className="img-fluid nav-menu_img"
              />
            </Link>
          </div>
          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
              <li className="d-flex justify-content-center align-items-center py-4 nav-menu_logo">
                <img
                  src={Big_logo}
                  alt="shanono logo"
                  className="img-fluid nav-menu_img"
                />
              </li>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars">
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {SidebarData.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${item.cName} ${
                      item.paths.includes(location.pathname) && "menu-path"
                    }`}
                  >
                    <Link to={item.path}>
                      {item.icon}
                      <span className="menu-span">{item.title}</span>
                    </Link>
                  </li>
                );
              })}
              <li className="nav-text nav-logout" onClick={logout}>
                <span>
                  <ImIcons.ImSwitch />
                  <span className="menu-span">Logout</span>
                </span>
              </li>
            </ul>
          </nav>
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default withRouter(UserMenu);