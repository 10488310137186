import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../components/InputField";
import "./Login.scss";
import { withRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/ducks/user";
import axios from "axios";
import Cookies from "js-cookie";
import { Alert, Fade } from "react-bootstrap";
import { getAccounts } from "../../redux/ducks/accounts";
import { validateUsername } from "../../utils/validateUsername";
import { getUserLocation } from "../../utils/userLocation";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const LoginForm = ({ history }) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const alertRef = useRef(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        await getUserLocation(dispatch);
      } catch (error) {
        console.error('Error fetching user location:', error);
      }
    };
  
    fetchLocation();
  }, [dispatch]);

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    setErrors([]);
    setShowAlert(false);

    try {
      // Add user location data to signin payload
      const loc = [
        {
          city: location?.city,
          state: location?.state,
          country: location?.country,
          country_code: location?.countryCode,
          latitude: location?.coords.latitude,
          longitude: location?.coords.longitude,
        },
      ];

      // Attach location info to the signin data
      const payload = {
        email: values.username,
        password: values.password,
        location_infos: loc,
      };

      console.log("Payload sent...");

      const res = await axios.post(`${process.env.REACT_APP_API}/login`, payload);

      console.log("Login response:", res.data.status);

      setLoading(false);
      resetForm();
      axios.defaults.headers.common["authorization"] = `Bearer ${res.data.data.token}`;
      Cookies.set("token", res.data.data.token);
      Cookies.set("isAuthenticated", true);
      Cookies.set("refreshToken", res.data.data.refresh_token);
      dispatch(setUser(res.data.data.user));
      dispatch(getAccounts());
      toast.success("Logged in successfully.");
      history.replace("/");
    } catch (err) {
      setLoading(false);
      console.error("Error in handleSubmit:", err);

      const apiErrors = err.response?.data?.errors || ["An unexpected error occurred"];
      let errorsArr = [];
      if (err.response?.status === 422) {
        if (Array.isArray(apiErrors)) {
          errorsArr = apiErrors;
        } else {
          for (const property in apiErrors) {
            if (Array.isArray(apiErrors[property])) {
              errorsArr.push(...apiErrors[property]);
            } else {
              errorsArr.push(apiErrors[property]);
            }
          }
        }
      } else {
        errorsArr = [err.message];
      }
      setErrors([...errorsArr]);
      setShowAlert(true);
    }
  };

  const validate = Yup.object({
    username: validateUsername(),
    password: Yup.string().required("Password is required"),
  });

  return (
    <div className="text-left form-container">
      <Fade in={showAlert} ref={alertRef}>
        <div>
          <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
            show={showAlert}
            className="login-alert"
          >
            {errors.map((error, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {error}
              </p>
            ))}
          </Alert>
        </div>
      </Fade>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <InputField
              label="EMAIL OR PHONE NUMBER"
              name="username"
              type="text"
              placeholder="EMAIL ADDRESS OR PHONE NUMBER"
            />
            <InputField
              label="ENTER YOUR PASSWORD"
              name="password"
              type="password"
              placeholder="********"
            />
            <p className="forget-pass">
              <Link to="/forget-password">Forgot password?</Link>
            </p>
            <button
              type="submit"
              className="btn btn-primary mt-2 login-btn"
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Login"}
            </button>
          </Form>
        )}
      </Formik>
      <p className="text-center py-4 reg-bottom-text">
        Don't have an account?
        <span className="text-primary ml-1">
          <Link to="/register">Register</Link>
        </span>
      </p>
    </div>
  );
};

export default withRouter(LoginForm);