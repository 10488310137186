import axios from "axios";
import React, { useState } from "react";

const ResetPin1 = ({ page, setPage }) => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setErrors([]);
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API}/tokens/reset/pin`)
      .then((res) => {
        setLoading(false);
        setPage(2);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const errors = err.response.data.error;
          for (const property in errors) {
            if (typeof errors[property] === "string"){
              apiErrors.unshift(errors[property]);
            }else{
              apiErrors.unshift(errors[property][0]);
            }
          }
          setErrors(apiErrors);
        } else if (err.response.status === 403) {
          setErrors([err.response.data.message]);
        } else {
          setErrors([err.response.data.error.message]);
        }
      });
  };

  return (
    <div className={page === 2 ? "d-none" : ""}>
      {errors.length > 0 && (
        <div className="alert alert-danger login-alert w-100" role="alert">
          {errors.map((item, index) => (
            <p key={index} style={{ fontSize: "14px" }}>
              {item}
            </p>
          ))}
        </div>
      )}
      <button
        className="btn btn-primary"
        disabled={loading}
        onClick={handleClick}
      >
        {loading ? "Please wait..." : "Request Token"}
      </button>
    </div>
  );
};

export default ResetPin1;
