import axios from "axios";
import Cleave from "cleave.js/react";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const UpdatePaymentModal = ({
  showUpdateModal,
  setShowUpdateModal,
  payment,
  setPayment,
  deleted,
  setDeleted,
}) => {
  const [state, setState] = useState({
    amount: null,
    schedule: null,
  });

  const schedules = useSelector((state) => state.schedules.schedules);

  const [alerts, setAlerts] = useState({ messages: [], type: "" });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setPayment(null);
    setShowUpdateModal(false);
    setAlerts({ messages: [], type: "" });
    setState({
      amount: null,
      schedule: null,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    setAlerts({ messages: [], type: "" });

    // if new update assign accordingly
    let update = {};
    update.amount = state.amount ? state.amount : payment.amount;
    update.schedule = state.schedule ? state.schedule : payment.schedule;

    axios
      .patch(`${process.env.REACT_APP_API}/card-payments/${payment.id}`, update)
      .then((res) => {
        setState({
          amount: null,
          schedule: null,
        });
        setDeleted(!deleted);
        setLoading(false);
        setAlerts({
          messages: ["Payment updated successfully"],
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        let apiErrors = [];
        if (err.response.status === 422) {
          const returnedErrors = err.response.data.error;
          for (const property in returnedErrors) {
            apiErrors.unshift(returnedErrors[property][0]);
          }
          setAlerts({ type: "danger", messages: [...apiErrors] });
        } else if (err.response.status === 403) {
          setAlerts({ type: "danger", messages: [err.response.data.message] });
        } else {
          setAlerts({
            type: "danger",
            messages: [err.response.data.error.message],
          });
        }
      });
  };

  return (
    <Modal show={showUpdateModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alerts.messages.length > 0 && (
          <div
            className={`alert alert-${alerts.type} login-alert w-100`}
            role="alert"
          >
            {alerts.messages.map((item, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {item}
              </p>
            ))}
          </div>
        )}
        <div>
          <p className="sendmoney-select_acc-label">Amount</p>
          <Cleave
            options={{
              numeral: true,
              numeralThousandsGroupStyle: "thousand",
              prefix: "₦ ",
              rawValueTrimPrefix: true,
            }}
            className="select-input"
            id="amount-input"
            name="amount"
            value={payment ? payment.amount : ""}
            onChange={(e) => setState({ ...state, amount: e.target.rawValue })}
          />
        </div>
        <Form.Group controlId="exampleForm.ControlSelect2d2">
          <Form.Label className="sendmoney-select_acc-label">
            Schedule
          </Form.Label>
          <Form.Control
            as="select"
            name="schedule"
            onChange={(e) => setState({ ...state, schedule: e.target.value })}
            className="select-input"
            value={
              state.schedule ? state.schedule : payment ? payment.schedule : ""
            }
          >
            {schedules &&
              Object.keys(schedules).map((key, index) => (
                <option key={index} value={key}>
                  {schedules[key]}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={handleClick}
          disabled={loading}
        >
          {loading ? "Processing..." : "Update"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePaymentModal;
