import moment from "moment";
import React, { useEffect, useState } from "react";
import Datatable from "react-bs-datatable";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import TopUpModal from "./TopUpModal";
import EditModal from "./EditModal";
import Unathorized from "../../settings/Unathorized";

const ViewInvestments = () => {
  const [investments, setInvestments] = useState(null);
  const [loading, setLoading] = useState(true);
  // for top up modal
  const [showModal, setShowModal] = useState(false);
  // for edit modal
  const [showEdit, setShowEdit] = useState(false);
  // investment to be topped up or editted
  const [investment, setInvestment] = useState({
    id: "",
    title: "",
    amount: "",
    rollover: "",
  });
  // this is just to fire useEffect and trigger live update when user do topup
  const [fetch, setFetch] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API}/investments`)
      .then((res) => {
        setInvestments(res.data.data.investments.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    //cleanup
    return () => {
      source.cancel();
    };
  }, [fetch]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!investments) {
    return (
      <div className="text-primary text-center mt-5">
        <p>Something went wrong,please try again later</p>
      </div>
    );
  }

  if (investments.length < 1) {
    return (
      <div className="mt-5">
        <Unathorized message="You have no investments" />
      </div>
    );
  }

  //header for data table
  const header = [
    {
      title: "TITLE",
      prop: "title",
      filterable: true,
      sortable: true,
    },
    { title: "TYPE", prop: "type", filterable: true, sortable: true },
    { title: "AMOUNT", prop: "amount", filterable: true },
    { title: "DUE DATE", prop: "date", filterable: true },
    {
      title: "ROLLOVER",
      prop: "rollover",
      filterable: true,
    },
    { title: "STATUS", prop: "active", filterable: true },
    { title: "", prop: "edit" },
    { title: "", prop: "topup" },
  ];

  const body = [];

  investments.forEach((item) => {
    body.push({
      title: item.title,
      type: item.type ? item.type.name : "Custom",
      amount: "₦" + thousandSeparator(item.total_amount),
      date: moment(item.due_at).format("MMMM Do, YYYY"),
      rollover: `${item.rollover ? item.rollover.name : "No Rollover"}`,
      active: item.is_active ? (
        <span className="bg-primary text-white p-2 rounded">Active</span>
      ) : (
        <span className="bg-danger text-white p-2 rounded">Inactive</span>
      ),
      edit: item.is_active ? (
        <button
          className="btn btn-outline-primary topup mt-n2"
          onClick={() => {
            setShowEdit(true);
            setInvestment({
              id: item.id,
              title: item.title,
              type_id: item?.type?.id,
              amount: item.total_amount,
              rollover: item.rollover,
            });
          }}
        >
          Edit
        </button>
      ) : (
        ""
      ),
      topup: item.is_active ? (
        <button
          className="btn btn-outline-primary topup mt-n2"
          onClick={() => {
            setShowModal(true);
            setInvestment({
              id: item.id,
              title: item.title,
              amount: item.total_amount,
            });
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          Top up
        </button>
      ) : (
        ""
      ),
    });
  });

  return (
    <>
      <TopUpModal
        showModal={showModal}
        setShowModal={setShowModal}
        investment={investment}
        setInvestment={setInvestment}
        setFetch={setFetch}
        fetch={fetch}
      />
      <EditModal
        showModal={showEdit}
        setShowModal={setShowEdit}
        investment={investment}
        setInvestment={setInvestment}
        setFetch={setFetch}
        fetch={fetch}
      />
      <div>
        <h2 className="text-primary text-center m-4">
          <u>Your Investments</u>
        </h2>
        <div className="table-responsive container-fluid">
          <Datatable
            tableHeaders={header}
            tableBody={body}
            rowsPerPage={5}
            rowsPerPageOption={[5, 10, 15, 20]}
            //  onRowClick={onColClick}
            classes={{
              table: "mt-3 table",
              thead: "",
              filterClearButton: "clear-btn",
              tbodyCol: "table-data table-width",
            }}
            labels={{ filterPlaceholder: "Filter..." }}
          />
        </div>
      </div>
    </>
  );
};

export default ViewInvestments;
