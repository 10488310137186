import Cookies from "js-cookie";
import React,{useState} from "react";
import IdleTimer from "react-idle-timer";

const IdleTimerComponent = ({handleLogout,setShowModal}) => {
  const [isTimedOut, setIsTimedOut] = useState(false);

  let idleTimer = null;

  const onAction = () => setIsTimedOut(false);
  const onActive = () => setIsTimedOut(false);

  const onIdle = () => {
    if (isTimedOut) {
      handleLogout();
    } else if (Cookies.get("token") && Cookies.get("isAuthenticated")) {
      setShowModal(true);
      idleTimer.reset();
      setIsTimedOut(true);
    }
  };

  return (
    <IdleTimer
      ref={(ref) => {
        idleTimer = ref;
      }}
      element={document}
      onActive={onActive}
      onIdle={onIdle}
      onAction={onAction}
      debounce={250}
      timeout={300000}
    />
  );
};

export default IdleTimerComponent;
