export const checkAccountType = (accounts, transaction) => {
  let type = "",
    name = "",
    number = "";
  if (transaction.source_account_number) {
    const account = accounts.filter(
      (account) => account.account_number === transaction.source_account_number
    );
    type = account[0].type.name;
    name = account[0].account_name;
  }

  if (transaction.account_id) {
    const account = accounts.filter(
      (account) => account.id === transaction.account_id
    );

    type = account[0].type.name;
    name = account[0].account_name;
    number = account[0].account_number;
  }

  return { type, name, number };
};
