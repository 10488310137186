import React, { useState } from "react";
import Big_logo from "../../assets/Cloudbank_logo-Big.png";
import { Helmet } from "react-helmet-async";
import ForgotPassForm from "./ForgotPassForm";
import ForgotPassForm2 from "./ForgotPassForm2";

// this component and its children use some classes in register/Register.scss
// and Login.scss
const ForgotPass = () => {
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");

  return (
    <div className="register">
      <Helmet>
        <title>Reset Password - Shanono</title>
      </Helmet>
      <h1 className="reg-logo pt-5">
      <img
          className="img-fluid"
          src={Big_logo}
          alt="shanono logo"
          style={{ width: "150px", height: "auto" }}
        />
      </h1>

      <div className="reg-msg mt-2 mb-3">
        {step === 1 ? (
          <h2>
            Forgot Password?
            <br /> <span>Please enter your email or phone number</span>
          </h2>
        ) : (
          <h2>Complete the form to change your password</h2>
        )}
      </div>
      <div className={step === 1 ? "" : "d-none"}>
        <ForgotPassForm setStep={setStep} setUsername={setUsername} username={username} />
      </div>
      <div className={step === 2 ? "" : "d-none"}>
        <ForgotPassForm2 username={username} setUsername={setUsername}/>
      </div>
    </div>
  );
};

export default ForgotPass;
