import { all, takeLatest } from "redux-saga/effects";
import { handleGetUser } from "./handlers/user";
import { GET_USER } from "../ducks/user";
import { GET_ACCOUNTS } from "../ducks/accounts";
import { handleGetAccounts } from "./handlers/accounts";
import { GET_BANKS } from "../ducks/banks";
import { handleGetBanks } from "./handlers/banks";
import { GET_BENEFICIARIES } from "../ducks/beneficiaries";
import { handleGetBeneficiaries } from "./handlers/beneficiaries";
import {
  handleGetInvestmentTypes,
  handleGetRolloverTypes,
} from "./handlers/investments";
import { GET_INVESTMENTTYPES, GET_ROLLOVER_TYPES } from "../ducks/investments";
import { GET_SCHEDULES } from "../ducks/schedules";
import { handleGetSchedules } from "./handlers/schedules";
import { handleGetAccountTypes } from "./handlers/accountTypes";
import { GET_ACCOUNTS_TYPES } from "../ducks/accountTypes";
import { GET_PROVIDERS } from "../ducks/providers";
import { handleGetProviders } from "./handlers/providers";

export function* watcherSaga() {
  yield all([
    takeLatest(GET_USER, handleGetUser),
    takeLatest(GET_ACCOUNTS, handleGetAccounts),
    takeLatest(GET_PROVIDERS, handleGetProviders),
    takeLatest(GET_BANKS, handleGetBanks),
    takeLatest(GET_BENEFICIARIES, handleGetBeneficiaries),
    takeLatest(GET_INVESTMENTTYPES, handleGetInvestmentTypes),
    takeLatest(GET_ROLLOVER_TYPES, handleGetRolloverTypes),
    takeLatest(GET_SCHEDULES, handleGetSchedules),
    takeLatest(GET_ACCOUNTS_TYPES, handleGetAccountTypes),
  ]);
}
