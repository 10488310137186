import React, { useState } from "react";
import { Form, Toast } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { thousandSeparator } from "../../../utils/thousandSeparator";
import { withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./AccountsSlider.scss";
import { getCurrencySymbol, getProviderName } from "../../../utils/normalize";

const AccountsSlider = ({ accounts, history }) => {
  // assign first account as the account to be displayed
  let account = null;

  //set account from redux once initialized
  if (accounts) {
    account = accounts[0];
  }
  const [copied, setCopied] = useState(false);
  // hideBal short for hide balance
  const [hideBal, setHideBal] = useState(localStorage.hideBal || false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      showDots={accounts && accounts.length > 1}
      sliderClass="pb-4"
      containerClass="slider-container"
    >
      {accounts &&
        accounts.map((account, index) => (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center flex-wrap mt userdash-cards"
          >
            <div className="d-flex justify-content-around flex-wrap align-items-center userdash-card_left">
              <div className="ml-lg-3 mr-2">
                <p className="acc-name mt-3">
                  {account && (
                    <span className="text-uppercase">{account.type.name}</span>
                  )}{" "}
                  ACCOUNT
                </p>
                <p className="mt-4 acc-bal">
                  {hideBal ? (
                    "*******"
                  ) : (
                    <span>
                      {account &&
                        `${getCurrencySymbol(account)} ${thousandSeparator(
                          account.available_balance
                        )}`}
                    </span>
                  )}
                </p>
              </div>
              <div className="">
                <div className={index !== 0 ? "invisible" : "d-flex mb-3 ml-3"}>
                  <span className="mr-2 align-self-center hide-bal">
                    HIDE BALANCE
                  </span>
                  <Form.Switch
                    onChange={() => {
                      setHideBal(!hideBal);
                      localStorage.hideBal
                        ? localStorage.removeItem("hideBal")
                        : localStorage.setItem("hideBal", !hideBal);
                    }}
                    defaultChecked={hideBal}
                    id="custom-switch"
                    label=""
                    className=""
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="">
                  <button
                    className="btn btn-primary fund-btn"
                    onClick={() => history.push("/fund-account")}
                  >
                    <FaIcons.FaCreditCard /> Fund Account
                  </button>
                  {/* <button
                    className="btn btn-primary fund-btn"
                    onClick={() => history.push("/investment")}
                  >
                    <FaIcons.FaChartLine /> Invest
                  </button> */}
                </div>
              </div>
            </div>
            <div className="p-2 p-lg-4 userdash-card_right">
              <p className="bank-details">BANK ACCOUNT DETAILS</p>
              <div className="d-flex justify-content-around">
                <div>
                  <p className="bank-name">Bank name</p>
                  <p className="text-capitalize">{getProviderName(account)}</p>
                </div>
                <div className="mt-auto">
                  <p className="bank-number">Account number</p>
                  <p>{account && account.account_number}</p>
                </div>
                <Toast
                  onClose={() => setCopied(false)}
                  show={copied}
                  className="copy-toast"
                  delay={1000}
                  autohide
                >
                  <Toast.Body className="copy-toast-text">Copied</Toast.Body>
                </Toast>
                <div className="mt-5 mt-lg-4">
                  <CopyToClipboard
                    btn="true"
                    onCopy={() => setCopied(true)}
                    text={
                      account &&
                      account.account_name +
                        " " +
                        getProviderName(account) +
                        " " +
                        account.account_number
                    }
                  >
                    <button
                      btn
                      className="btn btn-outline-primary btn-copy mt-n5"
                    >
                      <FaIcons.FaCopy /> Copy
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        ))}
    </Carousel>
  );
};

export default withRouter(AccountsSlider);
