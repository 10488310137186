/**
 * Filter off all interest from rollover.
 *
 * This is a commission based investment.
 * @param {*} name
 * @returns boolean
 */
export const getInterestFreeRolloverTypes = (
  type_name,
  investment_type_id,
  investmentTypes
) => {
  const investmentType = investmentTypes?.find(
    (investment) => investment?.id === investment_type_id
  );
  return (
    investmentType?.name?.toLowerCase() === "msa fund" &&
    type_name?.toLowerCase() !== "investment and interest"
  );
};

