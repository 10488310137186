import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../components/InputField";
import InputDropDown from "../../../components/InputDropDown";
import * as Yup from "yup";
import { Link, withRouter } from "react-router-dom";
import InputFile from "../../../components/InputFile";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/ducks/user";
import Cookies from "js-cookie";
import { getAccounts } from "../../../redux/ducks/accounts";
import { useSelector } from "react-redux";

const RegisterForm3 = ({ account, setAccount, history, setStep }) => {
  const accountTypes = useSelector((state) => state.accountTypes.accountTypes);

  const dispatch = useDispatch();
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSavingsAcc, setIsSavingsAcc] = useState(true);

  if (!accountTypes) {
    return <p className="text-center">Please wait...</p>;
  }

  // get account ids
  const savings = accountTypes.filter((type) => type.name === "Savings")[0].id;
  const business = accountTypes.filter((type) => type.name === "Business")[0]
    .id;

  const handleSubmit = (values) => {
    //empty errors
    setErrors([]);
    setLoading(true);
    //check if terms and policy was accepted
    if (!terms) {
      setLoading(false);
      return setErrors([...errors, "Plaese accept the terms and policy"]);
    }

    //combine the properties
    // if account type is savings remove cac and account_name from values sent
    const allAccountValues =
      values.account_type === savings
        ? { ...account, account_type: values.account_type }
        : { ...account, ...values };

    const formData = new FormData();
    // loop and append to formData
    for (const property in allAccountValues) {
      formData.append(property, allAccountValues[property]);
    }

    axios
      .post(`${process.env.REACT_APP_API}/register`, formData)
      .then((res) => {
        setLoading(false);
        // empty account PS: Please don't confuse this with redux setAccount
        setAccount({});
        //set user and accounts in redux store
        dispatch(getAccounts());
        dispatch(setUser(res.data.data.account));
        //set token in Cookie
        Cookies.set("token", res.data.data.token);
        Cookies.set("refreshToken", res.data.data.refresh_token);
        Cookies.set("isAuthenticated", true);
        // redirect to dashboard
        history.replace("/");
      })
      .catch((err) => {
        setLoading(false);
        // add errors to error
        const apiErrors = err.response.data.error;
        let errorsArr = [];
        if (err.response.status === 422) {
          for (const property in apiErrors) {
            errorsArr = apiErrors[property];
          }
        } else {
          for (const property in apiErrors) {
            errorsArr.push(apiErrors[property]);
          }
        }
        setErrors([...errorsArr]);
      });
  };

  let validate = Yup.object({
    account_type: Yup.string().required("Account type is required"),
    account_name: Yup.string().required("Account name is required"),
  });
  if (isSavingsAcc) {
    validate = Yup.object({
      account_type: Yup.string().required("Account type is required"),
      account_name: Yup.string(),
    });
  }

  return (
    <>
      <div className="reg-msg mt-2 mb-3">
        <h2>
          Final step, tell us more about the account <br /> you want to open
        </h2>
      </div>
      <div className="text-left form-container">
        {errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {/* new Set removes duplicates */}
            {errors.map((error, index) => (
              <p key={index} style={{ fontSize: "14px" }}>
                {error}
              </p>
            ))}
          </div>
        )}
        <Formik
          initialValues={{
            account_type: "",
            account_name: "",
          }}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <InputDropDown
                label="ACCOUNT TYPE"
                noValue="-- Choose Account Type --"
                options={["Business", "Savings"]}
                values={[business, savings]}
                name="account_type"
                style={{ width: "100%" }}
                onChange={(event) => {
                  formik.setFieldValue("account_type", event.target.value);
                  if (event.target.value === savings) {
                    // hide other inputs if it's savings account
                    setIsSavingsAcc(true);
                  } else {
                    // show other inputs if it's business account
                    setIsSavingsAcc(false);
                  }
                }}
              />
              <div className={isSavingsAcc ? "d-none" : ""}>
                <InputField
                  label="ACCOUNT NAME"
                  name="account_name"
                  type="text"
                  placeholder="Account name"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="terms"
                  onChange={() => setTerms(!terms)}
                />
                <label className="form-check-label" htmlFor="terms">
                  I agree to the{" "}
                  <Link to="/terms-of-service">terms and policy.</Link>
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-2 login-btn"
                style={{ width: "100%" }}
                disabled={isLoading}
              >
                {isLoading ? "Please wait..." : "Complete Registration"}
              </button>
              <button
                className="btn btn-outline-primary mt-2 w-100"
                onClick={() => setStep(2)}
              >
                Back
              </button>
            </Form>
          )}
        </Formik>
        <p className="text-center py-4 reg-bottom-text">
          Already have an account?
          <span className="text-primary ml-1">
            <Link to="/login">Login</Link>
          </span>
        </p>
      </div>
    </>
  );
};

export default withRouter(RegisterForm3);
