import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../components/InputField";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { validateUsername } from "../../utils/validateUsername";

// non bootstrap classes are in Login.scss
const ForgotPassForm2 = ({ history, username}) => {
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ type: "", message: [] });

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    setAlert({ type: "", message: [] });
    const neededValues = {
      username: values.username,
      token: values.token,
      password: values.password,
    };
    axios
      .post(`${process.env.REACT_APP_API}/password/reset`, neededValues)
      .then((res) => {
        setLoading(false);
        setAlert({
          type: "success",
          message: ["Password changed successfully"],
        });
        setTimeout(() => history.push("/login"), 1000);
        resetForm(values);
      })
      .catch((err) => {
        setLoading(false);
        //add errors to error array
        const apiErrors = err.response.data.error;
        const errors = [];
        if (err.response.status === 422) {
          for (const property in apiErrors) {
            errors.push(apiErrors[property][0]);
          }
        } else {
          for (const property in apiErrors) {
            errors.push(apiErrors[property]);
          }
        }
        setAlert({
          type: "danger",
          message: [...errors],
        });
      });
  };

  const validate = Yup.object({
    username: validateUsername(),
    token: Yup.string().required(),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm password"),
  });
  return (
    <div className="text-left form-container">
      {alert.message.length > 0 && (
        <div className={`alert alert-${alert.type} login-alert`} role="alert">
          {/* new Set removes duplicates */}
          {[...new Set(alert.message)].map((msg, index) => (
            <p key={index} style={{ fontSize: "14px" }}>
              {msg}
            </p>
          ))}
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          username: username,
          token: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <InputField
              label="EMAIL OR PHONE NUMBER"
              name="username"
              type="text"
              placeholder="EMAIL OR PHONE NUMBER"
            />
            <InputField
              label="TOKEN"
              name="token"
              type="text"
              placeholder={`Token sent to ${username}`}
              inputMode="numeric"
            />
            <InputField
              label="NEW PASSWORD"
              name="password"
              type="password"
              placeholder="********"
            />
            <InputField
              label="CONFIRM NEW PASSWORD"
              name="confirmPassword"
              type="password"
              placeholder="********"
            />
            <button
              type="submit"
              className="btn btn-primary mt-2 login-btn"
              disabled={isLoading}
            >
              {isLoading ? "Please wait..." : "Reset Password"}
            </button>
          </Form>
        )}
      </Formik>
      <p className="text-center py-4 reg-bottom-text">
        <span className="">
          <Link to="/login">Back to Login</Link>
        </span>
      </p>
    </div>
  );
};

export default withRouter(ForgotPassForm2);
