/**
 * Manage user's location
 */

export const GET_LOCATION = "GET_LOCATION";
const SET_LOCATION = "SET_LOCATION";
// const REMOVE_LOCATION = "REMOVE_LOCATION";

export const setUseLocation = (location) => ({
  type: SET_LOCATION,
  location,
});

export const getUserLocation = () => ({ type: GET_LOCATION });

// export const removeUserLocation = () => ({ type: REMOVE_LOCATION });

const initailState = null;

const userLocationReducer = (state = initailState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return { ...state, ...action.location };

    default:
      return state;
  }
};

export default userLocationReducer;
