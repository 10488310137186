import React from "react";
// import * as ImIcons from "react-icons/im";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
//import * as BiIcons from "react-icons/bi";
import * as GiIcons from "react-icons/gi";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    paths: ["/"],
    icon: <RiIcons.RiDashboardFill />,
    cName: "nav-text",
  },
  {
    title: "Send Money",
    path: "/send-money",
    paths: ["/send-money", "/send-money/beneficiaries"],
    icon: <FaIcons.FaMoneyBillWave />,
    cName: "nav-text",
  },
  {
    title: "Auto Transfer",
    path: "/auto-transfer",
    paths: ["/auto-transfer", "/auto-transfer/manage-auto-transfer"],
    icon: <FaIcons.FaCalendarAlt />,
    cName: "nav-text",
  },
  {
    title: "Fund Account",
    path: "/fund-account",
    paths: ["/fund-account", "/fund-account/recurring-payments"],
    icon: <GiIcons.GiPayMoney />,
    cName: "nav-text",
  },
  {
    title: "Bills Payment",
    path: "/bills-payment",
    paths: [
      "/bills-payment",
      "/bills-payment/data-purchase",
      "/bills-payment/tv-and-cable",
      "/bills-payment/electricity-payment",
    ],
    icon: <IoIcons.IoMdWallet />,
    cName: "nav-text",
  },
  {
    title: "Investment",
    path: "/investment",
    paths: ["/investment", "/investment/create", "/investment/all"],
    icon: <FaIcons.FaChartLine />,
    cName: "nav-text",
  },
  // {
  //     title: "Loan",
  //     path:"loan",
  //     paths: ["/loan"],
  //     icon: <FaIcons.FaHandHoldingUsd/>,
  //     cName: "nav-text",
  // },
  {
    title: "Cards",
    path: "/cards",
    paths: ["/cards", "/cards/create"],
    icon: <FaIcons.FaCreditCard />,
    cName: "nav-text",
  },
  {
    title: "Accounts",
    path: "/accounts",
    paths: ["/accounts", "/accounts/create"],
    icon: <BsIcons.BsBriefcaseFill />,
    cName: "nav-text",
  },
  {
    title: "POS",
    path: "/pos",
    paths: ["/pos", "/pos/create"],
    icon: <BsIcons.BsFilePost />,
    cName: "nav-text",
  },
  {
    title: "Transactions",
    path: "/transactions",
    paths: ["/transactions", "/transactions/statement/request"],
    icon: <RiIcons.RiFileList3Fill />,
    cName: "nav-text",
  },
  // {
  //     title: "Marketplace",
  //     path:"#",
  //     paths: ["#"],
  //     icon: <BiIcons.BiStore/>,
  //     cName: "nav-text",
  // },
  {
    title: "Settings",
    path: "/settings",
    paths: [
      "/settings",
      "/settings/create-pin",
      "/settings/update-profile",
      "/settings/change-pin",
      "/settings/upload-profile-picture",
      "/settings/verify-phone-number",
      "/settings/invite",
      "/settings/reset-pin",
      "/settings/update-referrer",
    ],
    icon: <RiIcons.RiSettings5Fill />,
    cName: "nav-text",
  },
  // {
  //   title: "Logout",
  //   path: "/login",
  //   paths: ["/login"],
  //   icon: <ImIcons.ImSwitch />,
  //   cName: "nav-text mt-4 nav-logout",
  // },
];
