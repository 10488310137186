import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import Announcement from "./announcement/Announcement";
import Total from "./total/Total";
import { Helmet } from "react-helmet-async";
import RightColumn from "./right_column/RightColumn";
import RightTransactions from "./right_column/RightTransactions";
import { useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";
import axios from "axios";
import AccountsSlider from "./accounts_slider/AccountsSlider";
import TotalBalance from "./total_balance/TotalBalance";
import Modal from "react-modal"
import { BroadCast, NoCrypto } from "../../components/Notice";

const Dashboard = () => {
  // notification for cryto trxn 
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const accounts = useSelector((state) => state.accounts.accounts);

  const time = new Date().getHours();
  const [greeting, setGreeting] = useState("Good morning");

  useEffect(() => {
    // watch for change in time and update greeting
    if (+time < 12) {
      setGreeting("Good morning");
    }
    if (+time >= 12 && !(+time >= 17)) {
      setGreeting("Good afternoon");
    }
    if (+time >= 17) {
      setGreeting("Good evening");
    }
  }, [time]);

  // returns % profile completion and data for donut chart
  const [home, setHome] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`${process.env.REACT_APP_API}/home`)
      .then((res) => setHome(res.data.data))
      .catch((err) => console.log(err));

    //cleanup
    return () => {
      source.cancel();
    };
  }, []);

  if (!accounts || !home) {
    return <PageLoader />;
  }
  return (
    <>
      <Helmet>
        <title>Home - Shanono</title>
      </Helmet>
      {/* no crypto transaction begin */}
      <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            },
            content: {
              maxWidth: "fit-content",
              maxHeight: "fit-content",
              margin: "auto",
              background: "white",
              borderRadius: "8px",
              padding: "0px",
            },
          }}
      >
        <NoCrypto closeModal={handleCloseModal}/>
      </Modal>
      {/* no crypto transaction end */}


      <div className="user-dashboard">
        <div className="d-flex user-dashboard">
          <div className="p-lg-2 user-dashboard_left">
            <div className="m-2">
              <BroadCast openModal={handleOpenModal}/>
              <h1 className="mt-4">Dashboard</h1>
              <h2>
                {greeting}, Shanono offers NUBAN account in Providus,
                Suntrust , WEMA and NIBBS platform. This is a singular wallet
                system with access to your funds from multiple channel. The only
                provider of this solution. One wallet; multiple NUBAN accounts.
                🤑
              </h2>
            </div>
            <TotalBalance />
            <AccountsSlider accounts={accounts} />
            <Announcement home={home} />
            <Total home={home} />
            <div className="hide-right-transactions">
              <RightTransactions />
              <p className="copyright-small-screen">
                Copyright © {new Date().getFullYear()} Shanono. All rights
                reserved.
              </p>
            </div>
          </div>
          <div className="pl-2 user-dashboard_right">
            <RightColumn home={home} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;